import { useEffect, useState } from "react";
import { emailRegex } from "utils";
import { GeneralFormType } from "types/Forms";
import { PrizeClaimComponentProps } from "types/Wizard";

import CustomSelect from "components/Select";
import Input from "components/Input";

import Button from "components/Button";
import AddressInput from "components/AddressInput";
import BirthDateInput from "components/DateOfBirthInput";
import CircularProgress from "components/CircularProgress";

import SelectNewStyle from "styles/Select/SelectNewStyle";
import { colorResolver, placementResolver } from "services/helpers/resolvers";
import ErrorComponent from "components/Error";
import Toast from "components/Toast";
import { useLocation, useParams } from "react-router-dom";
import { useGeneralFormSubmitMutation } from "api/graphql";

const FIELD_REQUIRED_ERROR = "This field is required";

const shirtSizes = [
  { value: "S", label: "S" },
  { value: "M", label: "M" },
  { value: "L", label: "L" },
  { value: "XL", label: "XL" },
  { value: "XXL", label: "XXL" },
  { value: "XXXL", label: "XXXL" },
  { value: "XXXXL", label: "XXXXL" },
];

const GeneralForm = (props) => {
  const { id } = useParams();

  const { context, next, wizardError, sendState }: PrizeClaimComponentProps =
    props;

  const location = useLocation();

  useEffect(() => {
    if (context.general) {
      populateForm(context.general);
    }
  }, [context.general]);

  const [values, setValues]: any = useState({
    name: "",
    day: "",
    month: "",
    year: "",
    phone: "",
    email: "",
    address: "",
    address_city: "",
    address_state: "",
    address_zip_code: "",
    address_apartment: "",
    shirt_size: null,
  });
  const [formErrors, setFormErrors] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [confirmError, setConfirmError] = useState(null);
  const [hasBeenPopulated, setHasBeenPopulated] = useState(false);

  const { mutateAsync: submitGeneralForm } = useGeneralFormSubmitMutation({
    onSuccess: ({ generalFormSubmit }) => {
      sendState("generalValid", generalFormSubmit.submitted);
      setConfirmSuccess(generalFormSubmit.submitted);
      setConfirmLoading(false);
    },
  });

  const handleChange = (e, selected) => {
    wizardError(null);
    setConfirmError(false);
    setConfirmSuccess(false);
    // determines if the select component is changing or text input
    if (
      formErrors.findIndex((x) =>
        selected ? x.where === selected.name : x.where === e.target.id
      ) >= 0
    )
      formErrors.splice(
        formErrors.findIndex((x) =>
          selected ? x.where === selected.name : x.where === e.target.id
        ),
        1
      );
    if (selected) {
      setValues({
        ...values,
        [selected.name]: e,
      });
      return;
    }
    setFormErrors(formErrors);
    setValues({
      ...values,
      [e.target.id]: e.target.value,
    });
  };

  const populateForm = (data: GeneralFormType) => {
    setValues({
      name: data.name,
      day: data.dateOfBirth.day,
      month: data.dateOfBirth.month,
      year: data.dateOfBirth.year,
      phone: data.phone,
      email: data.email,
      address: data.address,
      address_city: data.city,
      address_state: data.addressState,
      address_zip_code: data.zipCode,
      address_apartment: data.apartmentNumber,
      shirt_size: shirtSizes.find((x) => x.value === data.shirtSize),
    });
    setHasBeenPopulated(true);
  };

  const validateForm = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const errors: any[] = [];
      if (confirmError) reject(confirmError);
      if (confirmSuccess) {
        setConfirmError({ message: "Form already submitted" });
        reject(errors);
      }
      Object.keys(values).forEach((key) => {
        const optionalFields = ["address_apartment"];
        // skip checking paypal email input if the user hasn't selected paypal
        if (!values[key] && !optionalFields.some((x) => x === key)) {
          return errors.push({ where: key, message: FIELD_REQUIRED_ERROR });
        }
        if (key === "paypal_email" || key === "email") {
          const validEmail = emailRegex.test(values[key]);
          if (!validEmail)
            return errors.push({
              where: key,
              message: "Email address entered is invalid",
            });
        }
        if (["day", "month", "year"].some((x) => x === key)) {
          if (isNaN(Number(values[key]))) {
            errors.push({ where: key, message: `${key} must be a number` });
            return;
          }

          const curDate = new Date();

          switch (key) {
            case "day":
              if (Number(values[key]) > 31 || Number(values[key]) <= 0) {
                errors.push({
                  where: key,
                  message: values[key] + " is not a valid day, must be 1-31",
                });
                return;
              }
              return;
            case "month":
              if (Number(values[key]) > 12 || Number(values[key]) <= 0) {
                errors.push({
                  where: key,
                  message: values[key] + " is not a valid month, must be 1-12",
                });
                return;
              }
              return;
            case "year":
              // check if the year is greate
              if (
                values[key].length < 4 ||
                Number(values[key]) > curDate.getFullYear() ||
                curDate.getFullYear() - Number(values[key]) > 120
              ) {
                errors.push({
                  where: key,
                  message: values[key] + " is not a valid year",
                });
                return;
              }
              return;
            default:
              break;
          }
        }
      });
      if (errors.length > 0) reject(errors);
      else resolve(true);
    });
  };

  const handleSubmit = async () => {
    wizardError(null);
    try {
      await validateForm();
    } catch (errors) {
      setFormErrors(errors);
      return;
    }

    const input = {
      prizeId: String(id),
      name: values.name,
      dateOfBirth: {
        day: Number(values.day),
        month: Number(values.month),
        year: Number(values.year),
      },
      phone: values.phone,
      email: values.email,
      address: values.address,
      city: values.address_city,
      addressState: values.address_state,
      zipCode: values.address_zip_code,
      apartmentNumber: values.address_apartment,
      shirtSize: values.shirt_size.value,
    };

    setConfirmLoading(true);
    submitGeneralForm({ input });
  };

  next(() => {
    if (!confirmSuccess) {
      wizardError({ message: "Form must be submitted before continuing" });
      return false;
    } else {
      return true;
    }
  });

  return (
    <div className="prize-payout">
      {hasBeenPopulated && (
        <div className="prize-payout__toast mb-3">
          <Toast
            type="info"
            message="This form has been pre-filled with previous data, please double check all of the inputs before submitting!"
            closeable
          />
        </div>
      )}
      <div className="container__header">
        <h1>General Form</h1>
        <h3>Please fill out this form in order to collect your winnings</h3>
      </div>
      <form className="prize-payout__form">
        <div className="prize-payout__form__info">
          <div className="prize-payout__form__info__item">
            <span className="prize-payout__form__info__item__name">Date</span>
            <span className="prize-payout__form__info__item--date">
              {new Date(
                context.prize && context.prize.createdAt
              ).toDateString()}
            </span>
          </div>
          <div className="prize-payout__form__info__item">
            <span className="prize-payout__form__info__item__name">Event</span>
            <span className="prize-payout__form__info__item--event">
              {context.prize && context.prize.title}
            </span>
          </div>
          <div className="prize-payout__form__info__item">
            <span className="prize-payout__form__info__item__name">Place</span>
            <span
              className={`prize-payout__form__info__item--place ${colorResolver(
                context.prize && context.prize.rank
              )} bold`}
            >
              {placementResolver(context.prize && context.prize.rank)}
            </span>
          </div>
          <div className="prize-payout__form__info__item">
            <span className="prize-payout__form__info__item__name">Prize</span>
            <span className="prize-payout__form__info__item--prize">
              {context.prize && context.prize.amount.display}
            </span>
          </div>
        </div>
        <Input
          className="prize-payout__form__input"
          id="name"
          name="name"
          label="Name"
          value={values.name}
          onChange={handleChange}
          error={formErrors.find((x) => x.where === "name")}
        />
        <BirthDateInput
          values={values}
          handleChange={handleChange}
          errors={formErrors}
          locale={context.locale}
        />
        <Input
          className="prize-payout__form__input mt-1"
          id="phone"
          name="phone"
          label="Phone number"
          value={values.phone}
          onChange={handleChange}
          error={formErrors.find((x) => x.where === "phone")}
        />
        <Input
          className="prize-payout__form__input"
          id="email"
          name="email"
          label="Email"
          value={values.email}
          onChange={handleChange}
          error={formErrors.find((x) => x.where === "email")}
        />
        <AddressInput
          values={values}
          handleChange={handleChange}
          formErrors={formErrors}
        />
        <CustomSelect
          className="prize-payout__form__input"
          id="shirt_size"
          name="shirt_size"
          label="T-Shirt Size"
          options={shirtSizes}
          styles={SelectNewStyle}
          value={values.shirt_size}
          onChange={handleChange}
          error={formErrors.find((x) => x.where === "shirt_size")}
        />
        <div className="flex-align-center mt-4 flex">
          <Button
            className=""
            label="Confirm your details"
            color="green"
            onClick={handleSubmit}
          />
          {confirmLoading && <CircularProgress className="ml-2" sm inverse />}
          {confirmSuccess && !confirmError && (
            <span className="green bold ml-2">
              Form successfully submitted.
            </span>
          )}
        </div>
      </form>
      {confirmError && (
        <div className="mt-4">
          <ErrorComponent
            closeable={false}
            visible={confirmError && true}
            message={confirmError.message}
            type="GENERAL_FORM_SUBMIT"
            id={id}
            path={location.pathname}
          />
        </div>
      )}
    </div>
  );
};

export default GeneralForm;
