import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ProfileProvider } from "./lib/profile";
import AppRoutes from "./AppRoutes";

import Body from "components/Body";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ProfileProvider>
          <Body>
            <AppRoutes />
          </Body>
        </ProfileProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
