import React from 'react';

import ToggleButton from 'components/ToggleButton';

const ToggleButtonGroup = (props) => {
  return (
    <div className={`toggle-button-group ${props.disabled ? 'disabled' : ''}`}>
      <span className="toggle-button-group__label">{props.label}</span>
      <div className="toggle-button-group__buttons">
        {props.options.map((prop, key) => (
          <ToggleButton
            key={key}
            prop={prop}
            onClick={props.handleToggleButtonChange}
            selected={props.value === prop}
            attachFront={props.attachFront}
          />
        ))}
      </div>
    </div>
  );
};
export default ToggleButtonGroup;