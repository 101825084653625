import { useState, useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';

import BreakdownForm from 'components/Forms/BreakdownForm';

const BreakdownStep = (props) => {
  const validator = new SimpleReactValidator();

  const { next, sendState, loading, wizardStates, wizardError } = props;
  const { breakdown } = wizardStates;

  const [values, setValues] = useState(breakdown || {
    type: 'percentage',
    maxTeams: 3,
    breakdown: [...Array(3)],
  });
  const [errorType, setErrorType] = useState(null);

  const handleChange = (e) => {
    setErrorType(null);
    wizardError(null);

    const val = Number(e.target.value);
    if (val > 100) return;

    setValues({
      ...values,
      maxTeams: val,
      breakdown: [...Array(val)],
    });
  };

  const handleSwitchChange = (e) => {
    const type = values.type === 'percentage' ? 'amount' : 'percentage'
    setValues({
      ...values,
      type,
      breakdown: [...Array(values.maxTeams)],
    });
  };

  next(async () => {
    if (values.breakdown.some(x => x === undefined)) {
      loading(false);
      setErrorType('input');
      wizardError({ message: 'All inputs must be filled out' });
      return false;
    };
    if (values.breakdown.length == 0) {
      loading(false);
      wizardError({ message: 'Total teams cannot be 0' });
      return false;
    }

    const breakdownTotal = values.breakdown.reduce((total, num) => total += num, 0)
    if (breakdownTotal !== 100 && values.type == 'percentage') {
      loading(false);
      setErrorType('input');
      wizardError({ message: `The total must equal 100%, your breakdown equals ${breakdownTotal}%` })
      return false;
    }

    const total = values.type == 'percentage' ? 5 : breakdownTotal;
    sendState('breakdown', {
      ...values,
      total,
    });
    return true;
  });

  return (
    <div className="breakdown-step">
      <div className="wizard__step-header">
        <h1>Configure your prize pool breakdown</h1>
        <h3>Choose the amount of teams you want to payout, and how much of the prize you want them to receive</h3>
      </div>
      <BreakdownForm
        validator={validator}
        values={values}
        updateValues={(v) => setValues(v)}
        handleSwitchChange={handleSwitchChange}
        handleChange={handleChange}
        wizardError={wizardError}
        errorType={errorType}
        resetError={() => setErrorType(null)}
      />
    </div>
  );
};
export default BreakdownStep;