import React, { useState, useEffect } from "react";

import { Icon } from "@iconify/react";
import Input from "components/Input";

// icons
import SearchIcon from "@iconify/icons-ion/search";
import { useGetChallengermodeTournamentQuery } from "api/graphql";

const ChallengermodeForm = (props) => {
  const [success, setSuccess] = useState(false);

  const challengermodeTournamentVariables = {
    url: props.values.url,
  };

  const [searchText, setSearchText] = useState("");

  const { data, isLoading, error } = useGetChallengermodeTournamentQuery(
    {
      url: searchText,
    },
    {
      enabled: searchText.length > 0,
    }
  );

  useEffect(() => {
    if (error) {
      props.error(error);
      props.loading(false);
    }

    if (!isLoading && data) {
      props.updateValues({
        ...props.values,
        spaceId: data.challengermodeTournament.spaceId,
        tournamentId: data.challengermodeTournament.id,
      });
      props.loading(false);
      setSuccess(true);
    }
  }, [data]);

  const handleChange = (e) => {
    props.updateValues({
      ...props.values,
      url: e.target.value,
    });
  };

  const handleSearch = () => {
    if (!props.values.url) return;
    props.error(null);
    props.loading(true);
    setSearchText(props.values.url);
  };

  return (
    <React.Fragment>
      <div className="wizard__step-header">
        <h1>Configure your competition</h1>
        <h3>
          Enter the URL to your Challenger Mode tournament and the select the
          bracket you wish to use. Players must not be manually entered into the
          bracket.
        </h3>
      </div>
      <div className="configure-step__configure-form">
        <Input
          label="Challenger Mode Tournament URL"
          placeholder="https://challengermode.com/tournaments/long-string-here"
          button={<Icon icon={SearchIcon} />}
          value={props.values.url}
          onChange={handleChange}
          action={handleSearch}
          triggerOnEnter={true}
          success={success}
        />
        {props.validator.message("url", props.values.url, "required|url")}
        {props.validator.message(
          "tournamentId",
          props.values.tournamentId,
          "required"
        )}
        {success && (
          <span className="green">Action sucessful, you may continue.</span>
        )}
      </div>
    </React.Fragment>
  );
};
export default ChallengermodeForm;
