import ErrorComponent from "components/Error";
import { useLocation, useParams } from "react-router-dom";

import { useGetCommunityFundsQuery } from "api/graphql";

import AmountView from "components/AmountView";

import LoadingLogo from "components/LoadingLogo";

const CommunityAdmin = () => {
  const location = useLocation();
  const { id } = useParams();

  const { isLoading, error, data } = useGetCommunityFundsQuery({ id });

  if (isLoading) {
    return <LoadingLogo />;
  }

  if (error) {
    return (
      <ErrorComponent
        id="community_admin"
        visible={error ? true : false}
        message={error && error.message}
        path={location.pathname}
        type="CommunityFunds"
        closeable={false}
        style={{ margin: "24px 0" }}
        inverse
      />
    );
  }

  return (
    <div className="my-6">
      <div className="text-lg sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 odd:sm:grid">
        <div className="-mt-2.5">
          <h1 className="text-xl font-semibold text-orange-500">Funds</h1>
          <p className="text-neutral-300">{data.community.name}'s accounts</p>
        </div>
        <div className="col-span-2">
          <span className="text-orange-500">Currencies</span>
          {data.communityAdmin.funds.deposit ? (
            data.communityAdmin.funds.deposit.currencies.map((cur, k) => (
              <div key={k}>
                <AmountView amount={cur.amount} />
              </div>
            ))
          ) : (
            <div>
              <span>No Funds</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommunityAdmin;
