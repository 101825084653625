import React from "react";

const DisplayDate = (value: any) => {
  const dateTime = new Date(value);

  const formattedDate =
    dateTime.getFullYear() +
    "-" +
    ("0" + (dateTime.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + dateTime.getDate()).slice(-2);

  return <span title={dateTime.toString()}>{formattedDate}</span>;
};

export default DisplayDate;
