import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useCommunitiesQuery } from "api/graphql";

// components
import Button from "components/Button";
import CommunityCard from "components/CommunityCard";
import ErrorComponent from "components/Error";
import Footer from "components/Footer";

const Communities = () => {
  const location = useLocation();
  const [after, setAfter] = useState<string | null>(null);
  const [communities, setCommunities] = useState([]);

  const { isLoading, isFetching, error, data } = useCommunitiesQuery({
    after,
  });

  useEffect(() => {
    const edges = data?.communities?.edges;

    if (edges != null) {
      var newData = [...communities, ...edges];
      setCommunities(newData);
    }
  }, [data]);

  if (error) {
    return (
      <div
        style={{
          width: "70vw",
          margin: "0 auto",
        }}
      >
        <ErrorComponent
          closeable={false}
          visible={true}
          message={error.message}
          type="COMMUNITIES_FETCH"
          id="community"
          path={location.pathname}
          inverse
        />
      </div>
    );
  }

  const pageInfo = data?.communities?.pageInfo;

  const handleNextPage = () => {
    setAfter(pageInfo.endCursor);
  };

  return (
    <div>
      <div className="parallax"></div>
      <div className="communities">
        <div className="communities__header">
          <h1>Communities</h1>
          <Link className="btn" to="/create/community">
            <span className="btn__label">Create a Community</span>
          </Link>
        </div>
        <div className="communities__list">
          {/* This reverses the list so we can display Trovo / HyperX / Ranked Hoops / BBR first */}
          {communities
            .slice(0)
            .reverse()
            .map((c: any, i: number) => (
              <CommunityCard key={i} data={c.node} />
            ))}
        </div>
        <div className="communities__list__load-more">
          <Button
            small
            label={
              isFetching
                ? "Loading..."
                : pageInfo?.hasNextPage
                ? "Load more"
                : "You've reached the end"
            }
            disabled={!pageInfo?.hasNextPage ?? true}
            onClick={handleNextPage}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Communities;
