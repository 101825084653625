export default {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#0F0F0F",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
    border: "1px solid #525252",
    borderRadius: "4px",
    borderColor: state.isFocused ? "#FF7A00" : "#525252",
    pointerEvents: state.isDisabled && "all",
    "&:hover": {
      borderColor: state.isDisabled ? "" : "#FF7A00",
      cursor: state.isDisabled && "not-allowed",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "#575757" : "#F3F3F3",
    padding: "0",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: "#FF7A00",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#212529",
    fontWeight: "bold",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "8px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#F3F3F3",
    background: "transparent",
    border: "0",
    "&:hover": {
      background: "#141414",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    border: "0",
    background: "#0F0F0F",
    boxShadow: "0",
    zIndex: "999",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "#575757" : "#FF7A00",
    "&:hover": {
      color: state.isDisabled ? "#575757" : "#FF7A00",
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#FF7A00",
  }),
};
