import React from 'react';

export type CheckboxProps = {
  id: string
  name?: string
  className?: string
  value?: any
  checked: boolean
  disabled?: boolean
  label?: string
  error?: any
  success?: any
  onChange: any
}

const Checkbox = (props: CheckboxProps) => {
  return (
    <div className={`checkbox ${props.className}`}>
      <input
        className="checkbox__input"
        type="checkbox"
        name={props.name}
        id={props.id}
        onChange={props.onChange}
        disabled={props.disabled}
        checked={props.checked}
      />
      <label className="checkbox__label" htmlFor={props.name}>{props.label}</label>
    </div>
  );
};
export default Checkbox;