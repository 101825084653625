import React, { useState } from 'react';

import { Icon, InlineIcon } from '@iconify/react';

import ErrorIcon from '@iconify/icons-cil/x-circle';
import CloseIcon from '@iconify/icons-cil/x';

type ErrorProps = {
  visible: boolean
  closeable?: boolean
  compact?: boolean
  inverse?: boolean
  message: string | string[]
  type: string | string[]
  path: string
  id: any
  onErrorClose?: any
  style?: any
}

const ErrorComponent = (props: ErrorProps) => {

  const [visible, setVisible] = useState(props.visible);
  const [errMessage, setErrMessage] = useState(props.message);
  const [errPath, setErrPath] = useState(props.path);
  const [errType, setErrType] = useState(props.type);
  const [id, setId] = useState(props.id);

  const toggleError = () => {
    props.onErrorClose();
    setVisible(false);
  }

  const copyMessage = () => {
    const message = formatMessage(id, errPath, errMessage, errType);
    navigator.clipboard.writeText(message);
  }

  const formatMessage = (id, path, msg, type): string => {
    const outText = `
    __**Context.gg Error Report:**__\n
${type ? "**Error Type:** " : null}${type ? "`" + type + "`" : null}
${id ? "**ID:** " : null}${id ? "`" + id + "`" : null}
${path ? "**Where:** " : null}${path ? "`" + path + "`" : null}
${msg ? "**Message:** " : null}${msg ? "`" + msg + "`" : null}
    `
    return outText;
  }

  return (
    <div className={`
    error-container
    ${visible ? 'visible' : 'hidden'}
    ${props.inverse ? 'inverse' : ''}
    `}
      style={props.style}
    >
      {props.closeable && <div className="error-container__close-button" onClick={() => toggleError()}>
        <Icon icon={CloseIcon} />
      </div>}
      <h1><InlineIcon className="error-container__icon" icon={ErrorIcon} /> Error</h1>
      <span className="error-container__message">
        Oops, there seems to have been an error. Feel free to refresh and try again, but if the error persists click copy message and then paste that to us on Discord!
        {!props.compact && <span>{props.message}</span>}
      </span>
      <div className="error-container__buttons">
        {/* <button onClick={() => toggleError()}>Close</button> */}
        <button onClick={() => copyMessage()}>Copy message</button>
        <button onClick={() => window.open("https://discord.gg/2KFUWNM", '_blank')}>Send it to us on Discord</button>
      </div>
    </div>
  );
};
export default ErrorComponent;