import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ReactGA from "react-ga";

import { useGetCommunityWinnersQuery } from "api/graphql";
import { useMyProfile } from "lib/profile";
import { communityWinnersTableDataResolver } from "services/helpers/resolvers";

import CustomTable from "components/Table";
import Button from "components/Button";
import ErrorComponent from "components/Error";
import ClaimFormsModal from "components/Modals/ClaimFormsModal";
import WinningModal from "components/Modals/WinningModal";
import LoadingLogo from "components/LoadingLogo";

const tableHeaders = [
  { name: "Date", props: { align: "left" } },
  { name: "Status", props: { align: "left" } },
  { name: "Name", props: { align: "left" } },
  { name: "Amount", props: { align: "left" } },
  { name: "Expires", props: { align: "left" } },
  { name: "Forms", props: { align: "left" } },
];

const CommunityAdminWinners = () => {
  const location = useLocation();
  const user = useMyProfile();
  const { id } = useParams();

  const [winningModalOpen, setWinningModalOpen] = useState(false);
  const [formsModalOpen, setFormsModalOpen] = useState(false);
  const [visibleWinning, setVisibleWinning] = useState(null);
  const [visibleForms, setVisibleForms] = useState(null);
  const [after, setAfter] = useState(null);

  const [winners, setWinners] = useState([]);

  const { isLoading, isFetching, error, data } = useGetCommunityWinnersQuery(
    { id, after },
    { keepPreviousData: true }
  );

  useEffect(() => {
    const winnerData = data?.communityAdmin?.winners?.edges?.map(({ node }) =>
      communityWinnersTableDataResolver(node, toggleModal)
    );

    if (winnerData != null) {
      var newData = [...winners, ...winnerData];
      setWinners(newData);
    }
  }, [data]);

  if (isLoading) {
    return <LoadingLogo />;
  }

  if (error) {
    return (
      <ErrorComponent
        id="community_admin_winners"
        visible={true}
        message={error.message}
        path={location.pathname}
        type="CommunityWinners"
        closeable={false}
        style={{ margin: "24px 0" }}
        inverse
      />
    );
  }

  const toggleModal = (value, type, data) => {
    switch (type) {
      case "winner":
        ReactGA.modalview("community-staff-winner");
        setVisibleWinning(data);
        setWinningModalOpen(value);
        break;
      case "forms":
        ReactGA.modalview("community-staff-forms");
        setVisibleForms(data);
        setFormsModalOpen(value);
        break;
    }
  };

  const pageInfo = data?.communityAdmin?.winners?.pageInfo;

  const handleNextPage = async () => {
    setAfter(pageInfo.endCursor);
  };

  return (
    <React.Fragment>
      <ClaimFormsModal
        visible={formsModalOpen}
        toggleModal={(val) => toggleModal(val, "forms", null)}
        winning={visibleForms}
      />
      <WinningModal
        visible={winningModalOpen}
        toggleModal={(val) => toggleModal(val, "winner", null)}
        winning={visibleWinning}
      />

      <div className="community-admin__tabs__wrapper my-6">
        <div className="community-admin__winners">
          <div className="rounded bg-context-light px-2 pt-2 pb-1 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-ctx-orange">
            <CustomTable tableData={winners} tableHeaders={tableHeaders} />
            <div className="community-admin__winners__load-more">
              <Button
                small
                label={
                  isFetching
                    ? "Loading..."
                    : pageInfo?.hasNextPage
                    ? "Load more"
                    : "You've reached the end"
                }
                disabled={!pageInfo?.hasNextPage ?? true}
                onClick={handleNextPage}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommunityAdminWinners;
