import React from 'react';
import { regionResolver } from 'services/helpers/resolvers';

const Details = (props) => {

  return (
    <div className="community-details">
      <div className="community-details__header">
        <h1>Details</h1>
      </div>
      <div className="community-details__container">
        <div className="community-details__container__stats">
          <div className="community-details__container__stats__item">
            <h3>Region</h3>
            <div className="community-details__container__stats__item__content">
              {props.community.regions && props.community.regions.length > 0 && props.community.regions.map((r, i) => {
                // check if last item and omit comma
                if (i === props.community.regions.length - 1) {
                  return regionResolver(r)
                } else {
                  return regionResolver(r) + ", "
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Details;