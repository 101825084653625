import { colorResolver } from 'services/helpers/resolvers';

const PrizeCard = (props) => {

  return (
    <div className="prize-card">
      <div className={`prize-card__placement ${colorResolver(props.placement)}-bg`}>{props.title}</div>
      <div className="prize-card__amount">{props.amountDisplay}</div>
    </div>
  );
};
export default PrizeCard;