import { CompetitionConfigure } from "types/Wizard";

export const leaderboardConfigResolver = (
  type,
  data: CompetitionConfigure
): any => {
  switch (type) {
    case "smashgg":
      const smashCfg = {
        eventId: data.eventId,
        url: data.url,
        tournamentId: Number(data.tournamentId),
      };
      return smashCfg;
    case "sixmans":
      const sixmansCfg = {
        regionId: data.regionId,
        rankId: data.rankId,
        seasonId: data.seasonId,
      };
      return sixmansCfg;
    case "battlefy":
      const battlefyCfg = {
        stageId: data.stageId,
        url: data.url,
        tournamentId: data.tournamentId,
      };
      return battlefyCfg;
    case "challonge":
      const challongeCfg = {
        subdomain: data.subdomain,
        url: data.url,
        tournamentId: data.tournamentId,
        tournamentUrl: data.tournamentUrl,
      };
      return challongeCfg;
    case "challengermode":
      const challengermodeCfg = {
        tournamentId: data.tournamentId,
        spaceId: data.spaceId,
        url: data.url,
      };
      return challengermodeCfg;
  }
};
