import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { InlineIcon } from "@iconify/react";
import Refresh from "@iconify/icons-uil/refresh";

import {
  useCompetitionSyncMutation,
  useGetCompetitionWinnersQuery,
} from "api/graphql";
import { CompetitionType } from "types/Competition";

import Button from "components/Button";
import ErrorComponent from "components/Error";
import CircularProgress from "components/CircularProgress";

import WinningTeam from "../Components/WinningTeam";

const Winners = ({ context, next, sendState, loading }) => {
  const location = useLocation();

  const [entrantsToPayout, setEntrantsToPayout] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [syncSuccess, setSyncSuccess] = useState(false);

  const {
    isLoading: winnersLoading,
    error: winnersError,
    data,
  } = useGetCompetitionWinnersQuery({ id: context.data.id });
  const {
    mutateAsync: executeCompSync,
    isLoading: syncLoading,
    error: syncError,
  } = useCompetitionSyncMutation();

  useEffect(() => {
    if (winnersLoading || winnersError) {
      return;
    }

    const teamsToPayout = data.competition.standings.map((team) => {
      return {
        teamId: team.id,
        playerBreakdowns: [
          ...team.players.map((p) => ({
            id: p.id,
            type: p.type,
            profileId: p.profileId,
          })),
        ],
      };
    });

    setEntrantsToPayout(teamsToPayout);
    sendState("winners", { entrantsToPayout: teamsToPayout, valid: true });
  }, [winnersLoading, winnersError, data]);

  const togglePlayer = (teamId, selectedPlayer) => {
    // reset error
    setError(null);
    // remove userId from teamId's payout
    const findTeam = entrantsToPayout.find((x) => x.teamId === teamId);
    if (!findTeam)
      return setError({
        message: "Unexpected error, refresh and try again, sorry..",
      });
    const findIndexOfUser = findTeam.playerBreakdowns.findIndex(
      (obj) => obj.id === selectedPlayer.id
    );
    if (findIndexOfUser >= 0) {
      // player exists in array, remove
      findTeam.playerBreakdowns.splice(findIndexOfUser, 1);
    } else {
      // player doesnt exist, add em
      findTeam.playerBreakdowns.push(selectedPlayer);
    }
    // refresh state so player buttons update
    setEntrantsToPayout([...entrantsToPayout]);
    validateSelectedPlayers();
  };

  const validateSelectedPlayers = () => {
    let isValid = true;
    entrantsToPayout.forEach((team) => {
      if (team.playerBreakdowns.length < 1) {
        return (isValid = false);
      }
    });
    // validate and send the state every time
    sendState("winners", { entrantsToPayout, valid: isValid });
    return isValid;
  };

  // this code will be unused until there's another step added
  next(async () => {
    loading(true);
    const selectionsValid = validateSelectedPlayers();
    if (!selectionsValid) {
      loading(false);
      return;
    }
    return true;
  });

  // runSync executes the competition sync on the backend
  // to refetch data from the external source
  const runSync = async (id: string) => {
    const variables = {
      input: {
        id: id,
      },
    };
    await executeCompSync(variables);
    setSyncSuccess(true);
  };

  if (winnersLoading) {
    return <CircularProgress />;
  }

  return (
    <div className="finalize-form__winners">
      <div className="finalize-form__winners__header">
        <h1>Select winners</h1>
        <h3>
          Select the players you wish to payout. All players are selected by
          default.
        </h3>
        <h3>
          Data is refetched every 10 minutes, if your event has completed but
          hasn't updated yet try clicking refresh.
        </h3>
        <Button
          onClick={() => runSync(context.data.id)}
          label={
            <span>
              <InlineIcon className="inline" icon={Refresh} /> Refresh
            </span>
          }
        />
        {syncError && (
          <div className="pt-2">
            <ErrorComponent
              id={context.data.id}
              visible={syncError ? true : false}
              message={syncError && syncError.message}
              path={location.pathname}
              type="CompetitionSync"
              closeable={false}
            />
          </div>
        )}
        {syncSuccess && (
          <div className="green pt-2">
            Competition successfully synced, refresh the page to see changes.
          </div>
        )}
        {syncLoading && <CircularProgress md />}
        {!syncSuccess && !syncLoading && (
          <div className="finalize-form__winners__select">
            {data.competition &&
              data.competition.standings.map((standing, k) => (
                <WinningTeam
                  key={k}
                  standing={standing}
                  handleSelect={togglePlayer}
                  entrantsToPayout={entrantsToPayout}
                />
              ))}
          </div>
        )}
        {error && <div className="red pt-2">{error.message}</div>}
        {success && (
          <div className="green pt-2">
            Competition has been successfully finalized.
          </div>
        )}
      </div>
    </div>
  );
};

export default Winners;

type QueryResponse = {
  data: { competition: CompetitionType };
  loading: boolean;
  error?: any;
};
