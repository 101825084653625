import React from 'react';
import { StaffMember } from 'types/Community';

const StaffMemberItem = (props: { staff: StaffMember }) => {
  return (
    <div className="community-staff__member">
      <img src={props.staff.user.avatarURL ? props.staff.user.avatarURL : '/ctx-user-icon.jpg'} alt="" />
      <h3>{props.staff.user.displayName}</h3>
    </div>
  );
};
export default StaffMemberItem;