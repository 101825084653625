import { useState, useRef } from "react";
import ReactGA from "react-ga";
import { Link, useNavigate } from "react-router-dom";
import { InlineIcon } from "@iconify/react";

import EyeIcon from "@iconify/icons-uil/eye";
import EyeSlashIcon from "@iconify/icons-uil/eye-slash";

import Button from "components/Button";
import Footer from "components/Footer";
import Parallax from "components/Parallax";

const GettingStarted = () => {
  const navigate = useNavigate();
  const s2Ref = useRef(null);

  const [sectionOneVisible, setSectionOneVisible] = useState(true);
  const [sectionTwoVisible, setSectionTwoVisible] = useState(true);
  const [sectionThreeVisible, setSectionThreeVisible] = useState(true);

  const executeScroll = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleCtaClick = (e, type: string) => {
    ReactGA.event({
      action: `Clicked ${type} on getting started`,
      category: "Call-to-action",
      label: "Getting Started CTA",
    });
    switch (type) {
      case "create competition":
        navigate("/create/competition");
        break;
      case "view competitions":
        navigate("/competitions");
        break;
      case "create community":
        navigate("/create/community");
        break;
      case "view communities":
        navigate("/communities");
        break;
      default:
        break;
    }
  };

  return (
    <div className="">
      <div className="vanity getting-started">
        <Parallax url="/context-banner-new.png" />
        <div className="mx-auto -mt-44 px-6 md:px-40 lg:-mt-32">
          <div className="space-y-8">
            <h1 className="text-5xl font-bold text-white">Getting Started</h1>
            <h3 className="text-2xl text-neutral-300">
              Need a quick run through on the Context platform?
            </h3>
          </div>
          <div className="vanity__content">
            <div className="vanity__content__section one">
              <div className="vanity__content__header">
                <h1>
                  <span className="vanity__content__header__number">1.</span>
                  Competitions
                </h1>
                <span
                  className="vanity__content__header__eye flex items-center gap-x-2"
                  onClick={() => setSectionOneVisible(!sectionOneVisible)}
                >
                  {sectionOneVisible ? (
                    <>
                      <InlineIcon icon={EyeSlashIcon} /> Hide me
                    </>
                  ) : (
                    <>
                      <InlineIcon icon={EyeIcon} /> Show me
                    </>
                  )}
                </span>
              </div>
              {sectionOneVisible && (
                <div className="vanity__content__section__content">
                  <p className="vanity__content__section__content__biline">
                    Competitions are the backbone of the Context platform. They
                    operate hand in hand with our external integrations such as
                    start.gg, Battlefy & Challonge. Payouts are calculated based
                    on the standings of your tournament, so you don't need to
                    worry about it.
                    <br />
                    <br />
                    In order to verify payouts go to the correct players, when
                    running your bracket please make sure that users are signed
                    up with an actual account on the respective platform where
                    the event is being hosted, manually entering users into the
                    bracket will create problems when finalizing a competition.
                  </p>
                  <div className="vanity__content__section__content__subheading">
                    <h1>Creating a Competition</h1>
                    <h3>
                      To create a Context Competition, you can complete a
                      creation wizard, you can do that{" "}
                      <Link to="/create/competition">here</Link>.
                    </h3>
                  </div>
                  <div className="vanity__content__section__content__steps">
                    <div className="vanity__content__section__content__step">
                      <div className="vanity__content__section__content__step__header">
                        <h1>
                          <span>Step 1:</span> Begin
                        </h1>
                      </div>
                      <div className="vanity__content__section__content__step__content">
                        The first wizard step allows you to select a Context
                        Community to create this competition under, giving you
                        the ability to have a central hub to house all of the
                        competitions relating to your organization.
                        <p
                          className="vanity__content__section__content__step__content__nav"
                          onClick={() => executeScroll(s2Ref)}
                        >
                          Take me to the Community section →
                        </p>
                      </div>
                    </div>
                    <div className="vanity__content__section__content__step">
                      <div className="vanity__content__section__content__step__header">
                        <h1>
                          <span>Step 2:</span> Provider
                        </h1>
                      </div>
                      <div className="vanity__content__section__content__step__content">
                        The second step you'll be completing is selecting your
                        provider. A provider is generally where your event's
                        bracket is hosted. Currently we support{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="vanity__content__section__content__step__content__link"
                          href="https://start.gg"
                        >
                          start.gg
                        </a>
                        ,{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="vanity__content__section__content__step__content__link"
                          href="https://challonge.com"
                        >
                          Challonge
                        </a>{" "}
                        &{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="vanity__content__section__content__step__content__link"
                          href="https://battlefy.com"
                        >
                          Battlefy
                        </a>
                        .
                      </div>
                    </div>
                    <div className="vanity__content__section__content__step">
                      <div className="vanity__content__section__content__step__header">
                        <h1>
                          <span>Step 3:</span> Configure Event
                        </h1>
                      </div>
                      <div className="vanity__content__section__content__step__content">
                        The configure step may be different depending on which
                        provider you select on the previous step, however, it
                        usually involves pasting the URL to your tournament's
                        bracket.
                      </div>
                    </div>
                    <div className="vanity__content__section__content__step">
                      <div className="vanity__content__section__content__step__header">
                        <h1>
                          <span>Step 4:</span> Prize Breakdown
                        </h1>
                      </div>
                      <div className="vanity__content__section__content__step__content">
                        Configuring the prize breakdown is essential, this
                        determines who in your tournament gets a cut of the
                        prize pool and how much they receive.
                        <br />
                        <br />
                        By default, the prize split inputs are percentages of
                        the total prize pool, for example, if you enter 50 into
                        the input for 1st place, they will receive 50% of the
                        prize pool after the competition has concluded.
                        Alternatively, you can click the switch in the top right
                        to change the input boxes to amounts, where you can
                        enter a flat amount, for example, if you enter 250 into
                        the 1st place input, the 1st place team will receive
                        $250 in your selected currency.
                      </div>
                    </div>
                    <div className="vanity__content__section__content__step">
                      <div className="vanity__content__section__content__step__header">
                        <h1>
                          <span>Step 5:</span> Prize Pool
                        </h1>
                      </div>
                      <div className="vanity__content__section__content__step__content">
                        Here is where you input the amount you would like for
                        the total prize pool along with the currency. Donations
                        to your competition will also be locked to this
                        currency.
                      </div>
                    </div>
                    <div className="vanity__content__section__content__step">
                      <div className="vanity__content__section__content__step__header">
                        <h1>
                          <span>Step 6:</span> Checkout
                        </h1>
                      </div>
                      <div className="vanity__content__section__content__step__content">
                        The final step of the competition creation wizard. Here
                        you select how you wish to pay for the competition's
                        prize pool. Keep in mind when paying via PayPal the
                        amount may look like more than you selected on the prize
                        pool step. This is because we calculate the fees before
                        you are redirected for transparency. Context charges a
                        3% service fee, along with other fees which you will be
                        able to see on the order confirmation screen.
                      </div>
                    </div>
                  </div>
                  <div className="vanity__content__section__content__buttons">
                    <Button
                      small
                      label="Create a Competition"
                      onClick={(e) => handleCtaClick(e, "create competition")}
                    />
                    <Button
                      small
                      label="View Competitions"
                      outline
                      onClick={(e) => handleCtaClick(e, "view competitions")}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="vanity__content__section two">
              <div className="vanity__content__header" ref={s2Ref}>
                <h1>
                  <span className="vanity__content__header__number">2.</span>
                  Communities
                </h1>
                <span
                  className="vanity__content__header__eye flex items-center gap-x-2"
                  onClick={() => setSectionTwoVisible(!sectionTwoVisible)}
                >
                  {sectionTwoVisible ? (
                    <>
                      <InlineIcon icon={EyeSlashIcon} /> Hide me
                    </>
                  ) : (
                    <>
                      <InlineIcon icon={EyeIcon} /> Show me
                    </>
                  )}
                </span>
              </div>
              {sectionTwoVisible && (
                <div className="vanity__content__section__content">
                  <p className="vanity__content__section__content__biline">
                    A Context Community is the central hub for your organization
                    or esports community. Selecting a community on the first
                    step when creating a competition attaches it to it, meaning
                    the competiton will show on your community landing page.
                    <br />
                    <br />
                    Communities can also facilitate Subscriptions and Direct
                    donations, allowing you to explore new sources of revenue
                    and further fund your competitions. A portion of the revenue
                    is allowed to be freely withdrawn from the Context platform,
                    however the remaining portion will stay within the system
                    and can be put towards new competitions or donations to
                    other competitions.
                  </p>
                  <div className="vanity__content__section__content__subheading">
                    <h1>Creating a Community</h1>
                    <h3>
                      To create a Context Community, you will have to complete a
                      creation wizard, you can do that{" "}
                      <Link to="/create/community">here</Link>.
                    </h3>
                  </div>
                  <div className="vanity__content__section__content__steps">
                    <div className="vanity__content__section__content__step">
                      <div className="vanity__content__section__content__step__header">
                        <h1>
                          <span>Step 1:</span> Begin
                        </h1>
                      </div>
                      <div className="vanity__content__section__content__step__content">
                        The first step allows you to customize your community,
                        such as the name, regions & images. For best results, we
                        recommend using 200x200 resolution for the Logo &
                        1920x480 resolution for the Banner.
                      </div>
                    </div>
                    <div className="vanity__content__section__content__step">
                      <div className="vanity__content__section__content__step__header">
                        <h1>
                          <span>Step 2:</span> Subscriptions
                        </h1>
                      </div>
                      <div className="vanity__content__section__content__step__content">
                        The second step will give you the ability to add custom
                        Community Subscription plans. These can range from tier
                        1 to tier 3 subscriptions with your preferred payment
                        amount & currency. Subscriptions are charged monthly.
                      </div>
                    </div>
                  </div>
                  <div className="vanity__content__section__content__buttons">
                    <Button
                      label="Create a Community"
                      small
                      onClick={(e) => handleCtaClick(e, "create community")}
                    />
                    <Button
                      label="View Communities"
                      small
                      outline
                      onClick={(e) => handleCtaClick(e, "view communities")}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="vanity__content__section three">
              <div className="vanity__content__header">
                <h1>
                  <span className="vanity__content__header__number">3.</span>
                  Fees
                </h1>
                <span
                  className="vanity__content__header__eye flex items-center gap-x-2"
                  onClick={() => setSectionThreeVisible(!sectionThreeVisible)}
                >
                  {sectionThreeVisible ? (
                    <>
                      <InlineIcon icon={EyeSlashIcon} /> Hide me
                    </>
                  ) : (
                    <>
                      <InlineIcon icon={EyeIcon} /> Show me
                    </>
                  )}
                </span>
              </div>
              {sectionThreeVisible && (
                <div className="vanity__content__section__content">
                  <p>
                    Context charges a 3% service fee for every transaction.
                    Along with this, fees for PayPal include both a transaction
                    fee & payout fee. The transaction fee is 4.4% + $0.30 per
                    transaction whereas the payout fee is 2%. We charge this
                    payout fee when money comes into the system in order to
                    ensure players receive 100% of the winnings when
                    withdrawing.
                    <br />
                    <br />
                    You can see a full transparent breakdown of the fees charged
                    before confirming your payment.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default GettingStarted;
