import { Profile } from "types/Profile";

import Header from "../Header";
import Navigation from "../Navigation";

type LayoutProps = {
  user: Profile;
  children: any;
};

const Layout = ({ user, children }: LayoutProps) => {
  return (
    <div className="profile-v2__container mt-36 lg:my-16 mx-auto">
      <Header
        thumbnail={user.avatarURL}
        displayName={user.displayName}
        tagline="Welcome to your Context profile, view your winnings, connect external profiles	&amp; view your communities"
      />
      <div className="profile-v2__content">
        <Navigation />
        <div className="profile-v2__view">{children}</div>
      </div>
    </div>
  );
};
export default Layout;
