import React from 'react';

const Switch = (props) => {
  return (
    <div className={`custom-switch ${props.inverse ? 'inverse' : ''} ${props.className ? props.className : ''}`}>
      <span
        className={`custom-switch__label before
        ${!props.checked && props.after
            ? 'active'
            : (props.checked && !props.after ? 'active' : '')}`
        }>
        {props.before}
      </span>
      <span className="ui-switch is-animated">
        <input
          type="checkbox"
          id={props.id}
          className="ui-checkbox"
          checked={props.checked}
          onChange={props.onChange}
        />
        <span className="ui-button"></span>
      </span>
      <span className={`custom-switch__label after ${props.checked && 'active'}`}>{props.after}</span>
    </div>
  );
};
export default Switch;