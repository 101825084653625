import {
  CheckCircleIcon,
  LockClosedIcon,
  PencilAltIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import FormikWizard from "components/FormikWizard";
import { Step } from "components/FormikWizard/types";
import { FormikHelpers, FormikValues } from "formik";
import React from "react";
import TeamDetails from "./Steps/TeamDetails";

import * as Yup from "yup";

interface NewTeamWizardProps {
  data: any;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => void | Promise<any>;
  isLoading: boolean;
}

const NewTeamWizard = ({ data, isLoading, onSubmit }: NewTeamWizardProps) => {
  const initialValues = {
    team: { name: "", logo: "" },
  };

  // Step Form Spread: https://stackoverflow.com/a/47771259
  const steps: Step[] = [
    {
      component: () => <TeamDetails />,
      validationSchema: Yup.object().shape({
        team: Yup.object().shape({
          name: Yup.string()
            .required("Team Name is required")
            .min(3, "Must be at least 3 characters"),
          logo: Yup.string(),
        }),
      }),
      extraProps: {
        title: "Team Details",
      },
    },
  ];

  return (
    <FormikWizard
      initialValues={initialValues}
      activeStepIndex={0}
      onSubmit={onSubmit}
      steps={steps}
    >
      {({
        renderComponent,
        currentStepIndex,
        handlePrev,
        handleNext,
        isNextDisabled,
        isPrevDisabled,
        isLastStep,
      }) => (
        <div className="flex flex-col gap-y-6 lg:flex-row lg:gap-x-8">
          <div className="flex flex-initial flex-col gap-y-2">
            {steps.map((step, i) => (
              <div
                key={i}
                className={classNames(
                  i == currentStepIndex &&
                    "border-ctx-orange border-opacity-60 bg-gradient-to-r from-ctx-orange/20 to-orange-800/10 text-ctx-orange/90",
                  i < currentStepIndex &&
                    "border-green-500 border-opacity-40 bg-gradient-to-r from-green-800/20 to-green-900/10 text-green-400/70",
                  i > currentStepIndex &&
                    "border-gray-300 border-opacity-20 bg-gradient-to-r from-neutral-400/5 to-neutral-600/5 text-neutral-300/50",
                  "rounded-md border py-2 pl-1 pr-12"
                )}
              >
                {i == currentStepIndex && (
                  <PencilAltIcon className="mr-1 -mt-1 inline h-6 w-6 text-ctx-orange/90" />
                )}
                {i < currentStepIndex && (
                  <CheckCircleIcon className="mr-1 -mt-1 inline h-6 w-6 text-green-400/70" />
                )}
                {i > currentStepIndex && (
                  <LockClosedIcon className="mr-1 -mt-1 inline h-6 w-6 text-neutral-300/50" />
                )}
                {step.extraProps.title}
              </div>
            ))}
          </div>
          <div className="mb-4 flex-1 rounded-lg border border-ctx-orange border-opacity-50 bg-opacity-5 bg-gradient-to-r from-ctx-orange/5 to-orange-900/5">
            <div className="space-y-4">
              <div className="space-y-4 px-4 py-5 sm:px-8 md:px-16">
                {renderComponent()}

                <div className="mt-4 flex justify-between">
                  <button
                    onClick={handlePrev}
                    type="button"
                    className={classNames(
                      isPrevDisabled && "invisible",
                      " rounded-md border border-context-black border-opacity-50 bg-context-gray bg-opacity-60 px-6 py-1 text-neutral-400 transition hover:bg-context-dark hover:text-neutral-500 hover:outline-none"
                    )}
                  >
                    Back
                  </button>
                  <div className="inline-flex items-center">
                    <button
                      type="submit"
                      onClick={handleNext}
                      disabled={isLoading}
                      className="ctx-gradient-button inline-flex items-center px-6 py-1.5"
                    >
                      {isLastStep
                        ? isLoading
                          ? "Submitting"
                          : "Submit"
                        : "Next"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </FormikWizard>
  );
};

export default NewTeamWizard;
