import { useProfileDetailsQuery } from "api/graphql";
import LoadingData from "components/LoadingData";

import { Layout, Wallet } from "domains/profile/components";

const Profile = () => {
  const { data, isLoading } = useProfileDetailsQuery();

  if (isLoading) {
    return <LoadingData />;
  }

  return (
    <>
      <Layout user={data?.profile?.user}>
        <Wallet profile={data?.profile} />
      </Layout>
    </>
  );
};

export default Profile;
