import Player from './Player';
import { placementResolver } from 'services/helpers/resolvers';

const WinningTeam = (props) => {
  const { standing, entrantsToPayout, handleSelect } = props;

  return (
    <div className="finalize-form__winners__select__team">
      <div className="finalize-form__winners__select__team__header">
        <div className="finalize-form__winners__select__team__header__placement">
          {placementResolver(standing.position)}
        </div>
        <div className="finalize-form__winners__select__team__header__name">
          {standing.name}
        </div>
        <div className="finalize-form__winners__select__team__header__prize">
          {standing.prize.display}
        </div>
      </div>
      <div className="finalize-form__winners__select__team__players">
        {
          standing.players.map((p, k) => <Player
            key={k}
            data={{ teamId: standing.id, player: { id: p.id, type: p.type, profileId: p.profileId } }}
            playerData={p}
            handleSelect={handleSelect}
            selected={entrantsToPayout.some(x => x.playerBreakdowns.some(y => y.id === p.id))}
          />)
        }
      </div>
    </div>
  );
};
export default WinningTeam;