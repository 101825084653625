import { Icon } from '@iconify/react';

type EventInfoCardProps = {
  icon: any,
  text: string,
  iconClass?: string,
}

const EventInfoCard = (props: EventInfoCardProps) => {
  return (
    <div className="event-info-card">
      <div className={`event-info-card__icon ${props.iconClass}-bg`}>
        <Icon icon={props.icon} />
      </div>
      <div className="event-info-card__text">
        <span>{props.text}</span>
      </div>
    </div>
  );
};
export default EventInfoCard;