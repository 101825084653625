import React, { useState } from "react";
import { InlineIcon } from "@iconify/react";
import Testimonial from "../Testimonial/";
import { TestimonialType } from "types/Components";

import arrowLeft from "@iconify/icons-uil/arrow-left";
import arrowRight from "@iconify/icons-uil/arrow-right";

type TestimonialsProps = {
  testimonials: TestimonialType[];
};

const Testimonials = (props: TestimonialsProps) => {
  const [refs, setRefs] = useState([]);
  const [_, setCurrent] = useState(props.testimonials[0]);
  const [active, setActive] = useState(0);
  const [leftOffset, setLeftOffset] = useState("");

  const handleArrowClick = (e, type: string) => {
    console.log("current left offset:", leftOffset);
    let newIndex: number;
    switch (type) {
      case "left":
        if (active === 0) return;
        newIndex = active - 1;
        if (refs[newIndex])
          updatePos(refs[newIndex].offsetLeft, newIndex, "left");
        setCurrent(props.testimonials[newIndex]);
        setActive(newIndex);
        break;
      case "right":
        newIndex = active + 1;
        if (newIndex >= props.testimonials.length) return;
        if (refs[newIndex])
          updatePos(refs[newIndex].offsetLeft, newIndex, "right");
        setCurrent(props.testimonials[newIndex]);
        setActive(newIndex);
        break;
    }
  };

  const updatePos = (offset: number, index: number, dir: string) => {
    let offsetCssString: string;
    switch (dir) {
      case "left":
        if (index > 0) {
          offsetCssString = `${offset / index}px`;
        } else {
          offsetCssString = `0px`;
        }
        break;
      case "right":
        if (index > 0) {
          offsetCssString = `-${offset * index}px`;
          console.log("offsetCssString: ", offsetCssString);
        } else {
          offsetCssString = `0px`;
        }
        break;
    }
    setLeftOffset(offsetCssString);
  };

  const setRef = (ref: any, idx: number) => {
    refs[idx] = ref;
    setRefs(refs);
  };

  return (
    <div className="testimonials">
      {active > 0 && (
        <span
          className="testimonials__arrow arrow__left"
          onClick={(e) => handleArrowClick(e, "left")}
        >
          <InlineIcon icon={arrowLeft} />
        </span>
      )}
      <div
        className="testimonials__wrapper"
        style={{
          marginLeft: leftOffset,
        }}
      >
        {props.testimonials.map((t, k) => (
          <Testimonial
            key={k}
            index={k}
            name={t.name}
            biline={t.biline}
            message={t.message}
            avatarURL={t.avatarURL}
            twitter={t.twitter}
            refFunc={setRef}
          />
        ))}
      </div>
      {active + 1 != props.testimonials.length && (
        <span
          className="testimonials__arrow arrow__right"
          onClick={(e) => handleArrowClick(e, "right")}
        >
          <InlineIcon icon={arrowRight} />
        </span>
      )}
    </div>
  );
};
export default Testimonials;
