import { useState } from "react";
import ReactGA from "react-ga";

import { redirectToLogin } from "utils";
import {
  standingsTableDataResolver,
  contributorsTableDataResolver,
} from "services/helpers/resolvers";

import CompetitionView from "components/CompetitionView";
import DonationModal from "components/Modals/DonationModal";
import ErrorComponent from "components/Error";
import FinalizeModal, { FinalizeTypes } from "components/Modals/FinalizeModal";
import { useMyProfile } from "lib/profile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetCompetitionQuery } from "api/graphql";
import LoadingData from "components/LoadingData";

const ViewCompetition = () => {
  const nav = useNavigate();
  const { compId } = useParams();
  const location = useLocation();
  const user = useMyProfile();

  const [donateModalOpen, setDonateModalOpen] = useState(false);
  const [finalizeModalOpen, setFinalizeModalOpen] = useState(false);

  const { data, isError, isLoading, error } = useGetCompetitionQuery({
    id: compId,
  });

  if (isError) {
    return (
      <div
        style={{
          width: "70vw",
          margin: "0 auto",
        }}
      >
        <ErrorComponent
          closeable={false}
          visible={true}
          message={error.message}
          type="COMMUNITY_COMPETITION_FETCH"
          id={compId}
          path={location.pathname}
          inverse
        />
      </div>
    );
  }

  if (isLoading) {
    return <LoadingData />;
  }

  const competition = data?.competition;

  const standingsTableHeaders = [
    { name: "Placement", props: { align: "center" } },
    { name: "Team", props: { align: "left" } },
    { name: "Players", props: { align: "left" } },
    { name: "Prize", props: { align: "left" } },
  ];
  const standingsTableData = standingsTableDataResolver(competition);

  const contributorsTableHeaders = [
    { name: "Position", props: { align: "center" } },
    { name: "Name", props: { align: "left" } },
    { name: "Amount", props: { align: "left" } },
  ];

  const contributorsTableData = contributorsTableDataResolver(
    competition.contributors
  );

  const toggleModal = (value, type) => {
    ReactGA.modalview(`community-competition-${type}-modal`);
    switch (type) {
      case "donate":
        if (value === true && !user) {
          const redirectTo = `${location.pathname}`;
          redirectToLogin(nav, redirectTo);
          return;
        }
        setDonateModalOpen(value);
        break;
      case "finalize":
        setFinalizeModalOpen(value);
        break;
    }
  };

  return (
    <div>
      {competition && (
        <FinalizeModal
          type={FinalizeTypes.COMPETITION}
          visible={finalizeModalOpen}
          toggleModal={(val) => toggleModal(val, "finalize")}
          data={
            competition && { id: competition.id, status: competition.status }
          }
        />
      )}
      {competition && (
        <DonationModal
          visible={donateModalOpen}
          toggleModal={(val) => toggleModal(val, "donate")}
          competitionData={competition}
          user={user}
        />
      )}
      <div className="parallax"></div>
      <CompetitionView
        competition={competition}
        contributorsTableHeaders={contributorsTableHeaders}
        contributorsTableData={contributorsTableData}
        standingsTableHeaders={standingsTableHeaders}
        standingsTableData={standingsTableData}
        toggleModal={toggleModal}
      />
    </div>
  );
};

export default ViewCompetition;
