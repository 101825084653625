import React from 'react';
import { Money } from 'types/Shared';

type AmountViewProps = {
  amount: Money
  className?: string
};

const AmountView = (props: AmountViewProps) => {
  return (
    <span className={props.className}>{props.amount && props.amount.display}<span className="text-smaller pl-02">{props.amount && props.amount.currency}</span></span>
  )
};
export default AmountView;