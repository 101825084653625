import { PlayerType } from 'types/Competition';

type PlayerProps = {
  data: any
  playerData: PlayerType
  selected?: boolean
  handleSelect: any
}

const Player = (props: PlayerProps) => {
  return (
    <div
      className={`finalize-form__winners__select__team__players__player ${props.selected ? 'active' : ''}`}
      onClick={() => props.handleSelect(props.data.teamId, props.data.player)}
    >
      {props.playerData.name}
    </div>
  );
};
export default Player;