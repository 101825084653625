import React from "react";

const Radio = (props) => {
  return (
    <div className="flex items-center gap-x-1.5">
      <input
        className="h-5 w-5 text-ctx-orange focus:ring-ctx-orange "
        checked={props.checked}
        type="radio"
        value={props.value}
        name={props.name}
        id={props.id}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <label htmlFor={props.id}>
        <span>{props.label}</span>
      </label>
    </div>
  );
};
export default Radio;
