import { expires, createCookie } from "./cookie";

export const amounts = ["5", "10", "25", "50", "100", "150", "250"];
export const currencies = ["USD", "EUR", "GBP", "AUD", "CAD"];
export const currencyOpts = [
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "AUD",
    value: "AUD",
  },
  {
    label: "CAD",
    value: "CAD",
  },
];
export const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const redirectToLogin = (nav, redirectUrl) => {
  // create our redirect url cookie, expiring after 1 minute
  createCookie("ctx-redirect", redirectUrl, expires);
  const url = `/api/auth/login?redirectUrl=${redirectUrl}`;
  nav(url);
};

const formatSSN = (ssn: string): string => {
  return `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5, 9)}`;
};

export const validSSN = (plainSSN): Promise<Boolean> => {
  return new Promise((resolve, reject) => {
    // format ssn from 123456789 to 123-45-6789
    const formattedSSN = formatSSN(plainSSN);

    const regex = new RegExp("^\\d{3}-?\\d{2}-?\\d{4}$");
    const valid = regex.test(formattedSSN);

    if (!valid) reject(new Error("false"));

    return resolve(true);
  });
};
