import React from "react";

import { ReactComponent as SingleElim } from "images/quantum/single_elim.svg";
import { useGetCompetitions } from "api/api";

const SelectCompetition = () => {
  const { data } = useGetCompetitions({ limit: 5, offset: 0 });

  return (
    <div className="space-y-5">
      <div>
        <h1 className="text-2xl font-semibold text-neutral-100">
          Setup Your Competition
        </h1>
        <p className="text-neutral-400">
          Select the type of competition you want to create
        </p>
      </div>
      <div className="">
        {/* if previous competition lets display them first... */}

        <div>
          <h2 className="text-xl font-semibold text-neutral-100">Presets</h2>
        </div>
        <div className="grid grid-cols-3 gap-x-4">
          <div className="rounded-md border border-context-black border-opacity-50 bg-context-gray bg-opacity-60 px-4 pb-1 text-neutral-400 transition hover:cursor-pointer hover:bg-context-dark">
            <div>
              <SingleElim
                className="mx-auto h-24 text-neutral-400"
                fill="currentColor"
              />
            </div>
            <div>
              <h2 className="text-neutral-300">Single Elimination</h2>
              <p>One shot, one opportunity</p>
            </div>
          </div>
          <div className="rounded-md border border-context-black border-opacity-50 bg-context-gray bg-opacity-60 px-4 pb-1 text-neutral-400 transition hover:cursor-pointer hover:bg-context-dark">
            <div>
              <SingleElim
                className="mx-auto h-24 text-neutral-400"
                fill="currentColor"
              />
            </div>
            <div>
              <h2 className="text-neutral-300">Double Elimination</h2>
              <p>Two chances for glory</p>
            </div>
          </div>
          <div className="rounded-md border border-context-black border-opacity-50 bg-context-gray bg-opacity-60 px-4 pb-1 text-neutral-400 transition hover:cursor-pointer hover:bg-context-dark">
            <div>
              <SingleElim
                className="mx-auto h-24 text-neutral-400"
                fill="currentColor"
              />
            </div>
            <div>
              <h2 className="text-neutral-300">Round Robin</h2>
              <p>Everyone Plays Each other</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCompetition;
