import ExternalProfilesView from "./ExternalProfiles";
import MyCommunities from "./MyCommunities";
import { Route, Routes } from "react-router-dom";
import Profile from "./Profile";
import Withdraw from "./Withdraw";
import Claim from "./Claim";
import Debug from "./Debug";

const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Profile />} />
      <Route path="/external-profiles" element={<ExternalProfilesView />} />
      <Route path="/my-communities" element={<MyCommunities />} />
      <Route path="/withdraw" element={<Withdraw />} />
      <Route path="/claim/:id" element={<Claim />} />
      <Route path="/debug" element={<Debug />} />
    </Routes>
  );
};

export default ProfileRoutes;
