import { Dialog, Transition } from "@headlessui/react";
import { PhotographIcon } from "@heroicons/react/outline";
import { FileLike } from "@rpldy/shared";
import {
  useAbortAll,
  useRequestPreSend,
  useItemFinalizeListener,
  withRequestPreSendUpdate,
  useItemStartListener,
  useBatchStartListener,
  useItemAbortListener,
} from "@rpldy/tus-uploady";
import { asUploadButton } from "@rpldy/upload-button";
import UploadDropZone from "@rpldy/upload-drop-zone";
import UploadPreview, { PreviewItem } from "@rpldy/upload-preview";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { UploadIcon, UploadProgress } from "..";

const ItemPreviewWithCrop = withRequestPreSendUpdate(
  ({
    url,
    isFallback,
    updateRequest,
    requestData,
    width,
    height,
    radius,
  }: any) => {
    // create use state to store url
    const [croppedImg, setCroppedImg] = useState("");

    const getBlobFromCanvas = (canvas: HTMLCanvasElement, file: FileLike) => {
      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Canvas is empty"));
              return;
            }
            resolve(blob);
          },
          file.type,
          1
        );
      });
    };

    const onUploadCrop = useCallback(
      async (canvas: any) => {
        if (!updateRequest || !canvas) return;

        const file = requestData.items[0].file;
        requestData.items[0].file = await getBlobFromCanvas(canvas, file);
        let croppedImgUrl = URL.createObjectURL(requestData.items[0].file);

        requestData.items[0].file.url = croppedImgUrl;
        setCroppedImg(croppedImgUrl);

        // const token = await refreshCsrf();

        updateRequest({
          items: requestData.items,
          options: {
            destination: {
              params: {
                "file-name": file.name,
                "file-type": file.type,
              },
              // headers: {
              //   "X-CSRF-Token": token,
              // },
            },
          },
        });
      },
      [requestData, updateRequest]
    );

    if (!requestData) {
      return <></>;
    }

    if (isFallback) {
      return <img src={url} alt="The fallback" />;
    }

    if (croppedImg) {
      return width == 1920 && height == 480 ? (
        <>
          <img
            src={croppedImg}
            className="my-2 rounded-sm"
            alt="The cropped image"
          />
        </>
      ) : (
        <>
          <img
            src={croppedImg}
            className="mx-auto w-28 rounded-full"
            alt="The cropped image"
          />
        </>
      );
    }

    return (
      <CropProfile
        url={url}
        onUploadCrop={onUploadCrop}
        width={width}
        height={height}
        radius={radius}
      />
    );
  }
);

const CropProfile = ({ url, onUploadCrop, width, height, radius }: any) => {
  const [open, setOpen] = useState(true);
  const [scale, setScale] = useState(1);

  const [editorRef, setEditorRef]: any = useState(null);
  const cancelButtonRef = useRef(null);
  const abortAll = useAbortAll();

  const onClickCancel = () => {
    setOpen(false);
    abortAll();
  };

  const onClickSave = () => {
    if (!editorRef) return;

    // If you want the image resized to the canvas size (also a HTMLCanvasElement)
    const canvasScaled = editorRef.getImageScaledToCanvas();
    onUploadCrop(canvasScaled);
    setOpen(false);
  };

  const handleScale = (e: any) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClickCancel}
        initialFocus={cancelButtonRef}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="my-auto inline-block transform overflow-hidden rounded-lg border border-gray-300 bg-white px-4 pt-5 pb-4 text-left align-middle shadow-xl transition-all sm:p-6">
              {/* <div className="inline-block align-bottom border border-gray-800 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-auto sm:p-6"> */}
              <div className="">
                <div className="flex items-center justify-center text-2xl font-bold">
                  <span className="">Crop Image</span>
                </div>
                <div className="mt-3 space-y-4 sm:mt-5">
                  <AvatarEditor
                    ref={(editor: any) => setEditorRef(editor)}
                    image={url}
                    width={width}
                    height={height}
                    borderRadius={radius}
                    border={0}
                    scale={scale}
                    className={
                      width > 300
                        ? "mx-auto h-full max-h-24 max-w-sm bg-gray-50 sm:max-h-32 sm:max-w-lg md:max-h-44 md:max-w-2xl lg:max-h-48 lg:max-w-3xl xl:max-h-56 xl:max-w-4xl 2xl:max-h-64 2xl:max-w-5xl"
                        : "mx-auto bg-gray-50"
                    }
                  />
                  <div className="mx-5 flex gap-x-4">
                    <div className="flex-0 m-auto w-10 text-gray-500">
                      <PhotographIcon className="mx-auto w-6 px-0" />
                    </div>
                    <input
                      style={{}}
                      className="w-full flex-1 hover:cursor-ew-resize focus:outline-inflow-purple"
                      name="scale"
                      type="range"
                      onChange={handleScale}
                      min="1"
                      max="2"
                      step="0.01"
                      defaultValue="1"
                    />
                    <div className="flex-0 my-auto w-10 text-gray-500">
                      <PhotographIcon className="w-10 px-0" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 flex w-full justify-between gap-x-4 sm:mt-6">
                <div className="flex flex-none">
                  <button
                    type="button"
                    className="w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-sm  font-medium text-gray-500 shadow-sm transition hover:text-gray-900 focus:outline-inflow-purple"
                    onClick={() => onClickCancel()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
                <div className="flex flex-none gap-x-4">
                  <button
                    type="button"
                    className="w-full justify-center rounded-md border-2 border-inflow-purple bg-inflow-purple px-4 py-1 text-sm font-medium text-white shadow-sm transition hover:bg-white hover:text-inflow-purple focus:outline-purple-900"
                    onClick={() => onClickSave()}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const DropZone = ({
  onClick,
  onChange,
  cropValues,
  crop,
  ...buttonProps
}: any) => {
  const [hasItem, setHasItem] = useState(false);

  useRequestPreSend(async ({ items }) => {
    if (items.length === 0) {
      return {};
    }

    // const token = await refreshCsrf();

    // we should only have 1 file for a TUS upload
    return {
      options: {
        destination: {
          params: {
            "file-name": items[0].file.name,
            "file-type": items[0].file.type,
          },
          // headers: {
          //   "X-CSRF-Token": token,
          // },
        },
      },
    };
  });

  useItemStartListener((item) => {
    setHasItem(true);
  });

  useItemAbortListener((item) => {
    if (onChange) {
      onChange("");
    }

    setHasItem(false);
  });

  useItemFinalizeListener((item) => {
    if (!onChange) return;

    const id = item?.uploadResponse?.location || "";
    const index = id.lastIndexOf("/");

    if (index >= 0) {
      onChange(id.substr(index + 1));
      return;
    }

    onChange(id);
  });

  return (
    <UploadDropZone
      {...buttonProps}
      onDragOverClassName="bg-gray-800"
      extraProps={{ onClick }}
    >
      <div className="flex h-48 w-full cursor-pointer flex-col justify-center gap-x-4 rounded-md border-2 border-dashed border-gray-400 px-4 py-2 text-sm transition hover:border-gray-500">
        {!hasItem && (
          <div className="my-auto text-center font-medium text-gray-500">
            <UploadIcon className="mx-auto mb-4 h-8" />
            <span className="text-inflow-purple">Upload a file </span>
            or drag and drop
            <br />
            <span className="font-normal text-gray-400">
              PNG or JPG, up to 10MB
            </span>
          </div>
        )}

        <UploadPreview
          PreviewComponent={ItemPreviewWithCrop}
          previewComponentProps={cropValues}
        />
        {hasItem && <UploadProgress />}
      </div>
    </UploadDropZone>
  );
};

const UploadDropButton = asUploadButton((props: any) => {
  return <DropZone {...props} />;
});

export default UploadDropButton;
