import { useState } from "react";
import { DonationWizardStates } from "types/Wizard";

// components
import Amount from "components/DonationWizardSteps/Community/Amount";
import Checkout from "components/DonationWizardSteps/Community/Checkout";
import Wizard from "components/Wizard";

// icons
import moneyBill from "@iconify/icons-uil/money-bill";
import shoppingBag from "@iconify/icons-uil/shopping-bag";
import Modal from "components/Modal";
import { useNavigate } from "react-router-dom";
import { useNewCommunityDonationMutation } from "api/graphql";

const CommunityDonation = (props) => {
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { mutateAsync } = useNewCommunityDonationMutation({
    onSuccess: (data: any) => {
      if (data.communityDonationNew)
        nav(`/order/confirmation/${data.communityDonationNew.id}`);
    },
  });

  const steps = [
    {
      stepName: "Amount",
      stepDescription: "Enter your desired contribution amount",
      stepIcon: moneyBill,
      stepId: 1,
      stepComponent: Amount,
    },
    {
      stepName: "Checkout",
      stepDescription: "Select the method used to complete your contribution",
      stepIcon: shoppingBag,
      stepId: 2,
      stepComponent: Checkout,
    },
  ];

  const finalClick = async (states: DonationWizardStates) => {
    if (!states.checkout)
      return setError({
        message:
          "At least one payment method must be selected for this competition",
      });

    // no payments selected for a competition with a prize
    if (states.contribution.amount > 0 && states.checkout.options.length === 0)
      return setError({
        message:
          "At least one payment method must be selected for this competition",
      });

    // check if the checkout payment options are valid
    if (!states.checkout.isValid)
      return setError({ message: "Selected payment options are invalid." });

    setLoading(true);

    // build our payment configs
    const paymentConfigs = states.checkout.options;

    // build the new competition input
    const input = {
      communityId: props.communityData.id,
      amount: states.contribution.amount,
      currency: states.contribution.currency,
      paymentConfig: paymentConfigs,
    };

    mutateAsync({ input });
  };

  return (
    <Modal visible={props.visible} toggleModal={props.toggleModal}>
      <div className="donate-modal">
        <Wizard
          steps={steps}
          finalClick={finalClick}
          context={{ profile: props.profile, data: props.communityData }}
          noNegativeMargin={true}
          noHeaders={true}
          forceLoading={loading}
          forceError={error}
        />
      </div>
    </Modal>
  );
};

export default CommunityDonation;
