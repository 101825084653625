import React from "react";
import { Link } from "react-router-dom";

const Select = () => {
  return (
    <div className="-mt-16 flex h-screen flex-col items-center justify-center gap-y-8 py-12 px-4 text-white sm:px-6 lg:px-8">
      <div className="w-full max-w-xl space-y-8 rounded-lg bg-context-light p-5">
        <div className="space-y-4">
          <div>
            <h1 className="text-2xl font-bold">Create</h1>
            <h2 className="font-base text-neutral-400">
              Select what you would like to create
            </h2>
          </div>
          <div className="h-px bg-ctx-orange"></div>
          <Link
            className="hover:bg- block w-full rounded-lg  border-context-dark p-2 hover:bg-context-gray"
            to="/create/competition"
          >
            <h1 className="text-lg font-bold">Create Competition</h1>
            <p className="text-neutral-400">
              Create a Competition for your community
            </p>
          </Link>
          <Link
            className="hover:bg- block w-full rounded-lg  border-context-dark p-2 hover:bg-context-gray"
            to="/create/community"
          >
            <h1 className="text-lg font-bold">Create Community</h1>
            <p className="text-neutral-400">
              Create a Community to crowdfund and distribute prize pools
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Select;
