import Footer from "components/Footer";
import Player from "components/Help/player";
import Community from "components/Help/community";
import FAQ from "components/Help/faq";
import Parallax from "components/Parallax";

const Help = () => {
  return (
    <div>
      <Parallax url="/context-banner-new.png" />
      <div className="help-page__wrapper">
        <div className="help-page__header px-6">
          <h1>Help</h1>
          <h3>
            Need help? Select one of the topics below to find what you're
            looking for
          </h3>
        </div>
        <div className="mx-auto">
          <div className="flex w-full flex-col gap-x-4 px-4 xl:flex-row">
            <div className="mx-3 mt-5 flex-1 lg:mx-0 xl:mt-0">
              <div>
                <h1 className="text-3xl font-bold text-ctx-orange">
                  Community Help
                </h1>
                <h3 className="text-neutral-400">
                  Help related to communities
                </h3>
              </div>
              <Community />
            </div>
            <div className="mx-3 mt-5 flex-1 lg:mx-0 xl:mt-0">
              <div>
                <h1 className="text-3xl font-bold text-ctx-orange">
                  Player Help
                </h1>
                <h3 className="text-neutral-400">Help related to players</h3>
              </div>
              <Player />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Help;
