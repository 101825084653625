import React from "react";

import Input from "../Input";
import PaypalAccountsItem from "../PaypalAccountsItem";
import { ContextProfile } from "types/Profile";
import Button from "../Button";
import { useLocation } from "react-router-dom";

type InputError = {
  id: string;
  message: string;
};

type PaypalAccountsProps = {
  profile: any;
  selectedAccount: any;
  emailValues: any;
  onChange: Function;
  errors: InputError[];
};

const PaypalAccounts = (props: PaypalAccountsProps) => {
  const location = useLocation();

  return (
    <div className="paypal-accounts">
      {props.profile.connectedProfiles &&
        props.profile.connectedProfiles.length > 0 &&
        props.profile.connectedProfiles.map(
          (x, k) =>
            x.provider === "paypal" && (
              <PaypalAccountsItem
                key={`${x}-${k}`}
                profile={x}
                selected={props.selectedAccount}
                onChange={props.onChange}
              />
            )
        )}
      <div className="paypal-accounts__input">
        <h3>Please enter your and confirm your PayPal account below</h3>
        <Input
          className="paypal-accounts__input__email"
          id="paypalEmail"
          name="paypalEmail"
          label="PayPal Email"
          placeholder="email@provider.com"
          onChange={props.onChange}
          value={props.emailValues.paypalEmail}
          error={props.errors.find((x) => x.id === "paypalEmail")}
        />
        <Input
          className="paypal-accounts__input__email--confirm"
          id="paypalEmailConfirm"
          name="paypalEmailConfirm"
          label="Confirm PayPal Email"
          placeholder="email@provider.com"
          onChange={props.onChange}
          value={props.emailValues.paypalEmailConfirm}
          error={props.errors.find((x) => x.id === "paypalEmailConfirm")}
        />
      </div>
    </div>
  );
};
export default PaypalAccounts;
