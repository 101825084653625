export default {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    background: '#2f363b',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    border: '2px solid #dc3545',
    borderRadius: '4px',
    borderColor: state.isFocused ? '#FF7A00' : '#dc3545',
    '&:hover': {
      borderColor: '#dc3545'
    }
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#F3F3F3',
    padding: '0',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: '#FF7A00'
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#212529',
    fontWeight: 'bold'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '8px',
  }),
  option: (provided, state) => ({
    ...provided,
    color: '#F3F3F3',
    background: 'transparent',
    border: '0',
    '&:hover': {
      background: '#3D444B'
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    border: '0',
    background: '#2f363b',
    boxShadow: '0',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#FF7A00',
    '&:hover': {
      color: '#FF7A00',
    }
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
};