import React, { useEffect, useState } from "react";
import { PrizeClaimComponentProps } from "types/Wizard";
import countryList from "react-select-country-list";

import CustomSelect from "components/Select";

import SelectNewStyle from "styles/Select/SelectNewStyle";

const CountrySelect = (props) => {
  const { context, next, wizardError, sendState }: PrizeClaimComponentProps =
    props;

  const opts = countryList().data;

  const [country, setCountry] = useState({
    value: "US",
    label: "United States",
  });

  useEffect(() => {
    if (context.irstax && context.irstax.country) {
      setCountry(opts.find((x) => x.value === context.irstax.country));
    }
  }, [context]);

  next(() => {
    sendState("countrySelect", { country: country.value });
    return true;
  });

  return (
    <div>
      <div className="mb-3">
        <h1 className="mb-1 bold text-med">
          Please select your country of residence
        </h1>
        <h3 className="grey">
          This step is for tax purposes. If you are a US citizen but are
          residing elsewhere, select United States.
        </h3>
      </div>
      <CustomSelect
        styles={SelectNewStyle}
        id="country-select"
        onChange={(selected) => setCountry(selected)}
        value={country}
        options={opts}
        isSearchable
      />
    </div>
  );
};
export default CountrySelect;
