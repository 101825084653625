import Nav from "components/Nav";
import React from "react";

const Body = ({ children }: any) => {
  return (
    <div>
      <Nav />
      {children}
    </div>
  );
};

export default Body;
