import moment from "moment";
import { WinningsEdge } from "types/Profile";
import { winningsTableDataResolver } from "services/helpers/resolvers";

import CustomTable from "components/Table";
import CircularProgress from "components/CircularProgress";
import ErrorComponent from "components/Error";
import AmountView from "components/AmountView";
import { useLocation, useNavigate } from "react-router-dom";
import { useClaimWinningsMutation } from "api/graphql";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: function (number, withoutSuffix) {
      return withoutSuffix ? "now" : "a few seconds";
    },
    m: "1 min",
    mm: "%d mins",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 months",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

type WalletProps = {
  profile: any;
};

const Wallet = ({ profile }: WalletProps) => {
  const nav = useNavigate();
  const location = useLocation();

  const {
    mutateAsync: executeClaimWinnings,
    isLoading: winningsLoading,
    error: winningsError,
  } = useClaimWinningsMutation({
    onSuccess: ({ prizeClaim }) => {
      if (prizeClaim.type == "Redirect") {
        nav(prizeClaim.href);
        return;
      }

      if (prizeClaim.type == "Success") {
        nav(0);
        return;
      }
    },
  });

  const tableHeaders = [
    { name: "", props: { align: "left" } },
    { name: "Claim", props: { align: "left" } },
    { name: "Community", props: { align: "left" } },
    { name: "Competition", props: { align: "left" } },
    { name: "Amount", props: { align: "left" } },
    { name: "Expires", props: { align: "left" } },
  ];

  const claimWinning = (winning: WinningsEdge) => {
    const winningId = winning.node.id;
    const variables = {
      input: {
        id: winningId,
      },
    };

    executeClaimWinnings(variables);
  };

  const handleWithdrawClick = () => {
    nav("/profile/withdraw");
  };

  const tableData = winningsTableDataResolver(profile.winnings, claimWinning);

  return (
    <div>
      <div className="profile-v2__content__container">
        <div className="profile-v2__wallet__balances">
          <div className="profile-v2__wallet__balances__title">
            <h1>Your Balances</h1>
          </div>
          <div className="profile-v2__wallet__balances__accounts">
            <div className="profile-v2__wallet__balances__account">
              <h1 id="account-title">Deposit Account</h1>
              {profile.funds.deposit.length > 0 ? (
                profile.funds.deposit.map((amount, k) => (
                  <div
                    key={k}
                    className="profile-v2__wallet__balances__account__currency"
                  >
                    <AmountView amount={amount} />
                  </div>
                ))
              ) : (
                <div className="profile-v2__wallet__balances__account__currency">
                  <span id="no-funds">
                    You currently have no Deposit funds.
                  </span>
                </div>
              )}
              {profile.funds.deposit && (
                <span className="withdraw-button" onClick={handleWithdrawClick}>
                  Withdraw →
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="profile-v2__wallet__winnings">
          <div className="profile-v2__content__header">
            <h1>
              Your Winnings
              {winningsLoading && (
                <span>
                  <CircularProgress sm inverse />
                </span>
              )}
            </h1>
            <h3>
              Some competitions require you to claim your winnings before being
              able to withdraw them.
            </h3>
          </div>
          {winningsError && (
            <div className="profile-v2__wallet__winnings__error">
              <ErrorComponent
                id="claims-error"
                visible={winningsError ? true : false}
                message={winningsError && winningsError.message}
                path={location.pathname}
                type="WinningsClaim"
                closeable={false}
              />
            </div>
          )}
          <div className="profile-v2__wallet__winnings__table overflow-x-auto rounded-md bg-context-lighter py-2 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-ctx-orange">
            <CustomTable tableData={tableData} tableHeaders={tableHeaders} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
