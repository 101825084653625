import React from "react";
import { Icon } from "@iconify/react";

// components
import CustomSelect from "components/Select";
import Input from "components/Input";
import ToggleButtonGroup from "components/ToggleButtonGroup";

// icons
import euroIcon from "@iconify/icons-cil/euro";
import poundIcon from "@iconify/icons-cil/british-pound";
import dollarIcon from "@iconify/icons-cil/dollar";

// styles
import SelectStyles from "styles/Select/SelectNewStyle";

const AmountSelect = (props: any) => {
  if (!props.values) {
    return <div className="amount-select">No data</div>;
  }

  // select component needs this format for options :/
  const selectOptions = props.currencies.map((c: any) => ({
    value: c,
    label: c,
  }));
  const selectValues = {
    value: props.values.currency,
    label: props.values.currency,
  };

  return (
    <div className="amount-select">
      <div className="amount-select__amount">
        <div className="amount-select__amount__input">
          <Input
            id="amountInput"
            name="text"
            type="number"
            value={props.values.amountInputValue}
            label="Amount in selected currency"
            onChange={props.handleInputChange}
            autoComplete="false"
            placeholder="100"
            startAdornment={<Icon icon={dollarIcon} />}
            disabled={props.disabled}
          />
        </div>
        <div className="amount-select__amount__currency">
          <CustomSelect
            styles={SelectStyles}
            value={selectValues}
            onChange={props.handleCurrencyChange}
            options={selectOptions}
            isSearchable={false}
            name="currency-select"
            id="currency-select"
            label="Currency"
            disabled={props.disabled}
          />
        </div>
      </div>
      {props.amounts && (
        <ToggleButtonGroup
          id="toggleButtonGroup"
          handleToggleButtonChange={props.handleToggleButtonChange}
          value={props.values.amountInputValue}
          options={props.amounts}
          label="Quick-select an amount:"
          attachFront={
            props.values.currency === "EUR"
              ? euroIcon
              : props.values.currency === "GBP"
              ? poundIcon
              : dollarIcon
          }
          disabled={props.disabled}
        />
      )}
    </div>
  );
};
export default AmountSelect;
