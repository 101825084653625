import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ReactGA from "react-ga";

import { useGetCommunityOrdersQuery } from "api/graphql";
import { useMyProfile } from "lib/profile";
import { communityOrdersTableDataResolver } from "services/helpers/resolvers";

import OrderItemModal from "components/Modals/OrderItemModal";
import CustomTable from "components/Table";
import Button from "components/Button";
import ErrorComponent from "components/Error";
import LoadingLogo from "components/LoadingLogo";

const tableHeaders = [
  { name: "Date", props: { align: "left" } },
  { name: "Description", props: { align: "left" } },
  { name: "State", props: { align: "left" } },
  { name: "Amount", props: { align: "left" } },
  // { name: 'Action', props: { align: 'center' } },
];

const CommunityAdminOrders = () => {
  const location = useLocation();
  const user = useMyProfile();
  const { id } = useParams();

  const [modalOpen, setModalOpen] = useState(true);
  const [visibleOrder, setVisibleOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [after, setAfter] = useState(null);

  const { isLoading, isFetching, error, data } = useGetCommunityOrdersQuery(
    {
      id,
      after,
    },
    { keepPreviousData: true }
  );

  useEffect(() => {
    const orderData = data?.communityAdmin?.orders?.edges?.map(({ node }) =>
      communityOrdersTableDataResolver(node, toggleModal)
    );

    if (orderData != null) {
      var newData = [...orders, ...orderData];
      setOrders(newData);
    }
  }, [data]);

  if (isLoading) {
    return <LoadingLogo />;
  }

  if (error) {
    return (
      <ErrorComponent
        id="community_admin_orders"
        visible={error ? true : false}
        message={error && error.message}
        path={location.pathname}
        type="CommunityOrders"
        closeable={false}
        style={{ margin: "24px 0" }}
        inverse
      />
    );
  }

  const toggleModal = (value, order) => {
    ReactGA.modalview("community-staff-order-modal");
    setVisibleOrder(order);
    setModalOpen(value);
  };

  const pageInfo = data.communityAdmin.orders.pageInfo;

  const handleNextPage = async () => {
    setAfter(pageInfo.endCursor);
  };

  return (
    <React.Fragment>
      <OrderItemModal
        visible={modalOpen}
        toggleModal={(val) => toggleModal(val, null)}
        order={visibleOrder}
        profile={user}
        fixed={true}
      />
      <div className="my-6">
        <div className="rounded bg-context-light px-2 pt-2 pb-1 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-ctx-orange">
          <CustomTable tableData={orders} tableHeaders={tableHeaders} />
          <div className="community-admin__orders__load-more">
            <Button
              small
              label={
                isFetching
                  ? "Loading..."
                  : pageInfo?.hasNextPage
                  ? "Load more"
                  : "You've reached the end"
              }
              disabled={!pageInfo?.hasNextPage ?? true}
              onClick={handleNextPage}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommunityAdminOrders;
