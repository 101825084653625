import React from "react";

const RadioGroup = (props) => {
  return (
    <div className="radio-group">
      <h1 className={`radio-group__label ${props.error ? "error" : ""}`}>
        {props.label}
      </h1>
      <div className="">{props.children}</div>
    </div>
  );
};
export default RadioGroup;
