import { CalendarIcon } from "@heroicons/react/outline";
import FormInput from "components/FormInputs/FormInput";
import { Field } from "formik";
import React from "react";

const EventDetails = () => {
  return (
    <div className="space-y-5">
      <div>
        <h1 className="text-2xl font-semibold text-neutral-100">
          Competition Details
        </h1>
        <p className="text-neutral-400">
          Enter the details of your competition
        </p>
      </div>
      <div className="space-y-4 rounded-md shadow-sm sm:grid sm:grid-cols-3 sm:gap-x-4 sm:gap-y-2 sm:space-y-0">
        <div className="text-neutral-300">
          <div className="block text-sm font-semibold text-neutral-300">
            Type
          </div>
          <label className="block cursor-pointer">
            <Field
              type="radio"
              name="details.type"
              value="online"
              className="mr-1 -mt-0.5 h-4 w-4 cursor-pointer border-context-black bg-context-light bg-opacity-60 p-1 text-orange-500 focus:ring-orange-500"
            />
            Online
          </label>
          <label className="block cursor-pointer">
            <Field
              type="radio"
              name="details.type"
              value="offline"
              className="mr-1 -mt-0.5 h-4 w-4 cursor-pointer border-context-black bg-context-light bg-opacity-60 p-1 text-orange-500 focus:ring-orange-500"
            />
            Offline
          </label>
        </div>
        <div className="col-span-2 row-start-2">
          <FormInput
            name="details.name"
            placeholder="Competition Name"
            label="Competition Name"
          />
        </div>
        <div className="col-span-1 row-start-2">
          <FormInput
            name="details.series"
            placeholder="Series"
            label="Competition Series"
          />
        </div>
        <div className="col-span-3 row-start-3">
          <FormInput
            name="details.description"
            placeholder=""
            as="textarea"
            label="Competition Description"
          />
        </div>

        <div className="row-start-4">
          <FormInput
            name="details.start_date"
            placeholder=""
            type="date"
            label="Start Date"
            extraClassName=""
          />
        </div>

        <div className="row-start-5">
          <FormInput
            name="details.team_size"
            placeholder="3"
            label="Team Size"
            extraClassName="w-14"
          />
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
