import React from 'react';

// components
import { Icon, InlineIcon } from '@iconify/react';
import Input from 'components/Input';
import Switch from 'components/Switch';

// icons
import PercentageIcon from '@iconify/icons-uil/percentage';
import EuroIcon from '@iconify/icons-cil/euro';
import DollarIcon from '@iconify/icons-cil/dollar';

// styles
import { placementResolver } from 'services/helpers/resolvers';

const BreakdownForm = (props) => {
  const { values } = props;

  const handleListChange = (index, e) => {
    props.wizardError(null);
    props.resetError();

    const { breakdown } = values;
    breakdown[index] = Number(e.target.value);
    props.updateValues({
      ...values,
      breakdown
    });
  };

  return (
    <div>
      <div className="breakdown-step__switch">
        <Switch
          before='Percentage'
          after='Amount'
          checked={values.type === 'amount'}
          onChange={props.handleSwitchChange}
        />
      </div>
      <div className="breakdown-step__select">
        <Input
          label="Teams to payout"
          name="max-teams"
          id="max-teams"
          onChange={props.handleChange}
          error={props.errorType === "max-teams"}
          value={values.maxTeams}
        />
        {/* <CustomSelect
          styles={SelectStyles}
          value={selectValues}
          onChange={props.handleChange}
          options={selectOptions}
          isSearchable={false}
          error={props.errorType === 'select' ? true : false}
          name='max-teams-select'
          id='max-teams-select'
          label='Teams to payout'
        /> */}
        {props.validator.message('max-teams-select', values.maxTeams, 'required|numeric|min:1,num')}
      </div>
      <div className="breakdown-step__inputs">
        {values.breakdown.map((v, i) => (
          <Input
            key={i}
            id={`input-${i}`}
            label={'Payout ' + values.type.charAt(0).toUpperCase() + values.type.slice(1)}
            placeholder={values.type === 'amount' ? String(Math.floor(1000 / (i + 1))) : ((values.breakdown.length - i + 1) * 10).toString()}
            autoComplete="false"
            error={props.errorType === 'input' ? true : false}
            value={v || ''}
            onChange={(e) => handleListChange(i, e)}
            startAdornment={placementResolver(i + 1)}
            endAdornment={
              values.type === 'amount'
                ? <React.Fragment>
                  <Icon icon={DollarIcon} /> / <Icon icon={EuroIcon} />
                </React.Fragment>
                : <InlineIcon icon={PercentageIcon} />
            }
          />
        ))}
      </div>
    </div>
  );
};
export default BreakdownForm;