import React from 'react';

import Input from '../Input';

type AddressInputProps = {
  values: any
  handleChange: Function
  formErrors: any[]
};

const AddressInput = (props: AddressInputProps) => {
  return (
    <div className="address-input__container">
      <Input
        className="prize-payout__form__input address-input__address"
        id="address"
        name="address"
        label="Address"
        value={props.values.address}
        onChange={props.handleChange}
        error={props.formErrors.find(x => x.where === "address")}
      />
      <Input
        className="prize-payout__form__input address-input__opt"
        id="address_apartment"
        name="address_apartment"
        label="Apartment, Suite, etc. (optional)"
        value={props.values.address_apartment}
        onChange={props.handleChange}
        error={props.formErrors.find(x => x.where === "address_apartment")}
      />
      <Input
        className="prize-payout__form__input address-input__city"
        id="address_city"
        name="address_city"
        label="City"
        value={props.values.address_city}
        onChange={props.handleChange}
        error={props.formErrors.find(x => x.where === "address_city")}
      />
      <Input
        className="prize-payout__form__input address-input__zip"
        id="address_zip_code"
        name="address_zip_code"
        label="Zip Code"
        value={props.values.address_zip_code}
        onChange={props.handleChange}
        error={props.formErrors.find(x => x.where === "address_zip_code")}
      />
      <Input
        className="prize-payout__form__input address-input__state"
        id="address_state"
        name="address_state"
        label="State"
        value={props.values.address_state}
        onChange={props.handleChange}
        error={props.formErrors.find(x => x.where === "address_state")}
      />
    </div>
  );
};
export default AddressInput;