import { usePostCreateTeam } from "api/api";
import NewTeamWizard from "components/NewTeamWizard";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const CreateTeam = () => {
  const nav = useNavigate();
  const { mutateAsync, isLoading } = usePostCreateTeam();

  const onSubmit = async (values) => {
    const team_data = {
      aggregate_id: uuidv4(),
      name: values.team.name,
      logo: values.team.logo,
    };

    const data = await mutateAsync(
      { data: team_data },
      { onSuccess: () => nav("/team/" + team_data.aggregate_id) }
    );

    console.log("New team data", team_data, data);
  };

  return (
    // -mt-16 pt-16 just so we can render the noise background without having it on body (putting on the div below instead)
    <div
      style={{ backgroundImage: "url('/ctx_bg.png')" }}
      className="-mt-16 flex min-h-screen items-center pt-16 text-white"
    >
      <div className="mx-auto w-full max-w-7xl content-center justify-between p-4">
        <NewTeamWizard data={{}} onSubmit={onSubmit} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default CreateTeam;
