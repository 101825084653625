import { useGetInvite } from "api/api";
import { useParams } from "react-router-dom";
import TeamInvite from "./TeamInvite";

const GetInviteType = ({ type, id }) => {
  // We want to do different invites, team invites, competition invites, community invites, maybe admin invites
  switch (type) {
    case "team":
      return <TeamInvite id={id} />;
    default:
      return <div>Unknown invite type</div>;
  }
};

const Invite = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetInvite(id);

  if (isLoading) {
    return <>Loading Invite</>;
  }

  return (
    <div
      style={{ backgroundImage: "url('/ctx_bg.png')" }}
      className="-mt-16 flex min-h-screen items-center pt-16 text-white"
    >
      <div className="mx-auto w-full max-w-7xl content-center justify-between p-4">
        <GetInviteType type={data.type} id={data.external_id} />
      </div>
    </div>
  );
};

export default Invite;
