import React from "react";

const LoadingLogo = () => {
  return (
    <div className="mx-auto my-6 flex justify-center rounded-lg">
      <img src="/context-logo-nodot.png" />
      <img className="animate-bounce" src="/context-dot.png" />
    </div>
  );
};

export default LoadingLogo;
