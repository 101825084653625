import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useGetOrderConfirmationQuery } from "api/graphql";

import {
  feeNameResolver,
  skuDisplayResolver,
} from "services/helpers/resolvers";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import LineItem from "components/LineItem/LineItem";
import ErrorComponent from "components/Error";

const OrderConfirmation = () => {
  const { id } = useParams();
  const location = useLocation();

  const [nextAction, setNextAction] = useState<any>({
    href: "",
    text: "Confirm",
  });
  const [orderError, setOrderError] = useState(null);

  const { data, isLoading, error } = useGetOrderConfirmationQuery({ id: id });

  useEffect(() => {
    if (!isLoading && data) {
      setNextAction(data.order.action);
    }
  }, [data, isLoading]);

  const redirectToAction = () => {
    if (nextAction.href) {
      window.location.href = nextAction.href;
    } else {
      setOrderError({
        message:
          "Error completing order, please try again: Missing action href.",
      });
    }
  };

  return (
    <div>
      <div className="parallax" />
      <div className="order-confirmation">
        {error ? (
          <div className="order-confirmation__error">
            <ErrorComponent
              id={id}
              type="OrderFetch"
              visible
              closeable={false}
              message={error.message}
              path={location.pathname}
            />
          </div>
        ) : orderError ? (
          <ErrorComponent
            id={id}
            type="OrderCompletion"
            visible
            closeable={false}
            message={orderError.message}
            path={location.pathname}
          />
        ) : null}
        {isLoading && (
          <div className="order-confirmation__loading">
            <CircularProgress
              lg
              inverse
              label="Loading up your order, one sec.."
            />
          </div>
        )}
        {!isLoading && data && (
          <div className="order-confirmation__container">
            <div className="order-confirmation__header">
              <h1>You're almost there!</h1>
              <h3>
                Double check the summary below and make sure it all checks out!
              </h3>
            </div>
            <div className="order-confirmation__divider " />
            <div className="order-confirmation__summary">
              <h1>Order Summary</h1>
              <table className="order-confirmation__summary__table">
                <thead className="order-confirmation__summary__table__head">
                  <tr>
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody className="order-confirmation__summary__table__body">
                  {data.order.lineItems.length > 0 &&
                    data.order.lineItems.map((li, k) => (
                      <LineItem
                        key={k}
                        quantity={li.quantity}
                        description={`${
                          data.order.vendorName
                        } ${skuDisplayResolver(li.sku)} - ${li.description}`}
                        amount={li.amount.display}
                      />
                    ))}
                  {data.order.fees.length > 0 && (
                    <tr>
                      <td className="order-confirmation__summary__table__body__header">
                        Fee Breakdown
                      </td>
                    </tr>
                  )}
                  {data.order.fees.length > 0 &&
                    data.order.fees.map((feeItem, k) => (
                      <LineItem
                        key={k}
                        quantity={feeItem.amount.amount}
                        description={feeNameResolver(feeItem.type)}
                        amount={feeItem.amount.display}
                      />
                    ))}
                  <LineItem
                    description="Total"
                    amount={data.order.amount.display}
                    isTotal
                  />
                </tbody>
              </table>
            </div>
            <div className="order-confirmation__divider " />
            <div className="order-confirmation__footer">
              <Button
                label={nextAction.text}
                onClick={() => redirectToAction()}
                small
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default OrderConfirmation;
