import React from 'react';
import { amountResolver, orderItemArrayResolver } from 'services/helpers/resolvers';
import moment from 'moment';

import Modal from 'components/Modal';

const OrderItemModal = (props) => {

  if (!props.order) return <></>;

  return (
    <Modal visible={props.visible} toggleModal={props.toggleModal}>
      <div className="order-item-modal">
        <div className="order-item-modal__header">
          <h1>Order Item for {props.order.vendorName}</h1>
        </div>
        <table className="order-item-modal__table">
          <thead className="order-item-modal__table__head">
            <tr>
              <th align="left">Property</th>
              <th align="left">Value</th>
            </tr>
          </thead>
          <tbody className="order-item-modal__table__body">
            {Object.keys(props.order).map((prop, key) => {
              const ignoredProps = ['action', '__typename'];
              const dateProps = ['createdAt', 'updatedAt'];
              if (ignoredProps.some(x => x === prop)) return;
              if (dateProps.some(x => x === prop)) {

                return <tr key={key}>
                  <td>{prop}</td>
                  <td>{moment(props.order[prop]).format("Do MMM YYYY HH:MM:SS")}</td>
                </tr>
              };
              if (prop === 'amount') {
                return amountResolver(props.order[prop], key);
              }
              if (Array.isArray(props.order[prop])) {
                return orderItemArrayResolver(prop, props.order[prop], key).map(x => x);
              }
              if (typeof props.order[prop] === 'boolean') {
                return <tr key={key}>
                  <td>{prop}</td>
                  <td>{String(props.order[prop])}</td>
                </tr>
              }
              return <tr key={key}>
                <td>{prop}</td>
                <td>{props.order[prop]}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default OrderItemModal;