import React from 'react';
import { InlineIcon } from '@iconify/react';
import { ToastProps } from 'types/Components';

import ErrorIcon from '@iconify/icons-uil/times-circle';
import InfoIcon from '@iconify/icons-uil/info-circle';
import SuccessIcon from '@iconify/icons-uil/check-circle';

const Toast = (props: ToastProps) => {
  return (
    <div className={`toast ${props.type}`}>
      <div className="toast__icon">
        <InlineIcon
          icon={iconResolver(props.type)}
        />
      </div>
      <div className="toast__message">
        {props.message}
      </div>
    </div>
  );
};
export default Toast;

const iconResolver = (type: string) => {
  switch (type) {
    case 'error':
      return ErrorIcon;
    case 'success':
      return SuccessIcon;
    case 'info':
      return InfoIcon;
  }
};