import React from "react";
import { ContextProfile } from "types/Profile";

import Button from "components/Button";
import { useLocation } from "react-router-dom";

type ExternalProfilesListProps = {
  type: string;
  profile: ContextProfile;
};

const ExternalProfilesList = (props: ExternalProfilesListProps) => {
  const location = useLocation();

  const handleConnectClick = (type: string) => {
    window.location.href = `/connect/${type}?&id=${props.profile.user.id}&from=${location.pathname}`;
  };

  return (
    <div>
      <div className="profile-v2__profiles__list__item__values">
        {props.profile.connectedProfiles
          .filter((x) => x.provider === props.type)
          .map((p, k) => (
            <span
              key={k}
              className="profile-v2__profiles__list__item__values__display-name"
            >
              {p.displayName}
            </span>
          ))}
      </div>
      <Button
        label="+ Add"
        glow
        small
        onClick={() => handleConnectClick(props.type)}
      />
    </div>
  );
};
export default ExternalProfilesList;
