import { useState } from "react";

import CustomSelect from "components/Select";
import SettingsFormFooter from "./SettingsFormFooter";
import Switch from "components/Switch";

import SelectErrorStyle from "styles/Select/SelectErrorStyle";
import SelectNewStyle from "styles/Select/SelectNewStyle";
import { useUpdateAdvanceSettingsMutation } from "api/graphql";

const prizeExpiryOpts = [
  { value: 0, label: "Never" },
  { value: 1, label: "1 day" },
  { value: 30, label: "30 days" },
  { value: 60, label: "60 days" },
  { value: 90, label: "90 days" },
  { value: 120, label: "120 days" },
  { value: 180, label: "180 days" },
];

const claimFormOpts = [{ value: "general", label: "General" }];

const CommunityAdvancedSettings = ({ communityAdmin }) => {
  const advancedSettings = (communityAdmin &&
    communityAdmin.advancedSettings) || {
    prizeExpiry: 0,
    forms: [],
    irsTaxForms: false,
  };

  const [isUnsaved, setIsUnsaved] = useState(false);
  const [values, setValues] = useState({
    prizeExpiry: prizeExpiryOpts.find(
      (x) => x.value === advancedSettings.prizeExpiry
    ),
    taxFormRequired: advancedSettings.irsTaxForms,
    forms: advancedSettings.forms.map((x) =>
      claimFormOpts.find((y) => x === y.value)
    ),
  });

  const [formError, setFormError] = useState({
    // id of the component to make things easier to identify
    where: "",
    message: "",
  });

  const {
    mutateAsync: updateAsync,
    data: updateData,
    isLoading: updateIsLoading,
    error: updateError,
  } = useUpdateAdvanceSettingsMutation();

  const handleSelectChange = (selected, info) => {
    setIsUnsaved(true);
    setFormError(null);
    return setValues({ ...values, [info.name]: selected });
  };

  const handleToggleChange = (e) => {
    setIsUnsaved(true);
    setValues({
      ...values,
      [e.target.id]: !values[e.target.id],
    });
  };

  const handleSave = async () => {
    const input = {
      id: communityAdmin.id,
      prizeExpiry: values.prizeExpiry.value,
      forms:
        values.forms && values.forms.length > 0
          ? values.forms.map((x) => x.value)
          : [],
      irsTaxForms: values.taxFormRequired,
    };

    await updateAsync({ input });
  };

  const resetState = () => {
    setValues({
      prizeExpiry: { value: 0, label: "Never" },
      taxFormRequired: false,
      forms: [],
    });
    setIsUnsaved(false);
    setFormError(null);
  };

  return (
    <div className="my-2 sm:col-span-2 sm:my-0">
      <div className="community-admin__settings__form--split">
        <CustomSelect
          id="prizeExpiry"
          name="prizeExpiry"
          label="How long before competition prizes expire?"
          className="community-admin__settings__form__input"
          styles={SelectNewStyle}
          value={values.prizeExpiry}
          onChange={handleSelectChange}
          options={prizeExpiryOpts}
          isSearchable={false}
          error={formError && formError.where === "prizeExpiry" && formError}
          errorStyles={SelectErrorStyle}
        />
      </div>
      <div className="community-admin__settings__form__toggles community-admin__settings__form__toggles--advanced">
        <Switch
          before="Tax forms required (i.e IRS' W-9, W-8BEN etc.)"
          className="community-admin__settings__form__toggle"
          onChange={handleToggleChange}
          id="taxFormRequired"
          checked={values.taxFormRequired}
        />
      </div>
      <div className="community-admin__settings__form--split">
        <CustomSelect
          id="forms"
          name="forms"
          label="Required forms"
          className="community-admin__settings__form__input"
          styles={SelectNewStyle}
          value={values.forms}
          isMultiple
          onChange={handleSelectChange}
          options={claimFormOpts}
          isSearchable={false}
          error={formError && formError.where === "forms" && formError}
          errorStyles={SelectErrorStyle}
        />
      </div>
      <SettingsFormFooter
        id={communityAdmin.id}
        isUnsaved={isUnsaved}
        saveLoading={updateIsLoading}
        saveError={updateError}
        saveSuccess={updateData != null}
        handleSave={handleSave}
        resetState={resetState}
      />
    </div>
  );
};

export default CommunityAdvancedSettings;
