import React from 'react';
import moment from 'moment';
import { Money } from 'types/Shared';
import AmountView from 'components/AmountView';

type WithdrawalBreakdownProps = {
  title: string
  items: WithdrawalBreakdownItem[]
};

export type WithdrawalBreakdownItem = {
  date: Date
  label: string
  amount: Money
}

const WithdrawBreakdown = (props: WithdrawalBreakdownProps) => {
  return (
    <div className="withdraw__content__breakdown__section">
      <div className="withdraw__content__breakdown__section__header">
        {props.title}
      </div>
      <ul className="withdraw__content__breakdown__section__list">
        {props.items.map((item, k) => <li key={k} className="withdraw__content__breakdown__section__list__item">
          <span className="withdraw__content__breakdown__section__list__item__date">{moment(item.date).fromNow()}</span>
          <span className="withdraw__content__breakdown__section__list__item__name">{item.label}</span>
          <span className="withdraw__content__breakdown__section__list__item__amount">
            <AmountView
              amount={item.amount}
            />
          </span>
        </li>)}
      </ul>
    </div>
  );
};
export default WithdrawBreakdown;