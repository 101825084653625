import { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCommunityQuery } from "api/graphql";
import { useMyProfile } from "lib/profile";

import Layout from "domains/community/components/Layout";

const ViewCommunityDebug = () => {
  const { id } = useParams();
  const [cursor, setCursor] = useState(null);

  const user = useMyProfile();

  const { data, isLoading, isError, refetch } = useGetCommunityQuery({
    id: id,
  });

  if (isLoading) {
    return <></>;
  }
  if (isError) {
    return (
      <div className="mt-6 text-center text-2xl text-white">
        There was an error loading community with ID: {id}
      </div>
    );
  }

  const entries = Object.entries(data);

  console.log(data);

  return (
    <>
      <Layout
        community={data.community}
        communityAdmin={data.communityAdmin}
        user={user}
      >
        <span className="white">
          <pre
            style={{
              backgroundColor: "#101214",
              padding: "1rem",
              borderRadius: "0.5rem",
              marginBottom: "1rem",
              overflowWrap: "break-word",
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
            }}
          >
            {JSON.stringify(data.community, null, 2)}
          </pre>
        </span>
      </Layout>
    </>
  );
};

export default ViewCommunityDebug;
