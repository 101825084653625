import React from 'react';
import { Profile } from 'types/Profile';
import Radio from 'components/Radio';

type PaypalAccountsItemProps = {
  profile: Profile
  selected: boolean
  onChange: Function
};

const PaypalAccountsItem = (props: PaypalAccountsItemProps) => {
  return (
    <div className="paypal-accounts-item">
      <Radio
        id={`paypal-accounts-item--${props.profile.id}`}
        checked={props.selected}
        name={`paypal-accounts-item--${props.profile.id}`}
        label={<span className="flex flex-align-center"><img width="25px" height="25px" className="mr-1" src="/paypal-logo.png" /> {props.profile.email}</span>}
        value={props.profile.id}
        onChange={props.onChange}
      />
    </div>
  );
};
export default PaypalAccountsItem;