import React, { useState, useEffect } from "react";

import { Icon } from "@iconify/react";
import Input from "components/Input";
import Radio from "components/Radio";
import RadioGroup from "components/RadioGroup";

// icons
import SearchIcon from "@iconify/icons-ion/search";
import { useGetStartggTournamentQuery } from "api/graphql";

const SmashggForm = (props) => {
  const [smashggEvents, setSmashggEvents] = useState([]);
  const [debugClick, setDebugClick] = useState(0);
  const [searchText, setSearchText] = useState("");

  const { data, isLoading, error } = useGetStartggTournamentQuery(
    {
      text: searchText,
    },
    {
      enabled: searchText.length > 0,
    }
  );

  useEffect(() => {
    props.error(error);
  }, [error]);

  useEffect(() => {
    setSmashggEvents(data?.smashggTournament?.events || []);
    props.updateValues({
      ...props.values,
      tournamentId: data?.smashggTournament?.id,
    });
  }, [data]);

  const handleChange = (e) => {
    props.updateValues({
      ...props.values,
      url: e.target.value,
    });
  };
  const handleSearch = () => {
    if (!props.values.url) return;

    setSearchText(props.values.url);
  };

  const handleEventSelect = (e) => {
    props.updateValues({
      ...props.values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <div className="wizard__step-header">
        <h1>
          Configure your{" "}
          <span onClick={() => setDebugClick(debugClick + 1)}>competition</span>
        </h1>
        <h3>
          Drop us the URL to your start.gg tournament and the select the event
          you wish to use. Players must not be manually entered into the
          bracket.
        </h3>
      </div>
      <div className="configure-step__configure-form">
        {debugClick >= 3 && (
          <span className="text-ctx-orange">
            Debug URL: https://www.start.gg/tournament/ctx-internal-team-events
          </span>
        )}
        <Input
          label="start.gg Event URL"
          placeholder="https://start.gg/tournament/your-cool-tournament"
          button={<Icon icon={SearchIcon} />}
          value={props.values.url}
          onChange={handleChange}
          action={handleSearch}
          triggerOnEnter={true}
        />
        {isLoading && <>Loading...</>}
        {props.validator.message("url", props.values.url, "required|url")}
        {smashggEvents.length > 0 && (
          <RadioGroup label="Select an Event">
            {smashggEvents.map((event, i) => (
              <Radio
                key={i}
                name="eventId"
                checked={props.values.eventId == event.id}
                onChange={handleEventSelect}
                id={event.id}
                label={event.name}
                value={event.id}
              />
            ))}
          </RadioGroup>
        )}
        {props.validator.message("event", props.values.eventId, "required", {
          className: " red",
        })}
      </div>
    </React.Fragment>
  );
};
export default SmashggForm;
