import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

import { redirectToLogin } from "utils";

import Button from "components/Button";
import CommunityDonation from "components/Modals/CommunityDonation";

type CommunityViewProps = {
  community: any;
  communityAdmin: any;
  user: any;
  children: any;
};

const Layout = (props: CommunityViewProps) => {
  const location = useLocation();
  const nav = useNavigate();

  const [donateModalOpen, setDonateModalOpen] = useState(false);

  const toggleModal = (value, type) => {
    ReactGA.modalview(`community-${type}-modal`);
    switch (type) {
      case "donate":
        if (value === true && !props.user) {
          const redirectTo = `${location.pathname}`;
          redirectToLogin(nav, redirectTo);
          return;
        }
        setDonateModalOpen(value);
        return;
      default:
        break;
    }
  };

  if (!props.community) {
    return <></>;
  }

  const communityId = props.community.alias || props.community.id;

  return (
    <div>
      <div className="parallax"></div>
      <CommunityDonation
        visible={donateModalOpen}
        toggleModal={(val) => toggleModal(val, "donate")}
        communityData={props.community}
        profile={props.user}
      />
      <div className="community-view">
        <div className="community-view__header">
          <div className="community-view__header__display">
            <div className="community-view__header__display__image">
              <img
                src={
                  props.community.logo ? props.community.logo : "/no-image.jpg"
                }
                alt={`Logo for community ${props.community.name}`}
              />
            </div>
            <div className="community-view__header__display__title">
              <div>
                <h1>{props.community.name}</h1>
              </div>
            </div>
          </div>
          <div className="community-view__header__buttons space-x-6">
            {props.community.communityDonationsEnabled && (
              <button
                onClick={() => toggleModal(true, "donate")}
                className=" rounded bg-gradient-to-br from-ctx-dark-orange via-ctx-orange to-ctx-dark-orange bg-size-150 bg-pos-0 px-10 py-2 font-semibold text-white transition-all duration-500 hover:bg-pos-100"
              >
                Donate
              </button>
            )}
            {props.communityAdmin.enabled && (
              <Link
                to={`/${communityId}/admin`}
                className=" rounded bg-gradient-to-br from-ctx-dark-orange via-ctx-orange to-ctx-dark-orange bg-size-150 bg-pos-0 px-4 py-2 font-semibold text-white transition-all duration-500 hover:bg-pos-100"
              >
                <span className="btn__label">Admin</span>
              </Link>
            )}
          </div>
        </div>
        <div className="community-view__tabs">
          <div className="community-view__tabs__component">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
