import React, { useState, useEffect } from 'react';

import Button from 'components/Button';
import { InlineIcon } from '@iconify/react';
import CircularProgress from 'components/CircularProgress';

const Wizard = (props) => {

  const {
    finalClick,
    context,
    forceLoading,
    forceError,
  } = props;

  const [steps, _] = useState(props.steps);
  const [currentStepId, setCurrentStepId] = useState(1);
  const [states, setStates] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(forceLoading);
  }, [forceLoading]);

  useEffect(() => {
    setSuccess(props.success);
  }, [props.success]);

  useEffect(() => {
    setError(forceError);
  }, [forceError]);

  let currentStepFunc = null;

  const handleNextClick = async () => {
    setError(null);
    // check if its the final step
    if (currentStepId === steps.length) {
      // state doesn't update quick enough when setting
      // so we copy it here to pass to the final click
      if (currentStepFunc) {
        // if there's a next function inside of the step
        // execute it before we execute the final function
        const nextFunction = await currentStepFunc();
        if (!nextFunction) return setLoading(false);
        // update the states with the values from the step next func
        states[nextFunction.id] = nextFunction.values;
        setStates({ ...states });
      }
      currentStepFunc = null;
      return finalClick(states);
    } else {
      // early return if there's no step function
      if (!currentStepFunc) return;
      setLoading(true);
      const nextFunction = await currentStepFunc();
      // nextFunction should return true or false, check if false
      if (!nextFunction) return setLoading(false);
      // move on to next step
      setCurrentStepId(currentStepId + 1);
      // reset step function
      currentStepFunc = null;
      setLoading(false);
    };
  };

  const handleNavClick = async (id) => {
    setError(null);
    // check if user is clicking on the next step
    if (id === currentStepId + 1) {
      // check if current function is loaded
      if (!currentStepFunc) return;
      // attempt to run the current step function
      setLoading(true);
      const nextFunction = await currentStepFunc();
      // check if next func passes
      if (!nextFunction) return;
    };
    // return if clicking on the current step
    if (id === currentStepFunc) return;
    // don't allow to skip steps
    if (id - currentStepId > 1) return;
    //
    setCurrentStepId(id);
    currentStepFunc = null;
    setLoading(false);
  };

  return (
    <div>
      <div className={`wizard ${props.noNegativeMargin ? 'no-margin' : ''}`}>
        {!props.noHeaders ? <div className="wizard__header">
          <h3>{steps[currentStepId - 1].stepName}</h3>
          <h1>{steps[currentStepId - 1].stepDescription}</h1>
        </div> : null}
        <div className="wizard__content">
          <div className="wizard__content__menu">
            {steps.map(s => (
              <div
                key={s.stepId}
                className={`wizard__content__menu__item ${s.stepId === currentStepId && 'active'}`}
                onClick={() => handleNavClick(s.stepId)}
              >
                <span><InlineIcon id="icon" icon={s.stepIcon} />{s.stepName}</span>
              </div>
            ))}
          </div>
          <div className={`wizard__content__step ${props.noStepShadow ? "wizard__content__step--no-shadow" : ""}`}>
            <div className="wizard__content__step__content">
              {steps.map(s => {
                let Component;
                if (s.stepId === currentStepId) {
                  Component = s.stepComponent;
                  return <Component
                    context={context}
                    key={s.stepId}
                    wizardStates={states}
                    error={error}
                    next={async nextFunction => {
                      currentStepFunc = nextFunction;
                    }}
                    sendState={(id, state) => {
                      // get current step name
                      setStates({ ...states, [id]: state })
                    }}
                    loading={state => {
                      setLoading(state)
                    }}
                    wizardError={error => {
                      setError(error)
                    }}
                  />
                } else {
                  return null;
                };
              })}
            </div>
            <span className="wizard__content__error">{error && error.message}</span>
            {/* FIXME remove this make it scalable */}
            <span className="wizard__content__error green">{success && "Competiton successfully finalized, this page will refresh automatically."}</span>
            <div className="wizard__content__footer">
              {loading &&
                <CircularProgress sm inverse />
              }
              <Button
                label={currentStepId === steps.length ? 'Finish' : 'Next'}
                onClick={() => handleNextClick()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Wizard;