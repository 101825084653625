import {
  CheckIcon,
  ClockIcon,
  ExclamationIcon,
  SearchCircleIcon,
  SelectorIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import { Combobox } from "@headlessui/react";

const SearchGame = ({
  field: { name, value }, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { label, options, searchChanged, isFetching } = props;

  const handleChange = (newValue) => {
    setFieldValue(name, newValue);
  };

  return (
    <Combobox as="div" value={value} onChange={handleChange}>
      <Combobox.Label className="block text-sm font-medium text-neutral-300">
        {label}
      </Combobox.Label>
      <div className="relative mt-1">
        <div className="pointer-events-none absolute inset-y-0  left-0 z-20 flex items-center pl-1">
          <SearchCircleIcon className=" h-7 w-7 text-neutral-500" />
        </div>
        <Combobox.Input
          className="relative block w-full rounded-md border border-context-black border-opacity-50 bg-context-gray bg-opacity-60 px-3 py-2 pl-10 text-neutral-400 placeholder-neutral-500 caret-ctx-orange focus:z-10 focus:border-ctx-orange focus:border-opacity-80 focus:bg-context-dark focus:outline-none focus:ring-0"
          onChange={(event) => searchChanged(event.target.value)}
          placeholder="Rocket League"
          displayValue={(game: any) => game?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon
            className="z-20 h-5 w-5 text-neutral-400"
            aria-hidden="true"
          />
        </Combobox.Button>
        <Combobox.Options className="absolute z-50 mt-1 max-h-56 w-full overflow-auto rounded-md border border-context-black border-opacity-50 bg-context-gray py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <>
            {isFetching && (
              <Combobox.Option
                key="searching_for_game"
                value="searching"
                disabled={true}
              >
                <div className="relative cursor-not-allowed select-none rounded text-center text-neutral-400 transition">
                  <ClockIcon className="inline-block h-7 w-7 text-neutral-500" />{" "}
                  Searching...
                </div>
              </Combobox.Option>
            )}
            {options?.length === 0 && (
              <Combobox.Option
                key="no_results_found"
                value="no_results"
                disabled={true}
              >
                <div className="relative cursor-not-allowed select-none rounded text-center text-neutral-400 transition">
                  <ExclamationIcon className="inline-block h-7 w-7 text-neutral-500" />{" "}
                  No Results Found...
                </div>
              </Combobox.Option>
            )}
            {options?.length > 0 && (
              <>
                {options.map((game) => (
                  <Combobox.Option
                    key={game.id}
                    value={game}
                    className={({ active }) =>
                      classNames(
                        "relative cursor-pointer select-none rounded transition",
                        active
                          ? "bg-gradient-to-r from-ctx-orange to-orange-800 text-white"
                          : "text-neutral-300"
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span className="my-px flex items-center">
                          <img
                            src={game.logo}
                            alt={`${game.name} Logo}`}
                            className="h-24 flex-initial rounded-l"
                          />
                          <span className="truncate- ml-2 flex flex-1 flex-col overflow-clip">
                            <h1
                              className={classNames(
                                "text-lg",
                                value?.id == game.id && "font-semibold"
                              )}
                            >
                              {game.name}
                            </h1>
                            <p className="max-h-[60px] whitespace-pre-wrap">
                              {game.description}
                            </p>
                          </span>
                          {value?.id == game.id && (
                            <span
                              className={classNames(
                                "inset-y-0 right-0 flex flex-initial items-center pr-4 transition",
                                active ? "text-white" : "text-orange-600"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </>
            )}
          </>
        </Combobox.Options>
      </div>
    </Combobox>
  );
};

export default SearchGame;
