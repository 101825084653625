import React, { useState, useRef } from 'react';

const SSNInput = (props) => {

  const inputRef = useRef();

  const [values, setValues] = useState({
    ssn: "",
    maskedSSN: "",
    locked: false
  });

  const handleChange = (e) => {
    const prevValue = values.maskedSSN;
    const newValue = e.target.value;

    let actual = values.ssn;

    if (newValue.length > 11) {
      return;
    }

    // typing forward
    if (newValue.length > prevValue.length) {
      let newChar = newValue.split("").pop();

      actual = `${actual}${newChar}`;
    }
    // backspacing / deleting
    else {
      const charsRemovedCount = prevValue.length - newValue.length;

      actual = actual.substr(0, actual.length - charsRemovedCount);
    }

    setValues({
      ...values,
      ssn: actual,
      maskedSSN: starredMask(actual)
    });
  }

  const starredMask = (ssn, showLast4 = true) => {
    // replace numeric digits with * asterisk
    let valueHidden = ssn.replace(/[\d]/g, "*");

    if (valueHidden.length <= 3) {
      return valueHidden;
    }

    if (valueHidden.length <= 5) {
      return valueHidden.slice(0, 3) + "-" + valueHidden.slice(3, 5);
    }

    if (showLast4) {
      return (
        valueHidden.slice(0, 3) +
        "-" +
        valueHidden.slice(3, 5) +
        "-" +
        ssn.substr(5)
      );
    } else {
      return (
        valueHidden.slice(0, 3) +
        "-" +
        valueHidden.slice(3, 5) +
        "-" +
        valueHidden.slice(5, 9)
      );
    }
  }

  return (
    <div className="input__wrapper input__ssn">
      <div className="input__container">
        <input
          className={`
          input__field
          ${props.error ?
              'error' :
              ''
            }
          `}
          type="tel"
          name="social_security_number"
          id="social_security_number"
          ref={inputRef}
          onChange={handleChange}
          onBlur={(e) => props.handleBlur(e, values.ssn)}
          placeholder="123456789"
          value={values.maskedSSN}
        />
        <label
          className="input__label"
          htmlFor="social_security_number"
        >
          Social security number
        </label>
      </div>
      {
        props.error &&
        <div className="input__label__below">
          {props.error.message}
        </div>
      }
    </div>
  );
};
export default SSNInput;