import React, { useEffect, useState } from "react";

import { paymentTypeLabelResolver } from "services/helpers/resolvers";

import Checkbox from "../Checkbox";
import { InlineIcon } from "@iconify/react";

// icons
import CheckIcon from "@iconify/icons-uil/check-circle";
import CrossIcon from "@iconify/icons-uil/times-circle";
import { usePaymentOptionsMutation } from "api/graphql";
import { useMyProfile } from "lib/profile";

type PaymentOptionsProps = {
  communityId: string;
  userId: string;
  amount: number;
  currency: string;
  for: any;
  onChange: Function;
};

const PaymentOptions = (props: PaymentOptionsProps) => {
  const user = useMyProfile();

  const [paymentOptions, setPaymentOptions] = useState({
    user: null,
    community: null,
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionIds, setSelectedOptionsIds] = useState([]);
  const [error, setError] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const { mutateAsync: paymentOptsQuery, isLoading } =
    usePaymentOptionsMutation();

  const initialInputVars: any = {
    id: props.communityId ? props.communityId : props.userId,
    for: props.for,
    existing: selectedOptions,
    amount: props.amount,
    currency: props.currency,
  };

  useEffect(() => {
    paymentOptsQuery(
      { input: initialInputVars },
      {
        onSettled: (data, error) => {
          if (data.paymentOptions) {
            setPaymentOptions({
              user: data.paymentOptions.user,
              community: data.paymentOptions.community,
            });
          }
        },
      }
    );
  }, []);

  const onChange = (e) => {
    const newSelectedOptions = Array.from(selectedOptions);
    const newIds = Array.from(selectedOptionIds);

    // check if the option clicked is a community one
    const type = String(e.target.id).includes("community")
      ? "community"
      : "user";

    // id of community_deposit needs to be reverted
    // back to just deposit
    let checkboxId: string = e.target.id;
    if (e.target.id === "community_deposit") checkboxId = "context_deposit";
    // find the option matching the one clicked
    const option = findOption(type, checkboxId);
    const isSelected = selectedOptionIds.some((x) => x === e.target.id);
    if (!isSelected) {
      if (option) {
        // add it to the currently selected options
        const newOption = {
          accountId: type === "community" ? props.communityId : props.userId,
          type: checkboxId,
          available: option.available,
          amount: option.amount,
          amountEx: option.amountEx,
          currency: option.amount.currency,
        };
        newSelectedOptions.push(newOption);
        newIds.push(e.target.id);
      } else {
        console.log(`mayday, option ${checkboxId} not found`);
      }
    } else {
      // payment option clicked exists, remove it
      const index = newSelectedOptions.findIndex((x) => x.type === checkboxId);
      const idIndex = newIds.findIndex((x) => x === e.target.id);
      if (index < 0 || idIndex < 0) return;
      newSelectedOptions.splice(index, 1);
      newIds.splice(idIndex, 1);
    }

    // update the selected options state
    setSelectedOptionsIds([...newIds]);
    setSelectedOptions([...newSelectedOptions]);

    const existingOptionsInput = newSelectedOptions.map((opt) => {
      let amount;

      console.log(opt);

      if (opt.available && opt.available.amount > opt.amountEx.amount)
        amount = opt.amountEx.amount;
      else if (opt.available) amount = opt.available.amount;
      else amount = opt.amountEx.amount;

      return {
        accountId: opt.accountId,
        type: opt.type,
        amount: amount,
        currency: opt.currency,
      };
    });

    // refetch updated payment options with new existing elements
    const updatedInput = {
      id: props.communityId ? props.communityId : props.userId,
      for: props.for,
      existing: existingOptionsInput,
      amount: props.amount,
      currency: props.currency,
    };

    const valid = validateOptions(existingOptionsInput, props.amount);

    setIsValid(valid);

    // output a list of payment configs which can be passed
    // straight to graphql
    const optionsOut: any[] = newSelectedOptions.map((opt: any) => ({
      accountId: opt.accountId,
      paymentType: opt.type,
      amount:
        opt.available && props.amount > opt.available.amount
          ? opt.available.amount
          : opt.amount.amount,
      currency: opt.currency,
    }));

    // pass our options back up to the parent
    props.onChange(optionsOut, valid);

    fetchPaymentOptions(updatedInput);
  };

  const validateOptions = (options, prizePool: number): boolean => {
    let total = 0;

    options.forEach((opt) => {
      total += opt.amount;
    });

    if (total != prizePool) return false;
    else return true;
  };

  const findOption = (type: string, id: string) => {
    switch (type) {
      case "community":
        return paymentOptions.community.find((x) => x.type === id);
      case "user":
        return paymentOptions.user.find((x) => x.type === id);
      default:
        return null;
    }
  };

  const fetchPaymentOptions = (input: any) => {
    // setRefetching(true);
    paymentOptsQuery(
      { input },
      {
        onSettled: (data, error) => {
          if (data) {
            setPaymentOptions(paymentOptions);
          }
        },
      }
    );
  };

  return (
    <div className="payment-options__wrapper">
      {error && error.message}
      <React.Fragment>
        <div className="payment-options__header flex items-center">
          <h1 className="flex items-center gap-x-2">
            Payment Options{" "}
            <InlineIcon
              className={isValid ? "green" : "red"}
              icon={isValid ? CheckIcon : CrossIcon}
            />
          </h1>{" "}
          {isLoading && <span>Updating..</span>}
        </div>
        {paymentOptions.user && (
          <div className="payment-options__option">
            <h1 className="payment-options__option__header">User</h1>
            {paymentOptions.user.map((opt) => (
              <Checkbox
                key={"user_" + opt.type}
                id={opt.type}
                name={opt.type}
                label={paymentTypeLabelResolver(
                  opt.type,
                  opt.amount,
                  opt.available
                )}
                checked={selectedOptionIds.some((x) => x === opt.type)}
                disabled={
                  isLoading
                    ? isLoading
                    : paymentOptions.user.find((x) => x.type === opt.type) &&
                      paymentOptions.user.find((x) => x.type === opt.type)
                        .amountEx.amount === 0
                    ? true
                    : false
                }
                onChange={(e) => onChange(e)}
              />
            ))}
          </div>
        )}
        {paymentOptions.community && paymentOptions.community.length > 0 && (
          <div className="payment-options__option">
            <h1 className="payment-options__option__header">Community</h1>
            {paymentOptions.community.map((opt) => (
              <Checkbox
                key={"community_" + opt.type}
                id={prependCommunity(opt.type)}
                name={prependCommunity(opt.type)}
                label={paymentTypeLabelResolver(
                  prependCommunity(opt.type),
                  opt.amount,
                  opt.available
                )}
                checked={selectedOptionIds.some(
                  (x) => x === prependCommunity(opt.type)
                )}
                disabled={
                  isLoading
                    ? isLoading
                    : paymentOptions.community.find(
                        (x) => x.type === opt.type
                      ) &&
                      paymentOptions.community.find((x) => x.type === opt.type)
                        .amountEx.amount === 0
                    ? true
                    : false
                }
                onChange={(e) => onChange(e)}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    </div>
  );
};

export default PaymentOptions;

const prependCommunity = (type: string): string =>
  type === "context_deposit" ? "community_deposit" : type;
