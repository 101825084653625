import React from 'react';
import TeamMember from './TeamMember';

const Team = () => {
  return (
    <div className="team__wrapper">
      <TeamMember
        name="Chris Kolenko"
        position="Founder / CEO"
        avatarURL="/images/team/chris-image.jpg"
        links={[{ type: 'twitter', url: 'https://twitter.com/doofusV' }]}
        email="chris@context.gg"
      />
      <TeamMember
        name="Craig McArthur"
        position="Founder / Community Manager"
        avatarURL="/images/team/craig-image.jpg"
        links={[{ type: 'twitter', url: 'https://twitter.com/changeRL_' }]}
      />
      <TeamMember
        name="Calvin Moylan"
        position="React Developer / Designer"
        avatarURL="/images/team/calvin-image.jpg"
        links={[{ type: 'twitter', url: 'https://twitter.com/calstar__' }]}
        email="calvin@context.gg"
      />
    </div>
  );
};
export default Team;