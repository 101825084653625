import React, { useState } from "react";
import { InlineIcon } from "@iconify/react";

import QuestionIcon from "@iconify/icons-uil/question-circle";

type TooltipProps = {
  label: string;
  message: string;
};

const Tooltip = (props: TooltipProps) => {
  const [msgIsVisible, setMsgIsVisible] = useState(false);

  return (
    <React.Fragment>
      <div className="tooltip__message__wrapper">
        <span className="tooltip__message">{props.message}</span>
      </div>
      <h3
        onMouseEnter={() => setMsgIsVisible(true)}
        onMouseLeave={() => setMsgIsVisible(false)}
        className="tooltip"
      >
        {props.label}{" "}
        <InlineIcon
          className="tooltip__icon inline-block"
          icon={QuestionIcon}
        />
      </h3>
    </React.Fragment>
  );
};

export default Tooltip;

// this is the distance of the text box floating above the parent element
const resolveTop = (messageLength: Number): string => {
  if (messageLength <= 25) return "-32px";
  if (messageLength <= 50) return "-47px";
  if (messageLength <= 75) return "-65px";
  if (messageLength <= 100) return "-65px";
  if (messageLength <= 125) return "-70px";
  if (messageLength <= 150) return "-75px";
  else return "-80px";
};
