import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";

// icons
import CloudIcon from "@iconify/icons-uil/cloud-upload";
import CheckIcon from "@iconify/icons-uil/check-circle";
import CircularProgress from "components/CircularProgress";
import classNames from "classnames";

type FileInputProps = {
  id?: string;
  label?: any;
  onChange?: any;
  reset?: any;
  loading?: boolean;
  error?: any;
  success?: any;
};

const FileUpload = (props: FileInputProps) => {
  const { id, label, onChange } = props;

  const [fileName, setFileName] = useState("");

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFileName(file.name);
    };
    onChange(e);
  };

  return (
    <div className="file-upload__container">
      <span
        className={`
      file-upload__container__label
      ${props.success ? "success" : ""}
      ${props.error ? "error" : ""}
      `}
      >
        {label}
      </span>
      <div
        className={classNames(
          "file-upload__control border border-neutral-600 bg-context-dark",
          props.success && "success",
          props.error && "error"
        )}
      >
        <input
          className="file-upload__field"
          type="file"
          id={id}
          accept="image/png, image/jpeg"
          onChange={(e) => handleChange(e)}
        />
        <label htmlFor={id} className="file-upload__label">
          {props.success ? (
            <div className="file-upload__label__wrapper">
              <Icon className="file-upload__label__icon" icon={CheckIcon} />
              <span className="file-upload__label__text">{fileName}</span>
            </div>
          ) : (
            <div className="file-upload__label__wrapper">
              {props.loading ? (
                <CircularProgress
                  className="file-upload__label__loading"
                  sm
                  inverse
                />
              ) : (
                <Icon className="file-upload__label__icon" icon={CloudIcon} />
              )}
              <span className="file-upload__label__text">Upload file</span>
            </div>
          )}
        </label>
      </div>
      {props.error && (
        <div className="file-upload__container__error">
          <h3>{props.error.message}</h3>
        </div>
      )}
    </div>
  );
};
export default FileUpload;
