import React from "react";
import { currencyIconResolver } from "services/helpers/resolvers";

import { InlineIcon } from "@iconify/react";
import PaymentOptions from "components/PaymentOptions";

const Checkout = (props) => {
  const {
    classes,
    context,
    next,
    wizardStates,
    loading,
    sendState,
    wizardError,
  } = props;

  const handleChange = (selectedOptions, isValid: boolean) => {
    wizardError(null);
    const state = { options: selectedOptions, isValid };
    sendState("checkout", state);
  };

  return (
    <div className="checkout-step">
      <React.Fragment>
        <div className="wizard__step-header">
          <h1>Checkout</h1>
          <h3>
            Select your preferred payment methods in order to complete this
            transaction.
          </h3>
        </div>
        <div className="checkout-step__total">
          <h1>Contribution Amount</h1>
          <span>
            <InlineIcon
              icon={currencyIconResolver(wizardStates.contribution.currency)}
            />
            {wizardStates.contribution.amount}{" "}
            <span className="text-small">
              {wizardStates.contribution.currency}
            </span>
          </span>
        </div>
        <PaymentOptions
          userId={context.user.id}
          communityId={context.data && context.data.id}
          amount={wizardStates.contribution.amount}
          currency={wizardStates.contribution.currency}
          for={"DONATION"}
          onChange={handleChange}
        />
      </React.Fragment>
    </div>
  );
};
export default Checkout;
