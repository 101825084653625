import React, { useEffect, useState } from 'react';
import Input from 'components/Input';

type DateOfBirthInputProps = {
  values: any
  handleChange: Function
  errors: any[]
  locale: string
};

const DateOfBirthInput = (props: DateOfBirthInputProps) => {

  const [locale, setLocale] = useState("en-US");

  useEffect(() => {
    if (props.locale) {
      const locales = props.locale.split(";");
      setLocale(locales[0].split(",")[0]);
    }
  }, [props.locale])

  return (
    <div className="birthdate">
      <div className="birthdate__header">
        <h1>Date of birth</h1>
      </div>
      <div className="birthdate__wrapper">
        <Input
          id="month"
          name="month"
          type="number"
          className="birthdate__input birthdate__input--month"
          label="Month"
          value={props.values.month}
          onChange={(e) => e.target.value.length > 2 ? null : props.handleChange(e)}
          placeholder="12"
          error={props.errors && props.errors.find(x => x.where === "month")}
          styles={`
            .birthdate__input--month {
              order: ${locale == "en-US" ? 1 : 2}
            }
          `}
        />
        <Input
          id="day"
          name="day"
          type="number"
          className="birthdate__input birthdate__input--day"
          label="Day"
          value={props.values.day}
          onChange={(e) => e.target.value.length > 2 ? null : props.handleChange(e)}
          placeholder="31"
          error={props.errors && props.errors.find(x => x.where === "day")}
          styles={`
            .birthdate__input--day {
              order: ${locale == "en-US" ? 2 : 1}
            }
          `}
        />
        <Input
          id="year"
          name="year"
          type="number"
          className="birthdate__input birthdate__input--year"
          label="Year"
          value={props.values.year}
          onChange={(e) => e.target.value.length > 4 ? null : props.handleChange(e)}
          placeholder="2020"
          error={props.errors && props.errors.find(x => x.where === "year")}
        />
      </div>
    </div>
  );
};
export default DateOfBirthInput;