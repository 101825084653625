import { useGetTeam, usePostAcceptInvite } from "api/api";
import React from "react";
import { useNavigate } from "react-router-dom";

const TeamInvite = ({ id }) => {
  const nav = useNavigate();
  const { data, isLoading } = useGetTeam(id);
  const { mutateAsync } = usePostAcceptInvite(id);

  const onAcceptInvite = async () => {
    const data = {
      aggregate_id: id,
    };

    await mutateAsync({ data: data });
  };

  if (isLoading) {
    return <>Loading Team</>;
  }

  return (
    <div className="mx-auto flex max-w-lg flex-col items-center rounded-lg border border-orange-500/50 bg-gradient-to-r from-ctx-orange/5 to-ctx-dark-orange/5 text-white">
      <h1 className="py-4 text-2xl font-medium text-gray-200">
        Team Invitation
      </h1>
      <div className="py-6 text-gray-300">
        Are you sure you want to join team: {data?.name}
        <div className="my-2">
          <h1 className="text-center">Players:</h1>
          {data.players.map((player) => (
            <li key={player.id} className="list-none">
              {player.id} {player.role_id}
            </li>
          ))}
        </div>
        <div className="flex items-center justify-between gap-x-8 pt-4">
          <button
            className="rounded-md border border-context-black border-opacity-50 bg-context-gray bg-opacity-60 px-6 py-1 text-neutral-400 transition hover:bg-context-dark hover:text-neutral-500 hover:outline-none"
            onClick={() => nav("/")}
          >
            Decline
          </button>
          <button
            className="ctx-gradient-button px-8 py-1"
            onClick={() => onAcceptInvite()}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamInvite;
