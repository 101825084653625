import { useNavigate } from "react-router-dom";

import React from "react";
import ReactGA from "react-ga";

import Button from "components/Button";
import Footer from "components/Footer";
import Testimonials from "components/Testimonials";

const Home = () => {
  const navigate = useNavigate();

  const handleCtaClick = (e, type: string) => {
    ReactGA.event({
      action: `Clicked ${type} on landing`,
      category: "Call-to-action",
      label: "Landing Page CTA",
    });
    switch (type) {
      case "create competition":
        navigate("/create/competition");
        break;
      case "get started":
        navigate("/get-started");
        break;
      case "view competitions":
        navigate("/competitions");
        break;
      case "learn more":
        navigate("/about");
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <main className="landing">
        <section className="landing__hero">
          <div className="landing__hero__content">
            <div className="landing__hero__content__left">
              <div className="landing__hero__content__text">
                <h1>
                  Esports <span className="orange">crowdfunding</span> and{" "}
                  <span className="orange">payments</span> made simple.
                </h1>
                <h3>
                  Empowering tournament organisers and esport communities to
                  handle payments efficiently, create effective revenue streams,
                  and gamify their platforms
                </h3>
              </div>
              <div className="landing__hero__content__cta">
                <Button
                  label="Get Started"
                  onClick={(e) => handleCtaClick(e, "get started")}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="landing__section section-1">
          <div className="landing__section__content">
            <h1>Increase your prize pools</h1>
            <p>
              Context.gg gives your community a platform to easily crowdfund a
              greater prize pool for your gaming events, so you can focus on
              providing quality coverage and entertainment for your viewers.
              <br />
              <br />
              Once you have set up your competition with your preferred
              bracketing software, you will have your very own competition page.
              You can direct users to this page to contribute to your event.
            </p>
          </div>
          <div className="landing__section__image">
            <img src="/landing/section01.svg" alt="Landing competition view" />
          </div>
        </section>
        <section className="landing__section section-2">
          <div className="landing__section__content">
            <h1>Instantly pay out your players</h1>
            <p>
              After your event is over, all you need to do is select the players
              who placed in the money, then just a single click and you're done.
              <br />
              <br />
              Players will be able to withdraw their cash instantly to PayPal
              and are charged absolutely 0 fees, meaning they will always get
              100% of their earnings.
            </p>
          </div>
          <div className="landing__section__image">
            <img src="/landing/section02.svg" alt="Landing competition view" />
          </div>
        </section>
        <section className="landing__section section-3">
          <div className="landing__section__content">
            <div className="landing__section__content__header">
              <h1>Create new revenue streams with Context Communities</h1>
              <p>
                Maintaining a consistent revenue stream in order to keep
                attracting the highest level of competition is not an easy feat
                to accomplish, with Context, we can help ease some of the
                burden.
              </p>
            </div>
            <div className="landing__section__content__subsection reverse subsection-2">
              <div className="landing__section__content__subsection__content">
                <h1>Direct Donations</h1>
                <p>
                  Context also supports direct donations to a community. As an
                  organiser you can use these funds to consitently provide prize
                  pools for your players, pay various staff members for their
                  work, or withdraw a cut to use outside of Context.
                </p>
              </div>
              <div className="landing__section__content__subsection__image">
                <img
                  src="/landing/direct-donation.svg"
                  alt="Landing direct-donation view"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="landing__section section-4">
          <div className="testimonials__header text-center">
            <h1 className="orange bold">
              Here's what our customers have to say
            </h1>
          </div>
          <div className="landing__section__content">
            <Testimonials
              testimonials={[
                {
                  avatarURL: "/testimonials/rogue-logo.png",
                  biline: "Professional Rocket League Player for Rogue",
                  message:
                    "It's super easy to connect your start.gg account and receive your competition winnings instantly.",
                  name: "Turinturo",
                  twitter: "https://twitter.com/Turinturo_",
                },
                {
                  avatarURL: "/testimonials/realize.jpg",
                  biline:
                    "Professional Rocket League Player for @GamingGamersAU",
                  message:
                    "It was so simple to get my money from Context it only took a few minutes.",
                  name: "Realize",
                  twitter: "https://twitter.com/ReaLizeRL",
                },
                {
                  avatarURL: "/ctx-user-icon.jpg",
                  biline: "@RankedHoops community member, frequent donator.",
                  message:
                    "Incredibly easy to use for loading funds. As a community member that frequently donates to tournament prize pools, I am always in and out in under thirty seconds—no need to make an account, fill out a profile, or navigate any complex menus.  Two or three clicks, fill in a number, and done!",
                  name: "CMYK",
                },
              ]}
            />
          </div>
        </section>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Home;
