import React from 'react';
import { PrizeClaimComponentProps } from 'types/Wizard';
import W8Form from 'components/F-W8BEN';
import IrsW9Form from 'components/F-W9';

const PrizeClaimTaxForm = (props) => {

  const { wizardStates }: PrizeClaimComponentProps = props;

  return (
    <div>
      {wizardStates.countrySelect && wizardStates.countrySelect.country === 'US'
        ? <IrsW9Form {...props} />
        : <W8Form {...props} />}
    </div>
  );
};
export default PrizeClaimTaxForm;