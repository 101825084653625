import React from 'react';

type LineItemProps = {
  description: string,
  quantity?: number,
  amount: string,
  isTotal?: boolean,
};

const LineItem = (props: LineItemProps) => {
  return (
    <tr className={props.isTotal ? 'is-total' : ''}>
      <td id="line-item-desc">{props.description}</td>
      <td id="line-item-qty">{props.quantity}</td>
      <td id="line-item-amount">{props.amount}</td>
    </tr>
  );
};
export default LineItem;