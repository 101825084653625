import { useState } from "react";
import { CompetitionWizardStates } from "types/Wizard";
import { leaderboardConfigResolver } from "services/helpers/configs";

import BeginStep from "components/CreateCompetitionWizard/BeginStep";
import BreakdownStep from "components/CreateCompetitionWizard/BreakdownStep";
import CheckoutStep from "components/CreateCompetitionWizard/CheckoutStep";
import ConfigureStep from "components/CreateCompetitionWizard/ConfigureStep";
import PrizePoolStep from "components/CreateCompetitionWizard/PrizePoolStep";
import ProviderStep from "components/CreateCompetitionWizard/ProviderStep";
import Wizard from "components/Wizard";

import PlayCircle from "@iconify/icons-uil/play-circle";
import BracketIcon from "@iconify/icons-mdi/tournament";
import Trophy from "@iconify/icons-uil/trophy";
import MoneyBill from "@iconify/icons-uil/money-bill";
import ShoppingBag from "@iconify/icons-uil/shopping-bag";
import SettingsCog from "@iconify/icons-cil/settings";
import { useNavigate } from "react-router-dom";
import {
  useGetUserCommunitiesQuery,
  useNewCompetitionMutation,
} from "api/graphql";
import { useMyProfile } from "lib/profile";

const CreateCompetition = (props) => {
  const nav = useNavigate();
  const user = useMyProfile();

  const [aggregateId, setAggregateId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { data, isLoading } = useGetUserCommunitiesQuery();

  const { mutateAsync: newCompetition } = useNewCompetitionMutation();

  if (isLoading) {
    return <></>;
  }

  const steps = [
    {
      stepName: "Begin",
      stepDescription: "Create your Competition.",
      stepIcon: PlayCircle,
      stepId: 1,
      stepComponent: BeginStep,
    },
    {
      stepName: "Provider",
      stepDescription: "Select your leaderboard provider.",
      stepIcon: BracketIcon,
      stepId: 2,
      stepComponent: ProviderStep,
    },
    {
      stepName: "Configure Event",
      stepDescription: "Choose which event your competition will use.",
      stepIcon: SettingsCog,
      stepId: 3,
      stepComponent: ConfigureStep,
    },
    {
      stepName: "Prize Breakdown",
      stepDescription: "Provide the splits for the prize pool.",
      stepIcon: Trophy,
      stepId: 4,
      stepComponent: BreakdownStep,
    },
    {
      stepName: "Prize Pool",
      stepDescription: "Choose the amount you want your prize pool to be.",
      stepIcon: MoneyBill,
      stepId: 5,
      stepComponent: PrizePoolStep,
    },
    {
      stepName: "Checkout",
      stepDescription: "Select your preferred payment service.",
      stepIcon: ShoppingBag,
      stepId: 6,
      stepComponent: CheckoutStep,
    },
  ];

  const setCompetitionId = (guid) => {
    setAggregateId(guid);
  };

  const finalClick = async (states: CompetitionWizardStates) => {
    if (!states.checkout)
      return setError({
        message:
          "At least one payment method must be selected for this competition",
      });

    // no payments selected for a competition with a prize
    if (states.prize.dollars > 0 && states.checkout.options.length === 0)
      return setError({
        message:
          "At least one payment method must be selected for this competition",
      });

    // check if the checkout payment options are valid
    if (!states.checkout.isValid)
      return setError({ message: "Selected payment options are invalid." });

    setLoading(true);

    const lbCfgType = states.provider.name;
    let lbConfig;

    // build our leaderboard configs
    switch (lbCfgType) {
      case "smashgg":
        lbConfig = {
          type: lbCfgType,
          smashgg: leaderboardConfigResolver(lbCfgType, states.config),
        };
        break;
      case "sixmans":
        lbConfig = {
          type: lbCfgType,
          sixmans: leaderboardConfigResolver(lbCfgType, states.config),
        };
        break;
      case "battlefy":
        lbConfig = {
          type: lbCfgType,
          battlefy: leaderboardConfigResolver(lbCfgType, states.config),
        };
        break;
      case "challonge":
        lbConfig = {
          type: lbCfgType,
          challonge: leaderboardConfigResolver(lbCfgType, states.config),
        };
        break;
      case "challengermode":
        lbConfig = {
          type: lbCfgType,
          challengermode: leaderboardConfigResolver(lbCfgType, states.config),
        };
        break;
      default:
        setError({ message: `Tournament provider ${lbCfgType} not found` });
        break;
    }

    const payoutConfig = {
      breakdown: states.breakdown.breakdown,
      maxTeams: states.breakdown.maxTeams,
      type: states.breakdown.type,
    };

    // build our payment configs
    const paymentConfigs = states.checkout.options;

    // build the new competition input
    const input = {
      ownerId: states.begin.ownerId,
      enableCrowdfunding: states.prize.crowdfundingEnabled,
      leaderboardConfig: lbConfig,
      amount: states.prize.dollars,
      currency: states.prize.currency,
      payoutConfig: payoutConfig,
      paymentConfig: paymentConfigs,
    };

    newCompetition(
      { input },
      {
        onSettled: (data, error) => {
          if (data.competitionNew)
            nav(`/order/confirmation/${data.competitionNew.id}`);

          if (error) {
            setLoading(false);
            setError(error);
          }
        },
      }
    );
  };

  return (
    <div className="wizard-wrapper">
      <div className="parallax"></div>
      <Wizard
        steps={steps}
        finalClick={finalClick}
        context={{
          aggregateId,
          setCompetitionId,
          user: user,
          communities: data.profile.communities,
        }}
        forceLoading={loading}
        forceError={error}
        noHeaders
        noNegativeMargin
      />
    </div>
  );
};

export default CreateCompetition;
