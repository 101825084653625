import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

export type NavigationItem = {
  slug: string;
  title: string;
};

const items: NavigationItem[] = [
  {
    slug: "",
    title: "My Wallet",
  },
  {
    slug: "/external-profiles",
    title: "External Profiles",
  },
  {
    slug: "/my-communities",
    title: "My Communities",
  },
];

const Navigation = () => {
  const { pathname } = useLocation();
  const { id } = useParams();

  return (
    <div className="profile-v2__navigation__container">
      {items.map((item, k) => {
        const prefix = id ? "/profile/" + id : "/profile";
        const path = prefix + item.slug;

        const className =
          pathname === path
            ? `profile-v2__navigation__item active`
            : `profile-v2__navigation__item inactive`;
        return (
          <Link className={className} to={path} key={k}>
            {item.title}
          </Link>
        );
      })}
    </div>
  );
};

export default Navigation;
