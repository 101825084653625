type HeaderProps = {
  thumbnail: string;
  displayName: string;
  tagline: string;
};

const Header = (props: HeaderProps) => {
  return (
    <div className="profile-v2__header__container">
      <div className="profile-v2__header__image shrink-0">
        <img
          src={props.thumbnail || "/ctx-user-icon.jpg"}
          alt={`${props.displayName}'s profile picture`}
        />
      </div>
      <div className="profile-v2__header__text">
        <h1>
          Welcome, <span>{props.displayName}</span>!
        </h1>
        <h3>{props.tagline}</h3>
      </div>
    </div>
  );
};

export default Header;
