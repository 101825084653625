import { ContextProfile } from "types/Profile";
import ExternalProfilesList from "../ExternalProfilesList";

type ProfileExternalProfilesProps = {
  profile: any;
};

const ProfileExternalProfiles = (props: ProfileExternalProfilesProps) => {
  return (
    <div className="profile-v2__content__container">
      <div className="profile-v2__content__header">
        <h1>Your External Profiles</h1>
        <h3>
          Link your various gaming accounts to your Context profile to be able
          to claim your competition winnings.
        </h3>
      </div>
      <div className="profile-v2__profiles__list">
        <div className="profile-v2__profiles__list__item">
          <div className="profile-v2__profiles__list__item__header">
            <a
              target="_blank"
              href="https://start.gg"
              className="profile-v2__profiles__list__item__header__image"
            >
              <img src="/start-logo.png" alt="start.gg Logo" />
            </a>
            <h1>start.gg</h1>
          </div>
          <ExternalProfilesList profile={props.profile} type="smashgg" />
        </div>
        <div className="profile-v2__profiles__list__item">
          <div className="profile-v2__profiles__list__item__header">
            <a
              target="_blank"
              href="https://battlefy.com"
              className="profile-v2__profiles__list__item__header__image"
            >
              <img src="/battlefy-logo.png" alt="Battlefy Logo" />
            </a>
            <h1>Battlefy</h1>
          </div>
          <ExternalProfilesList profile={props.profile} type="battlefy" />
        </div>
        <div className="profile-v2__profiles__list__item">
          <div className="profile-v2__profiles__list__item__header">
            <a
              target="_blank"
              href="https://challonge.com"
              className="profile-v2__profiles__list__item__header__image"
            >
              <img src="/challonge-logo.png" alt="Challonge Logo" />
            </a>
            <h1>Challonge</h1>
          </div>
          <ExternalProfilesList profile={props.profile} type="challonge" />
        </div>
        <div className="profile-v2__profiles__list__item">
          <div className="profile-v2__profiles__list__item__header">
            <a
              target="_blank"
              href="https://challengermode.com"
              className="profile-v2__profiles__list__item__header__image"
            >
              <img src="/challengermode-logo.png" alt="Challengermode Logo" />
            </a>
            <h1>Challengermode</h1>
          </div>
          <ExternalProfilesList profile={props.profile} type="challengermode" />
        </div>
        <div className="profile-v2__profiles__list__item">
          <div className="profile-v2__profiles__list__item__header">
            <a
              target="_blank"
              href="https://www.twitch.tv/"
              className="profile-v2__profiles__list__item__header__image"
            >
              <img src="/twitch-logo.png" alt="Twitch logo" />
            </a>
            <h1>Twitch</h1>
          </div>
          <ExternalProfilesList profile={props.profile} type="twitch" />
        </div>
        <div className="profile-v2__profiles__list__item">
          <div className="profile-v2__profiles__list__item__header">
            <a
              target="_blank"
              href="https://twitter.com"
              className="profile-v2__profiles__list__item__header__image"
            >
              <img src="/twitter-logo.png" alt="Twitter Logo" />
            </a>
            <h1>Twitter</h1>
          </div>
          <ExternalProfilesList profile={props.profile} type="twitter" />
        </div>
        <div className="profile-v2__profiles__list__item">
          <div className="profile-v2__profiles__list__item__header">
            <a
              target="_blank"
              href="https://discord.com"
              className="profile-v2__profiles__list__item__header__image"
            >
              <img src="/discord-logo.png" alt="Discord Logo" />
            </a>
            <h1>Discord</h1>
          </div>
          <ExternalProfilesList profile={props.profile} type="discord" />
        </div>
        <div className="profile-v2__profiles__list__item">
          <div className="profile-v2__profiles__list__item__header">
            <a
              target="_blank"
              href="https://store.steampowered.com/"
              className="profile-v2__profiles__list__item__header__image"
            >
              <img src="/steam-logo.png" alt="Steam Logo" />
            </a>
            <h1>Steam</h1>
          </div>
          <ExternalProfilesList profile={props.profile} type="steam" />
        </div>
        <div className="profile-v2__profiles__list__item">
          <div className="profile-v2__profiles__list__item__header">
            <a
              target="_blank"
              href="https://discord.com/"
              className="profile-v2__profiles__list__item__header__image"
            >
              <img src="/discord-logo.png" alt="Discord Logo" />
            </a>
            <h1>Discord Webhooks</h1>
          </div>
          <ExternalProfilesList
            profile={props.profile}
            type="discord-webhook"
          />
        </div>
        <div className="profile-v2__profiles__list__item">
          <div className="profile-v2__profiles__list__item__header">
            <a
              target="_blank"
              href="https://paypal.com/"
              className="profile-v2__profiles__list__item__header__image"
            >
              <img src="/paypal-logo.png" alt="PayPal Logo" />
            </a>
            <h1>PayPal</h1>
          </div>
          <ExternalProfilesList profile={props.profile} type="paypal" />
        </div>
      </div>
    </div>
  );
};
export default ProfileExternalProfiles;
