import { useItemProgressListener } from "@rpldy/uploady";
import { useEffect, useState } from "react";

const UploadProgress = () => {
  const progressData = useItemProgressListener();
  const [status, setStatus] = useState("");

  const display = (item: any) => {
    // {uploading ? "Uploading..." : "Uploaded"}
    if (!item) {
      return "";
    } else if (item.completed < 100) {
      return "Uploading...";
    } else if (item.completed == 100) {
      return "Uploaded";
    }

    return progressData.state;
  };

  useEffect(() => {
    setStatus(display(progressData));
  }, [progressData]);

  if (!progressData) {
    return <></>;
  }

  if (progressData.state == "pending") {
    return <></>;
  }

  const c = progressData.completed
    ? `${Math.floor(progressData.completed)}%`
    : "0%";

  return (
    <>
      <div className="w-4/6 relative pt-1 my-auto mx-auto">
        <div className="flex mb-2 items-center justify-between">
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-inflow-purple">
              {status}
            </span>
          </div>

          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-inflow-purple">
              {c}
            </span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-inflow-purple">
          <div
            style={{
              width: c,
            }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-inflow-purple"
          ></div>
        </div>
      </div>
    </>
  );
};

export default UploadProgress;
