import {
  CheckCircleIcon,
  LockClosedIcon,
  PencilAltIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import FormikWizard from "components/FormikWizard";
import { Step } from "components/FormikWizard/types";
import { FormikHelpers, FormikValues } from "formik";
import React from "react";
import SelectGame from "./Steps/SelectGame";
import SelectFormat from "./Steps/SelectFormat";
import SelectCompetition from "./Steps/SelectCompetition";
import EventDetails from "./Steps/EventDetails";
import SelectCommunity from "./Steps/SelectCommunity";

import * as Yup from "yup";

interface NewCompetitionWizardProps {
  data: any;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => void | Promise<any>;
  isLoading: boolean;
}

const NewCompetitionWizard = ({
  data,
  isLoading,
  onSubmit,
}: NewCompetitionWizardProps) => {
  const initialValues = {
    community: null,
    game: null,
  };

  // Step Form Spread: https://stackoverflow.com/a/47771259
  const steps: Step[] = [
    {
      component: () => <SelectCommunity />,
      validationSchema: Yup.object().shape({
        community: Yup.object().nullable().required("Community is required"),
      }),
      extraProps: {
        title: "Select Community",
      },
    },
    {
      component: () => <SelectCompetition />,
      validationSchema: null,
      extraProps: {
        title: "Setup Your Competition",
      },
    },
    {
      component: () => <SelectGame />,
      validationSchema: Yup.object().shape({
        game: Yup.object().nullable().required("Game is required"),
      }),
      extraProps: {
        title: "Select Game",
      },
    },
    // We believe selecting Platform (PS, XBOX, PC) is a not required in modern day gaming.
    // Select game format (RL: 1v1, 2v2...etc) isn't too important for MVP. It is a nice thing to have, but not mission critical.
    // {
    //   component: () => <SelectFormat />,
    //   validationSchema: null,
    //   extraProps: {
    //     title: "Select Game Format",
    //   },
    // },
    {
      component: () => <EventDetails />,
      validationSchema: null,
      extraProps: {
        title: "Competition Details",
      },
    },
  ];

  return (
    <FormikWizard
      initialValues={initialValues}
      activeStepIndex={0}
      onSubmit={onSubmit}
      steps={steps}
    >
      {({
        renderComponent,
        currentStepIndex,
        handlePrev,
        handleNext,
        isNextDisabled,
        isPrevDisabled,
        isLastStep,
      }) => (
        <div className="flex flex-col gap-y-6 lg:flex-row lg:gap-x-8">
          <div className="flex flex-initial flex-col gap-y-2">
            {steps.map((step, i) => (
              <div
                key={i}
                className={classNames(
                  i == currentStepIndex &&
                    "border-ctx-orange border-opacity-60 bg-gradient-to-r from-ctx-orange/20 to-orange-800/10 text-ctx-orange/90",
                  i < currentStepIndex &&
                    "border-green-500 border-opacity-40 bg-gradient-to-r from-green-800/20 to-green-900/10 text-green-400/70",
                  i > currentStepIndex &&
                    "border-gray-300 border-opacity-20 bg-gradient-to-r from-neutral-400/5 to-neutral-600/5 text-neutral-300/50",
                  "rounded-md border py-2 pl-1 pr-12"
                )}
              >
                {i == currentStepIndex && (
                  <PencilAltIcon className="mr-1 -mt-1 inline h-6 w-6 text-ctx-orange/90" />
                )}
                {i < currentStepIndex && (
                  <CheckCircleIcon className="mr-1 -mt-1 inline h-6 w-6 text-green-400/70" />
                )}
                {i > currentStepIndex && (
                  <LockClosedIcon className="mr-1 -mt-1 inline h-6 w-6 text-neutral-300/50" />
                )}
                {step.extraProps.title}
              </div>
            ))}
          </div>
          <div className="mb-4 flex-1 rounded-lg border border-ctx-orange border-opacity-50 bg-opacity-5 bg-gradient-to-r from-ctx-orange/5 to-orange-900/5">
            <div className="space-y-4">
              <div className="space-y-4 px-4 py-5 sm:px-8 md:px-16">
                {renderComponent()}

                <div className="mt-4 flex justify-between">
                  <button
                    onClick={handlePrev}
                    type="button"
                    className={classNames(
                      isPrevDisabled && "invisible",
                      //   "text-neutral-rounded inline-flex items-center rounded bg-neutral-700/25 px-6 py-1.5 text-neutral-300 transition hover:bg-neutral-800/30 hover:text-neutral-400"
                      " rounded-md border border-context-black border-opacity-50 bg-context-gray bg-opacity-60 px-6 py-1 text-neutral-400 transition hover:bg-context-dark hover:text-neutral-500 hover:outline-none"
                    )}
                  >
                    Back
                  </button>
                  <div className="inline-flex items-center">
                    <button
                      type="submit"
                      onClick={handleNext}
                      disabled={isLoading}
                      className="ctx-gradient-button inline-flex items-center px-6 py-1.5"
                    >
                      {isLastStep
                        ? isLoading
                          ? "Submitting"
                          : "Submit"
                        : "Next"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </FormikWizard>
  );
};

export default NewCompetitionWizard;
