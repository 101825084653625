import React from 'react';

import Button from 'components/Button';

const CompetitionButton = ({ status, URL, type }) => {
  if (type === 'sixmans') return <Button
    label="View"
    onClick={() => window.open(URL, '_blank')}
    fullWidth
  />;
  const button = <Button
    label="View Bracket"
    onClick={() => window.open(URL, '_blank')}
    fullWidth
  />
  switch (status && status.toString().toLowerCase()) {
    case 'active':
      return button;
    case 'finished':
      return button;
    case 'completed':
      return button;
    case 'processing':
      return button;
    case 'created':
      return <Button
        label="Sign up"
        onClick={() => window.open(URL, '_blank')}
        fullWidth
      />;
    default:
      return null;
  };
};

export default CompetitionButton;