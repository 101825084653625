import { useState } from "react";
import { CommunityWizardStates } from "types/Wizard";

// components
import BeginStep from "components/CommunityWizardSteps/BeginStep";
import Wizard from "components/Wizard";

// icons
import playCircle from "@iconify/icons-uil/play-circle";
import { useNavigate } from "react-router-dom";
import { useNewCommunityMutation } from "api/graphql";

const CreateCommunity = (props) => {
  const nav = useNavigate();

  const { mutateAsync: newCommunityMutation } = useNewCommunityMutation();

  const [aggregateId, setAggregateId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState([]);

  const steps = [
    {
      stepName: "Begin",
      stepDescription: "Create your Community.",
      stepIcon: playCircle,
      stepId: 1,
      stepComponent: BeginStep,
    },
  ];

  const setCompetitionId = (guid) => {
    setAggregateId(guid);
  };

  const finalClick = async (states: CommunityWizardStates) => {
    setLoading(true);
    // make sure we have both steps' states
    if (!states.begin) {
      setLoading(false);
      return setError({
        message: "Oops, we're missing a step, please refresh and try again",
      });
    }

    const communityInput = {
      ...states.begin,
    };

    // create the community so we have an id to attach the plans to
    await newCommunityMutation(
      {
        communityNewInput: communityInput,
      },
      {
        onSettled: (data, error) => {
          if (data && data.communityNew) {
            if (data.communityNew) {
              setLoading(false);
              nav(`/${data.communityNew.id}/`);
            }
          }

          if (error) {
            setLoading(false);
            setError(error);
          }
        },
      }
    );
  };

  const resetErrors = () => {
    setInputErrors([]);
  };

  return (
    <div className="wizard-wrapper">
      <div className="parallax"></div>
      <Wizard
        steps={steps}
        finalClick={finalClick}
        context={{
          aggregateId,
          setCompetitionId,
          inputErrors,
          resetErrors,
          ...props,
        }}
        noHeaders
        forceError={error}
        forceLoading={loading}
        noNegativeMargin
      />
    </div>
  );
};

export default CreateCommunity;
