import { useState, useEffect } from "react";

import { ContextProfile } from "types/Profile";
import getUserLocale from "get-user-locale";

import CountrySelect from "components/CountrySelect";
import GeneralForm from "components/GeneralForm";
import Parallax from "components/Parallax";
import PrizeClaimTaxForm from "components/PrizeClaimTaxForm/PrizeClaimTaxForm";
import Wizard from "components/Wizard";

import DollarIcon from "@iconify/icons-uil/dollar-alt";
import EarthIcon from "@iconify/icons-ion/earth-sharp";
import InfoIcon from "@iconify/icons-uil/info-circle";
import { useNavigate, useParams } from "react-router-dom";
import {
  useClaimWinningsMutation,
  useGetRequiredFormsQuery,
} from "api/graphql";
import LoadingData from "components/LoadingData";
import ClaimWinnings from "components/ClaimWinnings";

type WinningsClaimProps = {
  profile?: ContextProfile;
};

const Claim = (props: WinningsClaimProps) => {
  const { id } = useParams();
  const nav = useNavigate();
  const locale = getUserLocale();

  const [error, setError] = useState(null);
  const [steps, setSteps] = useState(null);
  const [forms, setForms] = useState({
    general: null,
    irstax: null,
    exists: {},
  });

  const {
    data: formsData,
    isLoading: formsLoading,
    error: formsError,
  } = useGetRequiredFormsQuery({ prizeId: id });

  const {
    mutateAsync: executeClaimWinnings,
    isLoading: claimLoading,
    error: claimError,
  } = useClaimWinningsMutation();

  useEffect(() => {
    if (!formsData) {
      return;
    }

    const forms = {
      prize: formsData.prize,
      locale: locale,
      general: null,
      irstax: null,
      exists: {},
    };

    const steps = [];

    // builds form.
    if (formsData.requiredForms && formsData.requiredForms.forms) {
      formsData.requiredForms.forms.forEach((form) => {
        forms[form.type] = form.data;
        forms.exists[form.type] = true;
      });
    }

    if (forms.exists["general"]) {
      steps.push({
        stepName: "General Form",
        stepId: 1,
        stepDescription: "",
        stepComponent: GeneralForm,
        stepIcon: InfoIcon,
      });
    }

    if (forms.exists["irstax"]) {
      steps.push(
        {
          stepName: "Country",
          stepId: 2,
          stepDescription: "",
          stepComponent: CountrySelect,
          stepIcon: EarthIcon,
        },
        {
          stepName: "Tax form",
          stepId: 3,
          stepDescription: "",
          stepComponent: PrizeClaimTaxForm,
          stepIcon: DollarIcon,
        }
      );
    }

    if (!forms.exists["general"] && !forms.exists["irstax"]) {
      steps.push({
        stepName: "Claim Winnings",
        stepId: 1,
        stepDescription: "",
        stepComponent: ClaimWinnings,
        stepIcon: InfoIcon,
      });
    }

    setForms(forms);
    setSteps(steps);
  }, [formsData]);

  const handleFinalClick = (states) => {
    if (forms.exists["general"] && !states.generalValid) {
      setError({
        message:
          "Missing general form, please check it was submitted successfully",
      });
      return;
    }

    if (forms.exists["irstax"] && !states.irstaxValid) {
      setError({
        message: "Missing tax form, please check it was submitted successfully",
      });
      return;
    }

    const variables = {
      input: { id },
    };
    executeClaimWinnings(variables, {
      onSettled: (data, error) => {
        if (data.prizeClaim.type == "Success") {
          nav("/profile");
        }
      },
    });
  };

  if (claimLoading || formsLoading) {
    return <LoadingData />;
  }

  return (
    <div className="my-16">
      <div className="prize-claim__wrapper mx-2">
        {steps && (
          <Wizard
            steps={steps}
            finalClick={handleFinalClick}
            context={forms}
            forceLoading={claimLoading}
            forceError={error || claimError}
            noHeaders
            noNegativeMargin
          />
        )}
      </div>
    </div>
  );
};

export default Claim;
