import React from "react";
import { CustomTableHeader } from "types/Components";
import { statusClassResolver } from "services/helpers/resolvers";
import classNames from "classnames";

type CustomTableProps = {
  tableHeaders: CustomTableHeader[];
  tableData: any[];
  className?: any;
  loading?: boolean;
};

const CustomTable = (props: CustomTableProps) => {
  return (
    <table className={classNames(props?.className, "table w-full")}>
      <thead>
        <tr>
          {props.tableHeaders.map((header, i) => (
            <th className="px-1" scope="col" key={i} align={header.props.align}>
              {header.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.tableData.map((row, i) => {
          return (
            <tr
              key={i}
              className={
                (classNames(row.disabled && "disabled"), "w-full shrink-0")
              }
            >
              {Object.keys(row).map((data, j) => {
                if (data === "disabled") return;
                return (
                  <td
                    key={j}
                    className={classNames(
                      data != "name" && statusClassResolver(row[data]),
                      "min-w-full shrink-0 whitespace-nowrap px-1 py-2"
                    )}
                    role="cell"
                  >
                    {row[data]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default CustomTable;
