import React from 'react';
import Switch from 'components/Switch';
import AmountSelect from 'components/AmountSelect';
import { currencies } from 'utils';

const PrizePoolForm = (props) => {

  const handleToggleChange = (e) => {
    props.updateValues({
      ...props.values,
      prizePool: '0',
      crowdfundingEnabled: !props.values.crowdfundingEnabled
    });
  };

  const handlePrizePoolChange = (e) => {
    props.updateValues({
      ...props.values,
      prizePool: e.target.value
    });
  };

  const handleCurrencyChange = (selectedOption) => {
    props.updateValues({
      ...props.values,
      currency: selectedOption.value
    });
  };

  const pre = ['5', '10', '15', '30', '50', '100', '200', '250'];

  return (
    <div>
      <Switch
        before="Enable Crowdfunding?"
        onChange={handleToggleChange}
        checked={props.values.crowdfundingEnabled}
      />
      <AmountSelect
        handleInputChange={handlePrizePoolChange}
        handleToggleButtonChange={handlePrizePoolChange}
        handleCurrencyChange={handleCurrencyChange}
        values={{ currency: props.values.currency, amountInputValue: props.values.prizePool }}
        currencies={currencies}
        amounts={pre}
      />
      {props.validator.message('Prize Pool', props.values.prizePool, 'required|numeric|min:0,num')}
    </div>
  );
};
export default PrizePoolForm;