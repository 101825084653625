import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__text">
        <a className="footer__brand" href="/">
          context<span>.</span>
        </a>
        <span>
          Empowering tournament organisers and esport communities to create
          effective revenue streams and gamify their platforms
        </span>
        <div className="footer__text__socials">
          <a href="https://discord.gg/c6BR2UT">
            <img src="/discord.svg" alt="" width="30px" />
          </a>
          <a href="https://twitter.com/ctx_gg">
            <img src="/twitter.svg" alt="" width="30px" />
          </a>
        </div>
      </div>
      <div className="footer__links">
        <ul>
          <li>Company</li>
          <li>
            <Link to="/about">
              <p>About</p>
            </Link>
          </li>
          <li>
            <Link to="/help">
              <p>Help</p>
            </Link>
          </li>
          <li>
            <Link to="/contact">
              <p>Contact</p>
            </Link>
          </li>
        </ul>
        <ul>
          <li>Links</li>
          <li>
            <Link to="/get-started">
              <p>Getting Started</p>
            </Link>
          </li>
          <li>
            <Link to="/competitions">
              <p>Competitions</p>
            </Link>
          </li>
          <li>
            <Link to="/communities">
              <p>Communities</p>
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};
export default Footer;
