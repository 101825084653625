import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";

import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useMyProfile } from "lib/profile";

const Links = [
  {
    name: "About",
    to: "/about",
  },
  {
    name: "Help",
    to: "/help",
  },
  {
    name: "Competitions",
    to: "/competitions",
  },
  {
    name: "Communities",
    to: "/communities",
  },
  {
    name: "Contact",
    to: "/contact",
  },
];

const CurrentPage = (location: any, path: string) => {
  return location.pathname.split("/")[1] === path.substring(1);
};

const Nav = () => {
  const user = useMyProfile();
  const location = useLocation();

  return (
    <Disclosure as="nav" className="relative z-50 bg-context-gray">
      {({ open }) => (
        <>
          <div className="mx-auto px-2 lg:px-6">
            <div className="flex h-16 justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                  <Link
                    to="/"
                    className="select-none text-3xl font-bold text-white"
                  >
                    <img className="w-8" src="/ctx-icon.png" alt="" />
                  </Link>
                </div>
                <div className="hidden lg:ml-12 lg:flex lg:space-x-8">
                  {Links.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={classNames(
                        CurrentPage(location, item.to)
                          ? "border-ctx-orange text-white"
                          : "border-transparent text-neutral-400 hover:text-neutral-600",
                        "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-semibold transition duration-500"
                      )}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-neutral-200 hover:bg-neutral-800 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ctx-orange">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                {/* Profile dropdown */}
                {!user && (
                  <a
                    className="mx-4 rounded bg-gradient-to-br from-ctx-dark-orange via-ctx-orange to-ctx-dark-orange bg-size-150 bg-pos-0 px-6 py-1 font-semibold text-white transition-all duration-500 hover:bg-pos-100"
                    href="/login"
                  >
                    Login
                  </a>
                )}
                {user && (
                  <>
                    <span className="text-ctx-orange">{user.username}</span>
                    <Link
                      to="/create"
                      className="mx-4 rounded bg-gradient-to-br from-ctx-dark-orange via-ctx-orange to-ctx-dark-orange bg-size-150 bg-pos-0 px-4 py-1 font-semibold text-white transition-all duration-500 hover:bg-pos-100"
                    >
                      Create
                    </Link>
                    <Menu as="div" className="relative flex-shrink-0">
                      <div>
                        <Menu.Button className="flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-ctx-orange">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-10 w-10 rounded-full"
                            src={user.avatarURL ?? "/ctx-user-icon.jpg"}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-50 mt-1 w-48 origin-top-right  rounded-md border-ctx-orange bg-context-gray py-1 shadow-lg ring-1 ring-white  ring-opacity-10 focus:outline-none">
                          <Menu.Item>
                            {() => (
                              <Link
                                to="/profile"
                                className="block px-4 py-2 text-sm text-gray-100 transition hover:bg-context-dark hover:text-ctx-orange"
                              >
                                Profile
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {() => (
                              <a
                                href="/logout"
                                className="block px-4 py-2 text-sm text-red-400 transition hover:bg-context-dark hover:text-red-600"
                              >
                                Logout
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 pt-2 pb-3">
              {Links.map((item, i) => (
                <Disclosure.Button
                  key={i}
                  as={Link}
                  to={item.to}
                  className={classNames(
                    CurrentPage(location, item.to)
                      ? "border-ctx-orange bg-neutral-900 text-ctx-orange"
                      : "border-transparent text-neutral-400 hover:border-gray-300 hover:bg-context-light hover:text-neutral-50",
                    "block border-l-4 py-2 pl-3 pr-4 text-base font-medium transition duration-300"
                  )}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-200 pt-4 pb-3">
              {!user && (
                <a
                  className="block px-4 py-2 text-lg font-medium text-neutral-200 hover:bg-context-light hover:text-ctx-orange"
                  href="/login"
                >
                  Login
                </a>
              )}
              {user && (
                <>
                  <div className="flex items-center justify-between px-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={user.avatarURL ?? "/ctx-user-icon.jpg"}
                          alt=""
                        />
                      </div>
                      <div className="ml-1 flex-initial">
                        <div className="text-base font-medium text-ctx-orange">
                          {user.username}
                        </div>
                        <div className="text-sm font-medium text-gray-500">
                          {user.connection}
                        </div>
                      </div>
                    </div>
                    <Link
                      to="/create"
                      className="rounded bg-gradient-to-br from-ctx-dark-orange via-ctx-orange to-ctx-dark-orange bg-size-150 bg-pos-0 px-5 py-1.5 font-semibold text-white transition-all duration-500 hover:bg-pos-100"
                    >
                      Create
                    </Link>
                  </div>
                  <div className="z-50 mt-3 space-y-1">
                    <Disclosure.Button
                      as={Link}
                      to="/profile"
                      className="block px-4 py-2 text-base font-medium text-neutral-200 hover:bg-context-light hover:text-ctx-orange"
                    >
                      Your Profile
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/logout"
                      className="block px-4 py-2 text-base font-medium text-red-400 hover:bg-context-light hover:text-red-600"
                    >
                      Logout
                    </Disclosure.Button>
                  </div>
                </>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Nav;
