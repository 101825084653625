import classNames from "classnames";
import React from "react";

type InputProps = {
  label?: any;
  type?: string;
  name?: string;
  id?: string;
  onChange?: any;
  placeholder?: string;
  autoComplete?: string;
  value?: any;
  endAdornment?: any;
  startAdornment?: any;
  button?: any;
  onClick?: any;
  action?: any;
  error?: any;
  success?: boolean;
  triggerOnEnter?: boolean;
  disabled?: boolean;
  inverse?: boolean;
  className?: string;
  pattern?: string;
  inputMode?: any;
  min?: number;
  max?: number;
  maxLength?: number;
  styles?: any;
};

const Input = (props: InputProps) => {
  return (
    <div className={`input__wrapper ${props.className ? props.className : ""}`}>
      {props.styles && <style>{props.styles}</style>}
      <span className="input__label__v2">{props.label}</span>
      <div className="input__container">
        {props.startAdornment && (
          <div className="input__adornment start">{props.startAdornment}</div>
        )}
        <input
          className={classNames(
            "w-full rounded border-neutral-600 bg-context-dark text-neutral-200 caret-ctx-orange transition placeholder:text-neutral-600 focus:border-ctx-orange focus:ring-ctx-orange ",
            props.endAdornment || (props.button && "end-adornment"),
            props.startAdornment && "start-adornment",
            props.error && "error",
            props.success && "success",
            props.disabled && "disabled",
            props.inverse && "inverse"
          )}
          type={props.type ? props.type : "text"}
          name={props.name}
          id={props.id}
          onChange={props.onChange}
          placeholder={props.placeholder}
          autoComplete={props.autoComplete}
          value={props.value}
          onKeyPress={(e) =>
            e.key === "Enter" && props.triggerOnEnter ? props.action() : null
          }
          disabled={props.disabled}
          pattern={props.pattern}
          max={props.max}
          maxLength={props.maxLength}
          inputMode={props.inputMode}
        />
        {/* <label
          className="input__label"
          htmlFor={props.id}
        >
          {props.label}
        </label> */}
        {props.endAdornment && (
          <div className="input__adornment end">{props.endAdornment}</div>
        )}
        {props.button && (
          <div className="input__adornment button-adorn" onClick={props.action}>
            {props.button}
          </div>
        )}
      </div>
      {props.error && (
        <div className="input__label__below">{props.error.message}</div>
      )}
    </div>
  );
};
export default Input;
