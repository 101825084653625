import { useState } from "react";
import { CommunityUpdateGeneralSettingsInput } from "types/Community";

// components
import CustomSelect from "components/Select";
import FileUpload from "components/FileUpload";
import Input from "components/Input";
import Switch from "components/Switch";
import Tooltip from "components/Tooltip";

// select styles
import SelectErrorStyle from "styles/Select/SelectErrorStyle";
import SelectNewStyle from "styles/Select/SelectNewStyle";
import SettingsFormFooter from "./SettingsFormFooter";
import {
  useUpdateGeneralSettingsMutation,
  useUploadFileMutation,
} from "api/graphql";

const CommunityGeneralSettings = (props) => {
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [values, setValues] = useState({
    name: props.community.name,
    alias: props.community.alias,
    logo: props.community.logo,
    banner: props.community.banner,
    regions: regionsInputResolver(props.community.regions),
    hidden: props.community.hidden,
    communityDonationsEnabled: props.community.communityDonationsEnabled,
    competitionDonationsEnabled: props.community.competitionDonationsEnabled,
  });

  const [formError, setFormError] = useState({
    // id of the component to make things easier to identify
    where: "",
    message: "",
  });

  const {
    data: updateSettingsData,
    mutateAsync: updateSettingsAsync,
    isLoading: updateSettingsIsLoading,
    error: updateSettingsError,
  } = useUpdateGeneralSettingsMutation();
  const {
    data: uploadLogoData,
    mutateAsync: uploadLogoAsync,
    isLoading: uploadLogoIsLoading,
    error: uploadLogoError,
  } = useUploadFileMutation();
  const {
    data: uploadBannerData,
    mutateAsync: uploadBannerAsync,
    isLoading: uploadBannerIsLoading,
    error: uploadBannerError,
  } = useUploadFileMutation();

  const resolveCdnUrl = (
    fileId: string,
    fileName: string,
    type: string
  ): string => {
    const base = "https://ik.imagekit.io/ctx/ik-seo/tr:n-";
    const index = fileId.lastIndexOf(".");
    const fileExtension = fileId.substring(index + 1);
    const path = fileId.substring(0, index);
    return `${base}${type}/${path}/${type}.${fileExtension}`;
  };

  const handleUploadBanner = async (e) => {
    setIsUnsaved(true);
    const data = await uploadBannerAsync({
      file: e.target.files[0],
      type: "banner",
    });

    const url = resolveCdnUrl(
      data.fileUpload.id,
      data.fileUpload.name,
      "banner"
    );

    setValues({
      ...values,
      banner: url,
    });
  };

  const handleUploadLogo = async (e) => {
    setIsUnsaved(true);
    const data = await uploadLogoAsync({
      file: e.target.files[0],
      type: "logo",
    });

    const url = resolveCdnUrl(data.fileUpload.id, data.fileUpload.name, "logo");

    setValues({
      ...values,
      logo: url,
    });
  };

  const handleChange = (e) => {
    setIsUnsaved(true);
    setFormError(null);
    setValues({
      ...values,
      [e.target.id]: e.target.value,
    });
  };

  const handleSelectChange = (selected, info) => {
    setIsUnsaved(true);
    setFormError(null);
    // switches on the id of the element
    switch (info.name) {
      case "region":
        return setValues({ ...values, regions: selected });
    }
  };

  const handleToggleChange = (e) => {
    setIsUnsaved(true);
    setValues({
      ...values,
      [e.target.id]: !values[e.target.id],
    });
  };

  const handleSave = async () => {
    const input: CommunityUpdateGeneralSettingsInput = {
      id: props.community.id,
      name: values.name,
      alias: values.alias,
      regions: values.regions && values.regions.map((x: any) => x.value),
      communityDonationsEnabled: values.communityDonationsEnabled,
      competitionDonationsEnabled: values.competitionDonationsEnabled,
      logo: values.logo,
      banner: values.banner,
      hidden: values.hidden,
    };

    try {
      await validateFields(values);
    } catch (err) {
      setFormError(err);
      return;
    }

    await updateSettingsAsync({ input });
    setIsUnsaved(false);
  };

  const validateFields = (state: UpdatedDetails): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      // check if alias has any spaces or illegal characters
      // only allow numbers, letters, hyphens & underscores
      const aliasRegex = new RegExp(`^\S*^[a-z0-9\-\_]*$`);
      const validAlias = aliasRegex.test(state.alias);
      if (!validAlias) {
        reject({
          where: "alias",
          message:
            "Invalid alias, must be all lowercase with no special characters, hyphens & underscores are permitted.",
        });
      }
      resolve(true);
    });
  };

  const resetState = () => {
    setValues({
      name: props.community.name,
      alias: props.community.alias,
      logo: props.community.logo,
      banner: props.community.banner,
      regions: regionsInputResolver(props.community.regions),
      hidden: props.community.hidden,
      communityDonationsEnabled: props.community.communityDonationsEnabled,
      competitionDonationsEnabled: props.community.competitionDonationsEnabled,
    });
    setIsUnsaved(false);
    setFormError(null);
  };

  return (
    <div className="my-2 sm:col-span-2 sm:my-0">
      <div className="sm:flex sm:gap-x-5">
        <Input
          id="name"
          name="name"
          className="community-admin__settings__form__input"
          label="Name"
          placeholder={props.community.name}
          onChange={handleChange}
          value={values.name}
          error={formError && formError.where === "name" && formError}
        />
        <Input
          id="alias"
          name="alias"
          className="community-admin__settings__form__input"
          label={
            <Tooltip
              label="Alias"
              message="Used for the short URL for your Community. Must be all lowercase with no spaces. Hyphens and underscores are permitted."
            />
          }
          placeholder="my-community"
          onChange={handleChange}
          value={values.alias}
          error={formError && formError.where === "alias" && formError}
        />
      </div>
      <div className="community-admin__settings__form__input__wrapper--split whitespace-nowrap">
        <CustomSelect
          id="region"
          name="region"
          label="Region(s) where you host events"
          className="community-admin__settings__form__input"
          styles={SelectNewStyle}
          value={values.regions}
          onChange={handleSelectChange}
          options={regionOpts}
          isSearchable={false}
          isMultiple={true}
          error={formError && formError.where === "region" && formError}
          errorStyles={SelectErrorStyle}
        />
      </div>
      <div className="community-admin__settings__form__upload">
        <FileUpload
          id="logo"
          onChange={(e: any) => handleUploadLogo(e)}
          label={
            <Tooltip
              label="Logo image"
              message="Use a 200x200 resolution image for best results."
            />
          }
          loading={uploadLogoIsLoading}
          error={uploadLogoError}
          success={uploadLogoData != null}
        />
        <FileUpload
          id="banner"
          onChange={(e: any) => handleUploadBanner(e)}
          label={
            <Tooltip
              label="Banner image"
              message="Use a 1920x480 resolution image for best results."
            />
          }
          loading={uploadBannerIsLoading}
          error={uploadBannerError}
          success={uploadBannerData != null}
        />
      </div>
      <div className="space-y-3 xl:flex xl:space-y-0 xl:space-x-3">
        <Switch
          before="Enable Community Donations?"
          className="w-fit flex-initial rounded bg-context-light p-1"
          onChange={handleToggleChange}
          id="communityDonationsEnabled"
          checked={values.communityDonationsEnabled}
        />
        <Switch
          before="Enable Competition Donations?"
          className="w-fit flex-initial rounded bg-context-light p-1"
          onChange={handleToggleChange}
          id="competitionDonationsEnabled"
          checked={values.competitionDonationsEnabled}
        />
        <Switch
          before="Private?"
          className="w-fit flex-initial rounded bg-context-light p-1"
          onChange={handleToggleChange}
          id="hidden"
          checked={values.hidden}
        />
      </div>

      <SettingsFormFooter
        id={props.community.id}
        isUnsaved={isUnsaved}
        saveLoading={updateSettingsIsLoading}
        saveError={updateSettingsError}
        saveSuccess={updateSettingsData != null}
        handleSave={handleSave}
        resetState={resetState}
      />
    </div>
  );
};

export default CommunityGeneralSettings;

const regionOpts = [
  { value: "ASIA", label: "Asia" },
  { value: "EU", label: "Europe" },
  { value: "ME", label: "Middle East" },
  { value: "NA", label: "North America" },
  { value: "OCE", label: "Oceania" },
  { value: "SAM", label: "South America" },
];

const regionsInputResolver = (regions: string[]): any[] => {
  if (!regions) {
    return [];
  }

  return regions.map((x) => {
    return regionOpts.find((y) => y.value === x);
  });
};

type UpdatedDetails = {
  name: string;
  alias: string;
  logo: string;
  banner: string;
  regions: string[];
  hidden: boolean;
  communityDonationsEnabled: boolean;
  competitionDonationsEnabled: boolean;
};
