import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { Field } from "formik";
import React, { Fragment, useState } from "react";

const people = [
  {
    id: 1,
    name: "Wade Cooper",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 2,
    name: "Arlene Mccoy",
    avatar:
      "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 3,
    name: "Devon Webb",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
  {
    id: 4,
    name: "Tom Cook",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 5,
    name: "Tanya Fox",
    avatar:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 6,
    name: "Hellen Schmidt",
    avatar:
      "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 7,
    name: "Caroline Schultz",
    avatar:
      "https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 8,
    name: "Mason Heaney",
    avatar:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 9,
    name: "Claudie Smitham",
    avatar:
      "https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 10,
    name: "Emil Schaefer",
    avatar:
      "https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

const FormDropdown = ({
  field: { name, value }, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { label, options } = props;

  // this just pulls out all the old communities from the options
  let oldCommunities = options?.map((element) => {
    return element.node;
  });

  console.log(oldCommunities);

  const handleChange = (newValue) => {
    setFieldValue(name, newValue);
  };

  const isErrored = touched[name] === true && errors[name];

  return (
    <Listbox value={value} onChange={handleChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-neutral-300">
            {label}
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button
              className={classNames(
                isErrored
                  ? "border-red-500 bg-red-800 bg-opacity-10 text-red-500 text-opacity-90"
                  : "bg-opacity-60 text-neutral-500",
                "relative block w-full cursor-pointer rounded-md border border-context-black border-opacity-50 bg-context-gray px-3 py-2 text-neutral-300 placeholder-neutral-500 caret-ctx-orange focus:z-10 focus:border-ctx-orange focus:border-opacity-80 focus:bg-context-dark focus:text-neutral-500 focus:outline-none focus:ring-0"
              )}
            >
              <span className="flex items-center">
                {value?.logo && (
                  <img
                    src={value?.logo}
                    alt=""
                    className="h-6 w-6 flex-shrink-0 rounded-full"
                  />
                )}
                {value?.name && (
                  <span className="ml-3 block truncate">{value?.name}</span>
                )}
                {!value?.name && (
                  <span className="block truncate">Select Community</span>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-neutral-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {/* Old communities */}
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md border border-context-black border-opacity-50 bg-context-gray py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {oldCommunities?.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "bg-gradient-to-r from-orange-700 to-ctx-orange text-white"
                          : "text-neutral-400",
                        "relative cursor-pointer select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            src={option.logo}
                            alt=""
                            className="h-6 w-6 flex-shrink-0 rounded-full"
                          />
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {option.name}
                          </span>
                        </div>

                        {selected && (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-orange-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default FormDropdown;

// when we move to the new communities, use this block of code:
{
  /* <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md border border-context-black border-opacity-50 bg-context-gray py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
  {options?.map((option) => (
    <Listbox.Option
      key={option.id}
      className={({ active }) =>
        classNames(
          active
            ? "bg-gradient-to-r from-orange-700 to-ctx-orange text-white"
            : "text-neutral-400",
          "relative cursor-pointer select-none py-2 pl-3 pr-9"
        )
      }
      value={option}
    >
      {({ selected, active }) => (
        <>
          <div className="flex items-center">
            <img
              src={option.logo}
              alt=""
              className="h-6 w-6 flex-shrink-0 rounded-full"
            />
            <span
              className={classNames(
                selected ? "font-semibold" : "font-normal",
                "ml-3 block truncate"
              )}
            >
              {option.name}
            </span>
          </div>

          {selected && (
            <span
              className={classNames(
                active ? "text-white" : "text-orange-600",
                "absolute inset-y-0 right-0 flex items-center pr-4"
              )}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          )}
        </>
      )}
    </Listbox.Option>
  ))}
</Listbox.Options> */
}
