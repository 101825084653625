import ViewCompetition from "pages/ViewCompetition";
import PrivateRoute from "PrivateRoute";
import { Route, Routes } from "react-router-dom";
import Admin from "./Admin/Admin";
import CommunityAdmin from "./Admin/CommunityAdmin";
import CommunityAdminOrders from "./Admin/CommunityAdminOrders";
import CommunityAdminSettings from "./Admin/CommunityAdminSettings";
import CommunityAdminWinners from "./Admin/CommunityAdminWinners";
import ViewCommunity from "./ViewCommunity";
import ViewCommunityDebug from "./ViewCommunityDebug";

const CreateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ViewCommunity />} />
      <Route path="/competitions/:compId" element={<ViewCompetition />} />

      <Route path="/debug" element={<ViewCommunityDebug />} />

      {/* ADMIN ROUTES */}
      <Route
        path="/admin/*"
        element={
          <PrivateRoute>
            <Admin />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default CreateRoutes;
