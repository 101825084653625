import Moment from "react-moment";
import {
  gameBannerResolver,
  statusClassResolver,
} from "services/helpers/resolvers";
import { Link } from "react-router-dom";

import AmountView from "components/AmountView";

const CompetitionCard = (props) => {
  const { data } = props;

  const href = () => {
    const communityId = data.community.alias || data.community.id;
    return `/${communityId}/competitions/${data.id}`;
  };

  return (
    <div className="competition-card">
      <div className="competition-card__header">
        <div
          style={{
            backgroundImage: `linear-gradient( 180deg, transparent 0%, #141414 100% ), url("${data?.videoGame?.id}.jpg")`,
            backgroundSize: "cover",
          }}
          className="competition-card__header-image"
        ></div>
      </div>
      <div className="competition-card__content">
        <span className="competition-card__content-start">
          <Moment fromNow date={data.startDateTime} />
          <span
            style={{ marginLeft: "8px", fontWeight: "bold" }}
            className={statusClassResolver(data.status)}
          >
            {data.status}
          </span>
        </span>
        <h1
          className={
            data.name.length > 40
              ? "text-sm"
              : data.name.length > 60
              ? "text-xs"
              : ""
          }
        >
          {data.name}
        </h1>
        {data.community && <h3>{data.community.name}</h3>}
        <div className="competition-card__content__stats">
          <div className="competition-card__content__stats__item">
            <h3>Prize pool</h3>
            <h5>
              <AmountView amount={data.amount} />
            </h5>
          </div>
          <div className="competition-card__content__stats__item">
            <h3>Crowdfunding</h3>
            <h5 className={`${data.donationsEnabled ? "green" : "red"}`}>
              {data.donationsEnabled ? "Yes" : "No"}
            </h5>
          </div>
          {data.numEntrants != null && (
            <div className="competition-card__content__stats__item">
              <h3>Entrants</h3>
              <h5>{data.numEntrants}</h5>
            </div>
          )}
        </div>
      </div>
      {data.community && (
        <div className="competition-card__footer">
          <Link className="footerButton" to={href()}>
            View Competition
          </Link>
        </div>
      )}
    </div>
  );
};
export default CompetitionCard;
