import React from 'react';
import { CompetitionViewProps } from 'types/Competition';
import moment from 'moment';
import { statusIconResolver, statusClassResolver } from 'services/helpers/resolvers';

import Button from './Button';
import CompetitionButton from './CompetitionButton';
import CustomTable from './Table';
import EventInfoCard from './EventInfoCard';
import PrizeCard from './PrizeCard';

// icons
import Calendar from '@iconify/icons-ion/calendar-sharp';
import Gamepad from '@iconify/icons-ion/game-controller';
import CircularProgress from './CircularProgress';

const CompetitionView = (props: CompetitionViewProps) => {
  const { competition, toggleModal } = props;

  if (!competition) return <CircularProgress lg />;

  const noStandingsMessages = [
    `There are currently no standings available, check back later!`,
  ];

  const noContributorsMessages = [
    `Be the first to donate and see your name here!`,
    `Give your favourite players something more to play for! Contribute now!`,
    `Want to support this competition? Feel free to donate and see your name here!`,
  ];

  const noStandingsMessage = noStandingsMessages[Math.floor(Math.random() * noStandingsMessages.length)];
  const noContributorsMessage = noContributorsMessages[Math.floor(Math.random() * noContributorsMessages.length)];

  const finalizeStates = ["Active", "Created", "Completed"];

  return (
    <div className="competition">
      <div className="competition__header">
        <div className="competition__header__title">
          <img src={competition.thumbnail || '/no-image.jpg'} alt="" className="competition__header__title__image" />
          <div className="competition__header__title__text">
            <h1 className={competition.name.length > 60 ? 'text-sm' : (competition.name.length > 80 ? 'text-xs' : '')}>{competition.name}</h1>
          </div>
        </div>
        <div className="competition__header__title__button">
          <CompetitionButton status={competition.status} URL={competition.externalURL} type="smashgg" />
          {
            competition.isAdmin
            && finalizeStates.some(x => x === competition.status)
            && <Button
              label="Finalize"
              fullWidth
              outline
              onClick={() => toggleModal(true, 'finalize')}
            />
          }
        </div>
      </div>
      <div className="competition__header__info">
        <EventInfoCard
          icon={statusIconResolver(competition.status)}
          iconClass={statusClassResolver(competition.status)}
          text={competition.status}
        />
        <EventInfoCard
          icon={Calendar}
          text={`
                ${moment(competition.startDateTime).format("ddd MMM Do YYYY")}
              `}
        />
        <EventInfoCard
          icon={Gamepad}
          text={competition.videoGame.displayName}
        />
      </div>
      <div className="competition__content">
        <div className="competition__content__prizes">
          <div className="splits">
            <h1 className="subheading">Prizes</h1>
            <div className="competition__content__prizes__cards-wrapper">
              <div className="competition__content__prizes__cards-wrapper__cards">
                {competition.payouts.map(
                  (p, i) => <PrizeCard key={i} placement={p.position} title={p.title} amountDisplay={<span>{p.amount.display}<span className="currency">{p.amount.currency}</span></span>} />
                )}
              </div>
            </div>
          </div>
          <div className="prize">
            <h1 className="subheading">Prize Pool</h1>
            <div className="competition__content__prizes__prize-pool">
              <div className="competition__content__prizes__prize-pool__card">
                <div className="competition__content__prizes__prize-pool__card-amount">
                  {competition.amount.display}<span>{competition.amount.currency}</span>
                </div>
                <div className={`competition__content__prizes__prize-pool__card-button ${["Finished", "Processing", "Archived"].some(x => x === competition.status) ? 'disabled' : ''
                  }`}
                  onClick={() => toggleModal(true, 'donate')}
                >
                  donate
                    </div>
              </div>
            </div>
          </div>
        </div>
        <div className="competition__content__tables">
          <div className="competition__content__tables__standings">
            <h1 className="subheading">Standings</h1>
            <div className="competition__content__tables__standings-table">
              <CustomTable tableHeaders={props.standingsTableHeaders} tableData={props.standingsTableData} />
              {
                props.standingsTableData.length === 0 && <h1 className="competition__no-table-data">{noStandingsMessage}</h1>
              }
            </div>
          </div>
          <div className="competition__content__tables__contributors">
            <h1 className="subheading">Contributors</h1>
            <div className="competition__content__tables__contributors-table">
              <CustomTable tableHeaders={props.contributorsTableHeaders} tableData={props.contributorsTableData} />
              {
                competition.contributors.length === 0 && <h1 className="competition__no-table-data">{noContributorsMessage}</h1>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompetitionView;