import React from "react";
import { InlineIcon } from "@iconify/react";
import { Link } from "react-router-dom";

import { Winnings } from "types/Profile";

import Modal from "components/Modal";

import DLIcon from "@iconify/icons-uil/download-alt";

type ClaimFormsModalProps = {
  visible?: boolean;
  winning?: Winnings;
  toggleModal?: Function;
};

const ClaimFormsModal = (props: ClaimFormsModalProps) => {
  if (!props.winning) return <></>;

  return (
    <Modal visible={props.visible} toggleModal={props.toggleModal}>
      <div className="claim-forms-modal__wrapper p-2">
        <div className="container__header">
          <h1>Submitted Forms</h1>
          <h3>
            A list of forms submitted by {props.winning.user.displayName} for
            the competition {props.winning.title}
          </h3>
        </div>
        <div className="claim-forms-modal__forms">
          <div className="claim-forms-modal__forms__header">
            <h1 className="orange bold">Required forms</h1>
          </div>
          <div className="claim-forms-modal__forms__wrapper">
            {requiredFormsResolver(props.winning)}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ClaimFormsModal;

const requiredFormsResolver = (data: Winnings) => {
  return (
    <React.Fragment>
      {data.formStates.map((form, k) => {
        return (
          <div
            key={k}
            className={`claim-forms-modal__forms__item__status__value mb-2`}
          >
            <span className="mr-2 bold white">{formName(form.name)}</span>
            <span className={`${form.state == 0 ? "red" : "green"}`}>
              {form.state == 0 ? "NOT SUBMITTED" : "SUBMITTED"}
            </span>
            {form.state == 1 && (
              <Link to={`/api/forms/${data.id}/${form.name}.pdf`} className="claim-forms-modal__forms__item__download ml-2" target="blank">
                Download <InlineIcon icon={DLIcon} />
              </Link>
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
};

const formName = (type: string): string => {
  switch (type) {
    case "general":
      return "General";
    case "irstax":
      return "Tax";
  }
};
