import React from "react";

import Footer from "components/Footer";
import Team from "components/Vanity/Team";
import Parallax from "components/Parallax";

import { Link } from "react-router-dom";

const About = () => {
  return (
    <div>
      <div className="about">
        <Parallax url="/context-banner-new.png" />
        <div className="about__wrapper">
          <div className="about__header">
            <h1>About</h1>
            <h3>Learn more about Context.gg</h3>
          </div>
          <div className="about__content">
            <div className="about__content__section section-1">
              <h1>What is Context?</h1>
              <p>
                Founded in 2019, Context empowers tournament organisers &
                esports communities to solve business problems by providing a
                vast suite of tools.
              </p>
              <br />
              <p>
                Our solutions aim to combat issues such as revenue generation,
                prize pool payouts, legal requirements & participant retention.
              </p>
              <br />
              <p>
                If you have any queries or concerns about the platform, feel
                free to reach out to via our{" "}
                <Link to="/contact">Contact Form</Link>, or join our{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://discord.gg/c6BR2UT"
                >
                  Discord Server
                </a>
                .
              </p>
            </div>
            <div className="about__content__section section-2">
              <h1>Meet the team</h1>
              <Team />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default About;
