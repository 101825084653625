import { CheckIcon } from "@heroicons/react/solid";
import React from "react";
import { PrizeClaimComponentProps } from "types/Wizard";
import DisplayDate from "utils/DisplayDate";

function OrdinalSuffixOf(i: any) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

const ClaimWinnings = ({
  context,
  next,
  wizardError,
  sendState,
}: PrizeClaimComponentProps) => {
  const { prize } = context;

  return (
    <div className="space-y-4">
      <div>
        <h1 className="text-3xl font-bold">Claim Winnings</h1>
        <p className="text-neutral-300">
          Review your winnings then click finish to collect your payment
        </p>
      </div>
      <div>
        <div className="relative w-full rounded-xl border border-green-600 p-5">
          <div className="absolute top-0 right-0 flex h-full w-full items-center justify-center rounded-xl from-context-lighter text-center text-11xl">
            <span className="opacity-5">{OrdinalSuffixOf(prize.rank)}</span>
          </div>
          <div className="flex items-center gap-x-4">
            <div className="flex-none">
              <CheckIcon className="h-7 w-7 rounded-full bg-green-600" />
            </div>
            <div className="flex flex-1 items-center gap-x-2">
              <div>
                <img
                  src={
                    prize.community.logo != ""
                      ? prize.community.logo
                      : "/no-image.jpg"
                  }
                  className="h-16 w-16 rounded"
                  alt={`${prize.community.name}'s logo`}
                />
              </div>
              <div className="flex flex-1 flex-col font-bold">
                <h2 className="text-xl">{prize.label}</h2>
                <p className="text-neutral-300">{prize.title}</p>
              </div>
            </div>
            <div className="">
              <span className="text-3xl font-bold">{prize.amount.display}</span>{" "}
              <span className="text">{prize.amount.currency}</span>
            </div>
          </div>
          <div className="mt-1 pl-12">
            <div className="flex gap-x-6">
              <div className="flex-initial flex-col">
                <div className="font-bold">Community</div>
                <div className="text-neutral-300">{prize.community.name}</div>
              </div>
              <div className="flex-initial">
                <div className="font-bold">Date</div>
                <div className="text-neutral-300">
                  {DisplayDate(prize.createdAt)}
                </div>
              </div>
              <div className="flex-initial">
                <div className="font-bold">Position</div>
                <div className="text-neutral-300">
                  {OrdinalSuffixOf(prize.rank)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimWinnings;
