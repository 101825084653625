import { useState } from "react";
import { WithdrawCreateInput } from "types/Profile";
import { emailRegex } from "utils";
import { Icon } from "@iconify/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import AmountView from "components/AmountView";
import Button from "components/Button";
import ErrorComponent from "components/Error";
import Parallax from "components/Parallax";
import PaypalAccounts from "components/PaypalAccounts";
import WithdrawalBreakdown from "components/WithdrawBreakdown";

import WithdrawIcon from "@iconify/icons-uil/money-withdrawal";
import ErrorIcon from "@iconify/icons-uil/exclamation-circle";
import CircularProgress from "components/CircularProgress";
import {
  useGetContextProfileQuery,
  useGetWithdrawQuery,
  useWithdrawCreateMutation,
} from "api/graphql";
import LoadingData from "components/LoadingData";

const Withdraw = () => {
  const nav = useNavigate();
  const location = useLocation();

  const { data: profile, isLoading: profileLoading } =
    useGetContextProfileQuery();
  const { data: withdraw, isLoading: withdrawLoading } = useGetWithdrawQuery();

  const [emailValues, setEmailValues] = useState({
    paypalEmail: "",
    paypalEmailConfirm: "",
  });
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { mutateAsync: executeWithdrawal } = useWithdrawCreateMutation();

  if (profileLoading || withdrawLoading) return <LoadingData />;
  if (!withdraw) return <></>;

  const handleWithdrawal = () => {
    if (loading || success || errors.length > 0) {
      return;
    }

    setLoading(true);
    setError(null);

    // validate emails
    if (!selectedAccount) {
      if (!emailValues.paypalEmail || !emailValues.paypalEmailConfirm) {
        setErrors([
          ...errors,
          { id: "paypalEmail", message: "This is a required field" },
        ]);
        setLoading(false);
        return;
      }
      if (!emailRegex.test(emailValues.paypalEmail)) {
        setErrors([...errors, { id: "paypalEmail", message: "Invalid email" }]);
        setLoading(false);
        return;
      }
      if (emailValues.paypalEmail !== emailValues.paypalEmailConfirm) {
        setErrors([
          ...errors,
          { id: "paypalEmail", message: "Emails don't match" },
          { id: "paypalEmailConfirm", message: "Emails don't match" },
        ]);
        setLoading(false);
        return;
      }
      // checks done, execute withdrawal
    }

    const input: WithdrawCreateInput = {
      email: selectedAccount ? selectedAccount.email : emailValues.paypalEmail,
      winnings: withdraw.withdraw.winnings.map((x) => x.id),
    };

    executeWithdrawal(
      { input },
      {
        onSettled: (data, error) => {
          if (data.withdrawCreate) {
            setSuccess(true);
            setLoading(false);
            setTimeout(() => {
              nav("/profile");
            }, 2000);
          }

          if (error) {
            setLoading(false);
            setError(error);
          }
        },
      }
    );
  };

  const resetErrors = (fieldName?: string) => {
    if (fieldName) {
      const index = errors.findIndex((x) => x.id === fieldName);
      if (index === -1) return;
      errors.splice(index, 1);
      setErrors(errors);
      return;
    }
    setErrors([]);
  };

  const handleChange = (e) => {
    resetErrors(e.target.id);
    switch (e.target.id) {
      case "paypalEmail":
      case "paypalEmailConfirm":
        setSelectedAccount(null);
        setEmailValues({
          ...emailValues,
          [e.target.id]: e.target.value,
        });
        break;
      default:
        setErrors([]);
        setSelectedAccount(
          profile.profile.connectedProfiles.find((x) => x.id === e.target.value)
        );
        break;
    }
  };

  const resolveWinningsItems = (data: any) => {
    return data.map((w) => ({
      amount: w.amount,
      date: moment(w.createdAt).toDate(),
      label: w.title,
    }));
  };

  return (
    <div>
      <Parallax url="/placeholder-banner.png" />
      {withdraw.withdraw.winnings.length !== 0 ? (
        <div className="withdraw__wrapper">
          <div className="withdraw__header">
            <div className="withdraw__header__icon__wrapper">
              <Icon className="withdraw__header__icon" icon={WithdrawIcon} />
            </div>
            <div className="withdraw__header__text">
              <h1>Withdraw Balance</h1>
              <h3>Thanks for using Context!</h3>
            </div>
          </div>
          <div className="withdraw__content__wrapper">
            <div className="withdraw__content__total">
              <h3 className="withdraw__content__total__header">
                Your withdrawal total is
              </h3>
              <div className="withdraw__content__total__amount">
                {withdraw.withdraw.accounts.length > 0 &&
                  withdraw.withdraw.accounts.map((amount, k) => (
                    <AmountView key={k} amount={amount} />
                  ))}
              </div>
            </div>
            <div className="withdraw__content__breakdown__wrapper">
              <div className="withdraw__content__breakdown__header">
                <h1>Breakdown</h1>
              </div>
              <WithdrawalBreakdown
                title="Winnings"
                items={resolveWinningsItems(withdraw.withdraw.winnings)}
              />
            </div>
            <div className="withdraw__content__account__wrapper">
              <div className="withdraw__content__account__header">
                <h1>Select Method</h1>
              </div>
              <PaypalAccounts
                profile={profile.profile}
                selectedAccount={selectedAccount}
                emailValues={emailValues}
                onChange={handleChange}
                errors={errors}
              />
            </div>
            <div className="withdraw__content__footer flex-justify-end flex-align-center mb-2 flex">
              {loading && <CircularProgress className="mr-2" sm inverse />}
              {success && (
                <span className="bold green mr-2">
                  Your funds have been successfully withdrawn!
                </span>
              )}
              <Button
                label="Complete Withdrawal"
                color="green"
                onClick={handleWithdrawal}
              />
            </div>
            {error && (
              <ErrorComponent
                closeable={false}
                visible
                message={error.message}
                type="WITHDRAW_CREATE"
                id={profile.profile.user.id}
                path={location.pathname}
                inverse
              />
            )}
          </div>
        </div>
      ) : (
        <div className="withdraw__wrapper withdraw__wrapper--red">
          <div className="withdraw__header">
            <div className="withdraw__header__icon__wrapper">
              <Icon className="withdraw__header__icon red" icon={ErrorIcon} />
            </div>
            <div className="withdraw__header__text">
              <h1>Withdraw Error</h1>
              <h3>No avaiable balance to withdraw</h3>
            </div>
          </div>
          <div className="withdraw__content__wrapper">
            <div className="withdraw__content__error">
              <p>
                Unfortunately you do not have an available balance ready to
                withdraw at this time. This could be due to the following
                reasons:
                <ul>
                  <li>
                    Your winnings are from an event that was run before December
                    7th 2020.
                  </li>
                  <li>
                    You have outstanding winnings from events after this date
                    which you have yet to{" "}
                    <Link to="/profile">
                      <a>claim</a>
                    </Link>
                    .
                  </li>
                </ul>
                View the current Context{" "}
                <Link to="/competitions">
                  <a>Competitions</a>
                </Link>{" "}
                list and enter to earn your share of some prize money!
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Withdraw;
