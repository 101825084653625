import React from "react";
import { StaffMember } from "types/Community";
import StaffMemberItem from "./StaffMember";

const StaffList = (props: any) => {
  return (
    <>
      <div className="community-staff__list__header">
        <h1>Staff Members</h1>
      </div>
      <div className="community-staff__list">
        {props.staff.map((s, k) => (
          <StaffMemberItem key={k} staff={s} />
        ))}
      </div>
    </>
  );
};
export default StaffList;
