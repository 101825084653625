import React from "react";
import moment from "moment";

import Modal from "components/Modal";
import { Winnings } from "types/Profile";
import { Link } from "react-router-dom";
import {
  winningsStateColorResolver,
  winningsStateDisplayResolver,
} from "services/helpers/resolvers";

type WinningModalProps = {
  winning: Winnings;
  visible: boolean;
  toggleModal: Function;
};

const WinningModal = (props: WinningModalProps) => {
  if (!props.winning) return <></>;
  return (
    <Modal visible={props.visible} toggleModal={props.toggleModal}>
      <div className="winning-modal">
        <div className="winning-modal__header">
          <h1>
            User Winnings{" "}
            <span className="last-updated">
              Updated {moment(new Date(props.winning.updatedAt)).fromNow()}
            </span>
          </h1>
          <h3>Specific information relating to this user's winnings</h3>
        </div>
        <div className="winning-modal__links">
          <Link
            to={`/communities/${props.winning.community.id}/competitions/${props.winning.fromId}`}
          >
            Competition link
          </Link>
        </div>
        <div className="winning-modal__content">
          <div className="winning-modal__content__section winning-info">
            <h1>Winning Information</h1>
            <table className="winning-modal__content__section__table">
              <tbody className="winning-modal__content__section__table__body">
                <tr>
                  <td>ID</td>
                  <td>{props.winning.id}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>
                    {props.winning.amount.currency}
                    {props.winning.amount.display}
                  </td>
                </tr>
                <tr>
                  <td>Current State</td>
                  <td>
                    <span
                      className={`${winningsStateColorResolver(
                        props.winning.state
                      )}`}
                    >
                      {winningsStateDisplayResolver(props.winning.state)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Expiry</td>
                  <td>
                    {props.winning.expiresAt ? (
                      `${new Date(
                        props.winning.expiresAt
                      ).toDateString()} ${new Date(
                        props.winning.expiresAt
                      ).toLocaleTimeString()}`
                    ) : (
                      <span>N/A</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Withdrawal Email</td>
                  <td>{props.winning.withdrawalEmail || <span>N/A</span>}</td>
                </tr>
                <tr>
                  <td>Transaction Id</td>
                  <td>{props.winning.transactionId || <span>N/A</span>}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="winning-modal__content__section competition-info">
            <h1>Competition Information</h1>
            <table className="winning-modal__content__section__table">
              <tbody className="winning-modal__content__section__table__body">
                <tr>
                  <td>ID</td>
                  <td>{props.winning.fromId}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{props.winning.title}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="winning-modal__content__section winner-info">
            <h1>Winner Information</h1>
            <table className="winning-modal__content__section__table">
              {props.winning.user ? (
                <tbody className="winning-modal__content__section__table__body">
                  <tr>
                    <td>Context ID</td>
                    <td>{props.winning.user.id}</td>
                  </tr>
                  <tr>
                    <td>Username</td>
                    <td>{props.winning.user.displayName}</td>
                  </tr>
                  <tr>
                    <td>External Profile ID</td>
                    <td>{props.winning.user.profileID}</td>
                  </tr>
                </tbody>
              ) : (
                <tbody className="winning-modal__content__section__table__body">
                  <tr>
                    <td>Profile is not connected to Context yet.</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WinningModal;
