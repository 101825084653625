import React, { useState } from 'react';
import { currencies } from 'utils';

import AmountSelect from 'components/AmountSelect';

const DonateAmount = (props) => {

  const { context, next, sendState, loading, wizardStates, wizardError } = props;

  const [values, setValues] = useState({
    currency: 'AUD',
    amount: '5',
  })

  const handlePrizePoolChange = (e) => {
    wizardError(null);
    setValues({
      ...values,
      amount: e.target.value
    });
  };

  const handleCurrencyChange = (selected, info) => {
    setValues({
      ...values,
      currency: selected.value,
    });
  }

  next(async () => {
    if (Number(values.amount) <= 0) {
      return wizardError({ message: "Donation amount must be greater than 0" });
    }
    if (isNaN(Number(values.amount))) {
      return wizardError({ message: "Amount must be a number" });
    }
    sendState('contribution', values);
    return true;
  });

  const pre = ['5', '10', '15', '30', '50', '100', '200', '250'];

  return (
    <div className="donate-wizard__step">
      <h1>Amount</h1>
      <h3>Enter your ideal contribution amount.</h3>
      <AmountSelect
        handleInputChange={handlePrizePoolChange}
        handleCurrencyChange={handleCurrencyChange}
        handleToggleButtonChange={handlePrizePoolChange}
        values={{ currency: values.currency, amountInputValue: values.amount }}
        currencies={currencies}
        amounts={pre}
      />
    </div>
  );
};
export default DonateAmount;