import React from "react";
import { Icon } from "@iconify/react";

import CheckIcon from "@iconify/icons-cil/check-circle";
import Button from "components/Button";
import CompetitionSuggestions from "./CompetitionSuggestions";
import ErrorComponent from "components/Error";
import { useLocation, useParams } from "react-router-dom";

const WantFries = (props) => {
  const { id } = useParams();
  const location = useLocation();

  if (!props.error) {
    return (
      <div className="want-fries">
        <div className="want-fries__container">
          <div className="want-fries__header">
            <div className="want-fries__header__icon">
              <Icon icon={CheckIcon} />
            </div>
            <div className="want-fries__header__title">
              <h1>Order Confirmed</h1>
              <h3>Thank you for using Context!</h3>
            </div>
          </div>
          <div className="want-fries__divider"></div>
          <div className="want-fries__content">
            <div className="want-fries__content__header">
              <h1>Before you go, check these out</h1>
              <h3>
                If you are feeling generous, here are a few active Context
                competitions you can donate to!
              </h3>
            </div>
            <CompetitionSuggestions competitions={props.competitions} />
          </div>
          <div className="want-fries__divider"></div>
          <div className="want-fries__footer">
            <Button
              // label={props.order && props.order.action && props.order.action.text}
              label="Return to Context"
              color="green"
              onClick={props.handleClick}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="want-fries error">
        <div className="want-fries__container">
          <div className="want-fries__header">
            <div className="want-fries__header__title">
              <h1>Error Fetching Order</h1>
            </div>
          </div>
          <div className="want-fries__divider"></div>
          <div className="want-fries__content">
            <ErrorComponent
              id={id}
              type="OrderComplete"
              visible={true}
              closeable={false}
              message={props.error.message}
              path={location.pathname}
            />
          </div>
        </div>
      </div>
    );
  }
};
export default WantFries;
