type TestimonialProps = {
  index: number
  name: string
  // the text under their name
  biline: string
  avatarURL: string
  message: string
  twitter?: string
  refFunc?: Function
}

const Testimonial = (props: TestimonialProps) => {
  return (
    <div
      className="testimonial"
      ref={ref => props.refFunc(ref, props.index)}
    >
      <div className="testimonial__header">
        <div className="testimonial__header__image">
          <img src={props.avatarURL} alt="Profile picture" />
        </div>
        <div className="testimonial__header__text">
          <h1>{props.name} {props.twitter && <a target="_blank" href={props.twitter}><img src="/twitter-logo.png" alt="Twitter Logo" /></a>}</h1>
          <h3>{props.biline}</h3>
        </div>
      </div>
      <div className="testimonial__content">
        <span>{props.message}</span>
      </div>
    </div>
  );
};
export default Testimonial;