import { Route, Routes } from "react-router-dom";
import Invite from "./Invite";

const InviteRoutes = () => {
  return (
    <Routes>
      <Route path="/:id" element={<Invite />} />
    </Routes>
  );
};

export default InviteRoutes;
