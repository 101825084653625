import React from "react";
import { Link } from "react-router-dom";

const Player = () => {
  return (
    <div className="faq-tabs">
      <div className="mt-2">
        <div className="help-page__content__section__faq__questions">
          <div className="help-page__content__section__faq__questions-item">
            <h1>How do I withdraw my winnings?</h1>
            <p>
              This video details how to withdraw your winnings <br />
              <div className="help-page__content__section__faq__questions-item__video-container">
                <iframe
                  className="help-page__content__section__faq__questions-item__video-container__video"
                  src="https://www.youtube-nocookie.com/embed/xjlO5Irh5yk"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                />
              </div>
            </p>
          </div>
          <div className="help-page__content__section__faq__questions-item">
            <h1>How do I link my start.gg account?</h1>
            <p>
              This video details how to link your start.gg account <br />
              <div className="help-page__content__section__faq__questions-item__video-container">
                <iframe
                  className="help-page__content__section__faq__questions-item__video-container__video"
                  src="https://www.youtube-nocookie.com/embed/wuBBD-PbMqg"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                />
              </div>
            </p>
          </div>
          <div className="help-page__content__section__faq__questions-item">
            <h1>Why can't I see my winnings on my profile?</h1>
            <p>
              In order to see your winnings, you must first connect your
              start.gg account to your Context.gg profile. This can be done{" "}
              <a href="/profile/external-profiles">on your profile.</a>
            </p>
          </div>
          <div className="help-page__content__section__faq__questions-item">
            <h1>How long will it take to get the money I withdrew?</h1>
            <p>You should receive your money instantly.</p>
          </div>
          <div className="help-page__content__section__faq__questions-item">
            <h1>
              I no longer have access to the PayPal email I've added, what can I
              do?
            </h1>
            <p>
              You can add another PayPal email in your External Profiles
              section.
            </p>
          </div>
          <div className="help-page__content__section__faq__questions-item">
            <h1>Still looking for an answer?</h1>
            <p>
              Get in contact with us <Link to="/contact">here.</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Player;
