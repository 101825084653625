import React, { useState, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";

import ErrorComponent from "components/Error";

import { ChallengermodeForm, SmashggForm } from "../Forms";
import { useLocation } from "react-router-dom";

const ConfigureStep = ({
  next,
  sendState,
  loading,
  wizardStates,
  wizardError,
}) => {
  const location = useLocation();
  const competitionProvider = wizardStates.provider?.name || "smashgg";

  const validator = new SimpleReactValidator({
    messages: {
      url: "A valid URL must be entered.",
    },
  });

  const [values, setValues] = useState({
    url: "",
    tournamentId: "",
    eventId: "",
  });

  const [battlefyValues, setBattlefyValues] = useState({
    url: "",
    tournamentId: "",
    stageId: "",
  });

  const [challongeValues, setChallongeValues] = useState({
    url: "",
    tournamentId: "",
    subdomain: "",
    tournamentUrl: "",
  });

  const [challengermodeValues, setChallengermodeValues] = useState({
    tournamentId: "",
    spaceId: "",
  });

  const [sixmansValues, setSixmansValues] = useState({
    seasonId: 37,
    regionId: "",
    rankId: "",
  });

  const handleSelect = (selected, info) => {
    wizardError(null);
    switch (info.name) {
      case "seasonId":
        return setSixmansValues({
          seasonId: selected.value,
          regionId: "",
          rankId: "",
        });
      case "regionId":
        return setSixmansValues({
          ...sixmansValues,
          regionId: selected.value,
          rankId: "",
        });
      case "rankId":
        return setSixmansValues({
          ...sixmansValues,
          rankId: selected.value,
        });
    }
  };

  next(async () => {
    loading(true);

    if (!validator.allValid()) {
      let errors = [];
      const validationErrors = await validator.getErrorMessages();
      for (let error in validationErrors) {
        if (!validationErrors[error]) continue;
        if (error === "event") {
          errors = [...errors, { message: "An event must be selected" }];
          continue;
        } else if (error === "stage") {
          errors = [...errors, { message: "A bracket must be selected" }];
          continue;
        } else if (error === "tournamentId") {
          errors = [
            ...errors,
            { message: `There was an error fetching your tournament` },
          ];
          console.log(challongeValues);
          continue;
        } else if (error === "regionId") {
          errors = [...errors, { message: "A region must be selected" }];
          continue;
        } else if (error === "rankId") {
          errors = [...errors, { message: "A rank must be selected" }];
          continue;
        }
        errors = [...errors, { message: validationErrors[error] }];
      }
      wizardError(errors[0]);
      loading(false);
      return false;
    }

    switch (competitionProvider) {
      case "smashgg":
        const smashggConfig = {
          url: values.url,
          tournamentId: values.tournamentId,
          eventId: Number(values.eventId),
        };
        sendState("config", smashggConfig);
        return true;
      case "sixmans":
        sendState("config", sixmansValues);
        return true;
      case "battlefy":
        sendState("config", battlefyValues);
        return true;
      case "challonge":
        sendState("config", challongeValues);
        return true;
      case "challengermode":
        sendState("config", challengermodeValues);
        return true;
      default:
        wizardError({ message: `Type of ${competitionProvider} not found` });
        return false;
    }
  });

  switch (competitionProvider) {
    case "smashgg":
      return (
        <SmashggForm
          type={competitionProvider}
          validator={validator}
          values={values}
          updateValues={(v) => setValues(v)}
          error={wizardError}
          loading={loading}
        />
      );
    // case "sixmans":
    //   return (
    //     <SixmansForm
    //       type={competitionProvider}
    //       validator={validator}
    //       sixmansValues={sixmansValues}
    //       handleSelect={handleSelect}
    //     />
    //   );
    // case "battlefy":
    //   return (
    //     <BattlefyForm
    //       type={competitionProvider}
    //       validator={validator}
    //       values={battlefyValues}
    //       updateValues={(v) => setBattlefyValues(v)}
    //       error={wizardError}
    //       loading={loading}
    //     />
    //   );
    // case "challonge":
    //   return (
    //     <ChallongeForm
    //       type={competitionProvider}
    //       validator={validator}
    //       values={challongeValues}
    //       updateValues={(v) => setChallongeValues(v)}
    //       error={wizardError}
    //       loading={loading}
    //     />
    //   );
    case "challengermode":
      return (
        <ChallengermodeForm
          type={competitionProvider}
          validator={validator}
          values={challengermodeValues}
          updateValues={(v) => setChallengermodeValues(v)}
          error={wizardError}
          loading={loading}
        />
      );
    default:
      return (
        <ErrorComponent
          closeable={false}
          visible={true}
          message={`Provider of: ${competitionProvider}; was not found`}
          type="COMPETITION_TYPE_NOT_FOUND"
          id={`id: router.query.communityId`}
          path={location.pathname}
          inverse
        />
      );
  }
};
export default ConfigureStep;
