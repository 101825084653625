import React from "react";

const LoadingData = () => {
  return (
    <div className="-mt-16 flex h-screen flex-col items-center justify-center gap-y-8 py-12 px-4 text-white sm:px-6 lg:px-8">
      <div className="flex rounded-lg">
        <img src="/context-logo-nodot.png" />
        <img className="animate-bounce" src="/context-dot.png" />
      </div>
    </div>
  );
};

export default LoadingData;
