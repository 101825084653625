import { CalendarIcon } from "@heroicons/react/outline";
import FormInput from "components/FormInputs/FormInput";
import { UploadWithCrop } from "components/Upload";
import { useFormikContext } from "formik";
import React from "react";

const EventDetails = () => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <div className="space-y-5">
      <div>
        <h1 className="text-2xl font-semibold text-neutral-100">
          Team Details
        </h1>
        <p className="text-neutral-400">Enter the details for your team</p>
      </div>
      <div className="space-y-4 rounded-md shadow-sm sm:grid sm:grid-cols-4 sm:gap-x-4 sm:gap-y-2 sm:space-y-0">
        <div className="col-span-2 row-start-1">
          <FormInput
            name="team.name"
            placeholder="Team Name"
            label="Team Name"
          />
        </div>
        <div className="col-span-4 row-start-2">
          <UploadWithCrop
            onChange={(fileId: string) => {
              setFieldValue("team.logo", fileId);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
