import { useState } from "react";

// components
import CustomSelect from "components/Select";
import FileUpload from "components/FileUpload";
import Input from "components/Input";
import { InlineIcon } from "@iconify/react";

import QuestionIcon from "@iconify/icons-uil/question-circle";

// styles
import SelectNewStyle from "styles/Select/SelectNewStyle";
import SelectErrorStyle from "styles/Select/SelectErrorStyle";
import Switch from "components/Switch";
import { useUploadFileMutation } from "api/graphql";

const Begin = (props) => {
  const { context, next, sendState, loading, wizardStates, wizardError } =
    props;

  const {
    data: uploadLogoData,
    mutateAsync: uploadLogoAsync,
    isLoading: uploadLogoIsLoading,
    error: uploadLogoError,
  } = useUploadFileMutation();
  const {
    data: uploadBannerData,
    mutateAsync: uploadBannerAsync,
    isLoading: uploadBannerIsLoading,
    error: uploadBannerError,
  } = useUploadFileMutation();

  const [formError, setFormError] = useState({
    // id of the component to make things easier to identify
    where: "",
    message: "",
  });

  const [communityInputs, setCommunityInputs] = useState({
    name: "",
    regions: null,
    operationRegion: null,
    paymentCountry: null,
    logo: null,
    banner: null,
    donationEnabled: true,
  });

  // cb func for select components
  const onChange = (selected, info) => {
    setFormError(null);
    // switches on the id of the element
    switch (info.name) {
      case "region":
        return setCommunityInputs({ ...communityInputs, regions: selected });
      case "payment-country":
        return setCommunityInputs({
          ...communityInputs,
          paymentCountry: selected,
        });
    }
  };

  const resolveCdnUrl = (
    fileId: string,
    fileName: string,
    type: string
  ): string => {
    const base = "https://ik.imagekit.io/ctx/ik-seo/tr:n-";
    const index = fileId.lastIndexOf(".")
    const fileExtension = fileId.substring(index+1);
    const path = fileId.substring(0, index);
    return `${base}${type}/${path}/${type}.${fileExtension}`;
  };

  const handleUploadBanner = async (e) => {
    const data = await uploadBannerAsync({
      file: e.target.files[0],
      type: "banner",
    });

    const url = resolveCdnUrl(
      data.fileUpload.id,
      data.fileUpload.name,
      "banner"
    );

    setCommunityInputs({
      ...communityInputs,
      banner: url,
    });
  };

  const handleUploadLogo = async (e) => {
    const data = await uploadLogoAsync({
      file: e.target.files[0],
      type: "logo",
    });

    const url = resolveCdnUrl(data.fileUpload.id, data.fileUpload.name, "logo");

    setCommunityInputs({
      ...communityInputs,
      logo: url,
    });
  };

  const handleChange = (e) => {
    setFormError(null);

    switch (e.target.id) {
      case "name":
        return setCommunityInputs({
          ...communityInputs,
          name: e.target.value,
        });
    }
  };

  const handleToggleChange = (e) => {
    setCommunityInputs({
      ...communityInputs,
      donationEnabled: !communityInputs.donationEnabled,
    });
  };

  next(async () => {
    return new Promise((resolve, reject) => {
      loading(true);
      // validate the form
      if (!communityInputs.name) {
        setFormError({
          where: "name",
          message: "A community name must be specified",
        });
        loading(false);
        reject(false);
        return;
      }
      if (!communityInputs.regions) {
        setFormError({
          where: "region",
          message: "At least one region must be selected",
        });
        loading(false);
        reject(false);
        return;
      }
      if (!communityInputs.paymentCountry) {
        setFormError({
          where: "payment-country",
          message: "A payment country must be selected",
        });
        loading(false);
        reject(false);
        return;
      }

      // prepare our data
      const newCommunityValues = {
        name: communityInputs.name,
        logo: communityInputs.logo,
        banner: communityInputs.banner,
        donationEnabled: communityInputs.donationEnabled,
        paymentCountry: communityInputs.paymentCountry.value,
        regions: communityInputs.regions.map((x) => x.value),
      };

      resolve({ id: "begin", values: newCommunityValues });
      return;
    });
  });

  const regionOpts = [
    { value: "ASIA", label: "Asia" },
    { value: "EU", label: "Europe" },
    { value: "ME", label: "Middle East" },
    { value: "NA", label: "North America" },
    { value: "OCE", label: "Oceania" },
    { value: "SAM", label: "South America" },
  ];

  return (
    <div className="comm__begin-step">
      <div className="wizard__step-header">
        <h1>Let's get started</h1>
        <h3>
          Choose a suitable name for your community along with the region you
          operate out of.
        </h3>
      </div>
      <form className="comm__begin-step__form">
        <div className="comm__begin-step__form__item">
          <Input
            id="name"
            name="name"
            label="Community Name"
            placeholder="My super fun Community"
            onChange={(e) => handleChange(e)}
            error={formError && formError.where === "name" && formError}
          />
        </div>
        <div className="comm__begin-step__form__item">
          <CustomSelect
            id="region"
            name="region"
            label="Region(s) where you host events"
            styles={SelectNewStyle}
            value={communityInputs.regions}
            onChange={onChange}
            options={regionOpts}
            isSearchable={false}
            isMultiple={true}
            error={formError && formError.where === "region" && formError}
            errorStyles={SelectErrorStyle}
          />
        </div>
        <div className="comm__begin-step__form__item">
          <CustomSelect
            id="payment-country"
            name="payment-country"
            label={
              <span className="comm__begin-step__form__item__paypal__tooltip flex items-center">
                Payout Country
                <InlineIcon className="ml-1" icon={QuestionIcon} />
              </span>
            }
            styles={SelectNewStyle}
            value={communityInputs.paymentCountry}
            onChange={onChange}
            options={[
              { label: "United States", value: "us" },
              { label: "Australia", value: "au" },
            ]}
            isSearchable={false}
            error={
              formError && formError.where === "payment-country" && formError
            }
            errorStyles={SelectErrorStyle}
          />
        </div>
        <div className="comm__begin-step__form__item comm__begin-step__form__item__switches">
          <Switch
            before={
              <h3 className="comm__begin-step__form__item__switches__tooltip flex items-center">
                Enable Direct Donations?{" "}
                <InlineIcon
                  className="comm__begin-step__form__item__switches__tooltip__icon ml-1"
                  icon={QuestionIcon}
                />
              </h3>
            }
            onChange={handleToggleChange}
            checked={communityInputs.donationEnabled}
          />
        </div>
        <div className="comm__begin-step__form__items">
          <div className="comm__begin-step__form__items__child">
            <FileUpload
              id="logo"
              onChange={(e) => handleUploadLogo(e)}
              label="Logo image (200x200)"
              loading={uploadLogoIsLoading}
              error={uploadLogoError}
              success={uploadLogoData != null}
            />
          </div>
          <div className="comm__begin-step__form__items__child">
            <FileUpload
              id="banner"
              onChange={(e) => handleUploadBanner(e)}
              label="Banner image (1920x480)"
              loading={uploadBannerIsLoading}
              error={uploadBannerError}
              success={uploadBannerData != null}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
export default Begin;
