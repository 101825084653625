import React, { useState, useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';

import PrizePoolForm from '../Forms/PrizePoolForm';

const PrizePoolStep = (props) => {

  const { context, next, sendState, loading, wizardStates, wizardError } = props;

  const validator = new SimpleReactValidator();

  const [values, setValues] = useState({
    prizePool: '',
    currency: 'AUD',
    crowdfundingEnabled: true,
  });

  useEffect(() => {
    if (wizardStates.breakdown) {
      setValues({
        ...values,
        prizePool: String(wizardStates.breakdown.total)
      });
    };
  }, [wizardStates]);

  next(async () => {
    if (!validator.allValid()) {
      loading(false);
      wizardError({ message: 'A prize amount must be entered' });
      return false;
    };
    const state = {
      currency: values.currency,
      dollars: Number(values.prizePool),
      crowdfundingEnabled: values.crowdfundingEnabled
    };
    sendState('prize', state);
    return true;
  });

  return (
    <div className="prizepool-step">
      <div className="wizard__step-header">
        <h1>Configure your prize pool</h1>
        <h3>Specify the total prize pool you want to have your players play for</h3>
      </div>
      <PrizePoolForm
        validator={validator}
        values={values}
        updateValues={v => setValues(v)}
      />
    </div>
  );
};
export default PrizePoolStep;