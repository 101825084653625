import React, { useState, useEffect, useMemo } from "react";

let interval;

const CircularProgress = (props: any) => {
  const frame = useMemo(() => {
    return props.value + "%";
  }, [props.value]);

  return (
    <div className={`loader-wrapper ${props.className ? props.className : ""}`}>
      <div
        className={`loader
      ${props.xs ? "xs" : ""}
      ${props.sm ? "sm" : ""}
      ${props.md ? "md" : ""}
      ${props.lg ? "lg" : ""}
      ${props.inverse ? "inverse" : ""}`}
      ></div>
      {props.value ? (
        <div className="loader-wrapper__value">
          {frame}
          {props.label}
        </div>
      ) : null}
    </div>
  );
};
export default CircularProgress;
