import { Link, useLocation } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";

import CompetitionCard from "components/CompetitionCard";
import CustomTable from "components/Table";
import Button from "components/Button";
import ErrorComponent from "components/Error";
import Footer from "components/Footer";

import { useCompetitionsQuery } from "api/graphql";
import { useEffect, useState } from "react";
import { competitionTableDataResolver } from "services/helpers/resolvers";

// how many competitions we fetch
const pageSize = 15;
const tableHeaders = [
  { name: "⠀⠀⠀⠀⠀", props: { align: "center" } },
  { name: "Name", props: { align: "left" } },
  { name: "Game", props: { align: "left" } },
  { name: "Start Date", props: { align: "left" } },
  { name: "Status", props: { align: "left" } },
  { name: "Prize Pool", props: { align: "left" } },
  { name: "Crowdfunding", props: { align: "center" } },
];

const filter = {
  states: ["Created", "Completed", "Active", "Finished", "Processing"],
};

const Competitions = () => {
  const location = useLocation();
  const [after, setAfter] = useState<string | null>(null);
  const [competitions, setCompetitions] = useState([]);
  const { isFetching, error, data } = useCompetitionsQuery(
    {
      filter,
      pageSize,
      after,
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    const compsData = data?.competitions?.edges?.map(({ node }) =>
      competitionTableDataResolver(node)
    );

    if (compsData != null) {
      var newData = [...competitions, ...compsData];
      setCompetitions(newData);
    }
  }, [data]);

  if (error) {
    return (
      <div
        style={{
          width: "70vw",
          margin: "0 auto",
        }}
      >
        <ErrorComponent
          closeable={false}
          visible={true}
          message={error.message}
          type="COMPETITIONS_FETCH"
          id="competitions"
          path={location.pathname}
          inverse
        />
      </div>
    );
  }

  const featured = data?.competitionsFeatured;
  const pageInfo = data?.competitions?.pageInfo;

  const handleNextPage = () => {
    setAfter(pageInfo.endCursor);
  };

  return (
    <div>
      <div className="parallax"></div>
      <div className="competitions__content">
        {error && <span className="red">{error.message}</span>}
        <div className="competitions__content__header">
          <h1>Competitions</h1>
          <Link className="btn" to="/create/competition">
            <span className="btn__label">Create a Competition</span>
          </Link>
        </div>
        {featured && featured.length > 0 && (
          <div className="competitions__content__upcoming">
            <h4 className="subheading">Featured Competitions</h4>
            <ScrollContainer
              className="competitions__content__upcoming__items pb-2 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-ctx-orange"
              hideScrollbars={false}
            >
              {featured.map((node, k: number) => (
                <CompetitionCard key={k} data={node} />
              ))}
            </ScrollContainer>
          </div>
        )}
        <div className="competitions__content__all-comps">
          <h4 className="subheading">All Competitions</h4>
          <div className="competitions__content__all-comps__table mb-2 overflow-x-auto rounded-md bg-context-light py-2 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-ctx-orange">
            <CustomTable
              tableHeaders={tableHeaders}
              tableData={competitions}
              loading={isFetching}
            />
          </div>
          <div className="competitions__content__all-comps__load-more">
            <Button
              small
              label={
                isFetching
                  ? "Loading..."
                  : pageInfo?.hasNextPage
                  ? "Load more"
                  : "You've reached the end"
              }
              disabled={!pageInfo?.hasNextPage ?? true}
              onClick={handleNextPage}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Competitions;
