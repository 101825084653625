import { Routes, Route } from "react-router-dom";
import useGoogleAnalytics from "./lib/analyticshook";

import Error404 from "pages/Error404";
import OrderConfirmation from "pages/OrderConfirmation";
import OrderCompleted from "pages/OrderCompleted";
import Home from "pages/Home";
import About from "pages/About";
import Help from "pages/Help";
import Contact from "pages/Contact";
import GettingStarted from "pages/GettingStarted";
import Communities from "pages/Communities";
import Competitions from "pages/Competitions";
import PrivateRoute from "PrivateRoute";
import CreateRoutes from "pages/Create/CreateRoutes";
import ProfileRoutes from "pages/Profile/ProfileRoutes";
import CommunityRoutes from "pages/Community/CommunityRoutes";
import TeamRoutes from "pages/Team/TeamRoutes";
import InviteRoutes from "pages/Invite/InviteRoutes";

function App() {
  useGoogleAnalytics();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/help" element={<Help />} />
      <Route path="/get-started" element={<GettingStarted />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/competitions" element={<Competitions />} />
      <Route path="/communities" element={<Communities />} />
      <Route path="/:id/*" element={<CommunityRoutes />} />

      <Route path="/team/*" element={<TeamRoutes />} />
      <Route path="/invite/*" element={<InviteRoutes />} />

      <Route
        path="/profile/*"
        element={
          <PrivateRoute>
            <ProfileRoutes />
          </PrivateRoute>
        }
      />

      <Route
        path="/create/*"
        element={
          <PrivateRoute>
            <CreateRoutes />
          </PrivateRoute>
        }
      />

      <Route
        path="/order/completed/:id"
        element={
          <PrivateRoute>
            <OrderCompleted />
          </PrivateRoute>
        }
      />
      <Route
        path="/order/confirmation/:id"
        element={
          <PrivateRoute>
            <OrderConfirmation />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export default App;
