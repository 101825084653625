import React from "react";

import { Link } from "react-router-dom";
import { Icon, InlineIcon } from "@iconify/react";
import moment from "moment";
import Moment from "react-moment";
import { Order } from "types/Order";
import { Money } from "types/Shared";
import { CompetitionType } from "types/Competition";
import { TournamentInfoType } from "types/Tournament";
import {
  ContextProfile,
  ContextProfileConnection,
  Winnings,
  WinningsConnection,
} from "types/Profile";

// icons
import CircleTick from "@iconify/icons-uil/check-circle";
import Cross from "@iconify/icons-uil/times-circle";
import Live from "@iconify/icons-uil/video";
import Archive from "@iconify/icons-uil/archive";
import Process from "@iconify/icons-uil/process";
import Created from "@iconify/icons-uil/file-plus";
import OpenIcon from "@iconify/icons-ion/open-outline";
import Button from "components/Button";
import EuroIcon from "@iconify/icons-cil/euro";
import BritishPoundIcon from "@iconify/icons-cil/british-pound";
import DollarIcon from "@iconify/icons-cil/dollar";
import AmountView from "components/AmountView";
import { Withdraw } from "types/Withdraw";

export const placementResolver = (placement) => {
  if (!placement) return "";
  let a = placement % 10;
  let b = placement % 100;

  if (a == 1 && b !== 11) {
    return placement + "st";
  }
  if (a == 2 && b !== 12) {
    return placement + "nd";
  }
  if (a == 3 && b !== 13) {
    return placement + "rd";
  }
  return placement + "th";
};

export const colorResolver = (placement) => {
  if (!placement) return "grey";
  switch (placement) {
    case 1:
      return "gold";
    case 2:
      return "silver";
    case 3:
      return "bronze";
    default:
      return "grey";
  }
};

export const imageResolver = (game) => {
  if (!game) return "";
  switch (game) {
    case "Rocket League":
      return "url('/rocket-league.jpg')";
  }
};

export const competitionTableDataResolver = (data: any) => {
  if (!data) return {};

  const Name = () => {
    if (!data.community) {
      return <span>{data.name}</span>;
    }

    const id = data.community.alias || data.community.id;
    return (
      <Link id="name-link" to={`/${id}/competitions/${data.id}`}>
        {data.name}
      </Link>
    );
  };

  return {
    "": (
      <img
        className="mx-auto h-8 w-8 min-w-min rounded-full"
        src={data.thumbnail || "/no-image.jpg"}
        alt={`${data.name}'s Icon`}
      />
    ),
    name: <Name></Name>,
    game: data.videoGame.displayName,
    "start date": <Moment fromNow date={data.startDateTime} />,
    status: data.status,
    "prize pool": <AmountView amount={data.amount} />,
    crowdfunding: data.donationsEnabled ? (
      <InlineIcon className="table-icon tick green mx-auto" icon={CircleTick} />
    ) : (
      <InlineIcon className="table-icon red mx-auto" icon={Cross} />
    ),
  };
};

export const tournamentTableDataResolver = (data: TournamentInfoType) => {
  if (!data) return {};

  const Name = () => {
    return (
      <Link id="name-link" to={`/tournaments/${data.id}`}>
        {data.name}
      </Link>
    );
  };

  return {
    "": (
      <img
        src={data.thumbnail || "/no-image.jpg"}
        id="image"
        alt={`Icon for competition ${data.name}`}
      />
    ),
    name: <Name></Name>,
    game: data.videoGame.displayName,
    "start date": <Moment fromNow date={data.startAt} />,
    status: data.status,
  };
};

// get the prize cut of a specific placement
export const prizeBreakdownDisplayResolver = (bd: number, amount: Money) => {
  // amount is the competition amount
  // bd is the % split of the placement
  const currencySymbol = amount.display.charAt(0);
  const prizeSplit = amount.display.split("");
  prizeSplit.splice(0, 1);
  const prizeToNumber = Number(prizeSplit.join(""));
  return (
    amount.currency + currencySymbol + ((bd / 100) * prizeToNumber).toFixed(2)
  );
};

export const communityOrdersTableDataResolver = (data, toggleModal) => {
  if (!data) return {};
  return {
    Date: moment(new Date(data.createdAt)).format("Do MMM YYYY"),
    Description: (
      <span
        className="community-admin__orders__table__description"
        onClick={() => toggleModal(true, data)}
      >
        {data.description}
      </span>
    ),
    State: data.state,
    Amount: <AmountView amount={data.amount} />,
    // Action: <span className="community-admin__orders__table__action" onClick={() => toggleModal(true, data)}><Icon icon={OpenIcon} /></span>
  };
};

export const communityWinnersTableDataResolver = (data, toggleModal) => {
  if (!data) return {};

  return {
    Date: moment(new Date(data.createdAt)).format("Do MMM YYYY"),
    Status: (
      <span className={`${winningsStateColorResolver(data.state)}`}>
        {winningsStateDisplayResolver(data.state)}
      </span>
    ),
    Name: (
      <span onClick={() => toggleModal(true, "winner", data)}>
        {data.user ? data.user.displayName : "Unknown"}
      </span>
    ),
    Amount: <AmountView amount={data.amount} />,
    Expires: data.expiresAt && (
      <div className="profile-v2__wallet__winnings__expiry__container">
        {expiresColumnResolver(data)}
      </div>
    ),
    Forms:
      data.formStates && data.formStates.length > 0 && data.state > 1 ? (
        <div
          className="community-admin__winners__table__actions"
          onClick={() => toggleModal(true, "forms", data)}
        >
          <div
            className="community-admin__winners__table__actions__item"
            title="Forms"
          >
            <Icon icon={OpenIcon} />
          </div>
        </div>
      ) : null,
  };
};

const expiresColumnResolver = (data: Winnings) => {
  if (!data.enabled) return null;
  switch (data.state) {
    case 0:
    case 1:
    case 2:
    case 3:
      return (
        <span
          className="yellow bold"
          title={moment(new Date(data.expiresAt)).toString()}
        >
          Expires in {moment(new Date(data.expiresAt)).fromNow(true)}
        </span>
      );
    case 4:
    case 5:
    case 6:
  }
};

export const sixmansLeaderboardListItemResolver = (data) => {
  if (!data) return {};
  return {
    "": <img src="/sixmanslogo.png" id="image" alt="" />,
    name: (
      <Link id="name-link" to={`/6mans/competitions/${data.id}`}>
        {data.name}
      </Link>
    ),
    game: "Rocket League",
    "start date": <Moment fromNow date={data.startDateTime} />,
    status: data.status,
    "prize pool": <AmountView amount={data.amount} />,
    crowdfunding: data.donationsEnabled ? (
      <InlineIcon className="table-icon tick green" icon={CircleTick} />
    ) : (
      <InlineIcon className="table-icon  red" icon={Cross} />
    ),
  };
};

export const standingsTableDataResolver = (data) => {
  if (!data) return console.error("No competition data");
  return data.standings.map((s) => {
    return {
      position: s.position || "",
      name: s.name,
      players: s.players.map((x) => x.name + " "),
      prize: s.prize ? <AmountView amount={s.prize} /> : "",
    };
  });
};

export const getSixmansLeaderboardInfoFromId = (text) => {
  const split = text.split(" ");
  return {
    regionId: split[0],
    regionDisplay: regionResolver(split[0]),
    rankId: split[2],
  };
};

export const sixmansStandingsTableDataResolver = (data) => {
  if (!data) return console.error("No sixmans competition data");
  return data.standings.map((rank) => {
    const prize = data.payouts.find((x) => x.position === rank.position);
    return {
      position: rank.position || "",
      player: (
        <a href={`https://rl6mans.com/profile/${rank.name}`}>{rank.name}</a>
      ),
      wins: rank.wins,
      losses: rank.losses,
      elo: rank.elo,
      prize: prize ? <AmountView amount={prize.amount} /> : "",
    };
  });
};

export const contributorsTableDataResolver = (data) => {
  if (!data) return console.error("No contributor data");
  return data.map((contributor) => {
    return {
      position: contributor.position,
      name: contributor.user.displayName || contributor.user.username,
      amount: <AmountView amount={contributor.amount} />,
    };
  });
};

export const statusIconResolver = (status) => {
  if (!status) return "";
  switch (status.toString().toLowerCase()) {
    case "active":
      return Live;
    case "finished":
      return CircleTick;
    case "created":
      return Created;
    case "completed":
      return CircleTick;
    case "archived":
      return Archive;
    case "processing":
      return Process;
    default:
      return "";
  }
};

export const statusClassResolver = (status) => {
  if (!status) return "";
  switch (status.toString().toLowerCase()) {
    case "active":
      return "rose";
    case "finished":
      return "blue";
    case "created":
      return "cyan";
    case "completed":
      return "green";
    case "archived":
    case "processing":
      return "yellow";
    case "yes":
      return "green";
    case "no":
    case "failed":
      return "red";
    default:
      return "";
  }
};

export const regionResolver = (regionId) => {
  switch (regionId) {
    case "NA":
      return "North America";
    case "EU":
      return "Europe";
    case "OCE":
      return "Oceania";
    case "ASIA":
      return "Asia";
    case "ME":
      return "Middle East";
    case "SAM":
      return "South America";
    default:
      return regionId;
  }
};

export const feeNameResolver = (feeId) => {
  switch (feeId) {
    case "paypal_transaction":
      return "PayPal Transaction Fee";
    case "paypal_payout":
      return "PayPal Payout Fee";
    case "ach_payout":
      return "ACH Payout Fee";
    case "service5":
      return "Context Service Fee";
    case "service3":
      return "Context Service Fee";
    default:
      return feeId;
  }
};

export const skuDisplayResolver = (skuName: string) => {
  switch (skuName) {
    case "competition_donation":
      return "Competition Donation";
    case "community_donation":
      return "Community Donation ";
    default:
      return skuName.charAt(0).toUpperCase() + skuName.slice(1);
  }
};

export const amountResolver = (amount, key, modifier?) => {
  return (
    <tr className={`${modifier ? modifier : ""}`} key={key}>
      <td>Amount</td>
      <td>
        <AmountView amount={amount} />
      </td>
    </tr>
  );
};

export const orderItemArrayResolver = (initialProp, array, key): Array<any> => {
  let out = [];
  // make our separator first
  out.push(
    <tr className="heading" key={key}>
      <td>{initialProp.charAt(0).toUpperCase() + initialProp.slice(1)}</td>
      <td></td>
    </tr>
  );
  array.map((item, k) => {
    out.push(
      Object.keys(item).map((prop) => {
        if (prop === "__typename") return;
        if (prop === "amount") return amountResolver(item[prop], k, "small");
        return (
          <tr className="small" key={k + key}>
            <td>{prop}</td>
            <td>{feeNameResolver(item[prop])}</td>
          </tr>
        );
      })
    );
  });
  return out;
};

export const paymentTypeLabelResolver = (
  type: string,
  amount?: Money,
  available?: Money
): any => {
  const display = (label: string, amount: Money, available?: Money) => {
    return (
      <React.Fragment>
        <span className="pr-1">{label}:</span>
        <span className="white">
          {amount.amount >= available.amount
            ? available.display
            : amount.display}
        </span>
        <span className="grey-dark">
          {available && "/" + available.display}
        </span>
      </React.Fragment>
    );
  };
  switch (type) {
    case "paypal_order":
      return (
        <React.Fragment>
          <span className="pr-1">PayPal:</span>
          <span className="white">{amount.display}</span>
        </React.Fragment>
      );
    case "context_deposit":
      return display("Your account", amount, available);
    case "community_deposit":
      return display("Withdrawable funds", amount, available);
    case "context_communityfund":
      return display("Community funds", amount, available);
  }
};

export const gameBannerResolver = (gameSlug: string): string => {
  switch (gameSlug) {
    case "league-of-legends":
      return "/games/league-of-legends.jpg";
    case "rocket-league":
      return "/rocket-league.jpg";
    case "call-of-duty":
      return "/games/call-of-duty.jpg";
    case "5d8e626d0f14f524115dca53":
      return "/games/call-of-duty.jpg";
    case "569875921d10e44b00735070":
      return "/games/clash-royale.jpg";
    case "valorant":
      return "/games/valorant.jpg";
    case "tom-clancy-s-rainbow-six-siege-2":
      return "/games/rainbow-six-siege.jpg";
    default:
      return "/context-banner.jpg";
  }
};

export const winningsTableDataResolver = (
  winnings: WinningsConnection,
  claimWinning: Function
): any => {
  return winnings.edges.map((w) => ({
    placement: <span id="rank">{placementResolver(w.node.rank)}</span>,
    action: w.node.enabled ? (
      <Link
        className="inline-block rounded bg-gradient-to-br from-ctx-dark-orange via-ctx-orange to-ctx-dark-orange bg-size-150 bg-pos-0 px-4 py-2 font-semibold text-white transition-all duration-500 hover:bg-pos-100 "
        to={`/profile/claim/${w.node.id}`}
      >
        {winningsStateLabelResolver(w.node.state)}
      </Link>
    ) : (
      <div className="w-min cursor-not-allowed rounded bg-gradient-to-br from-ctx-dark-orange via-ctx-orange to-ctx-dark-orange bg-size-150 bg-pos-0 px-4 py-2 font-semibold text-white opacity-50 transition-all duration-500">
        {winningsStateLabelResolver(w.node.state)}
      </div>
    ),
    community: (
      <Link to={`/communities/${w.node.community.id}`}>
        {w.node.community.name}
      </Link>
    ),
    competition: (
      <React.Fragment>
        <Link
          to={`/communities/${w.node.community.id}/competitions/${w.node.fromId}`}
        >
          {w.node.title}
        </Link>
        <span id="team"> - {w.node.label}</span>
      </React.Fragment>
    ),
    amount: <AmountView amount={w.node.amount} />,
    timeRemaining: w.node.expiresAt && (
      <div className="profile-v2__wallet__winnings__expiry__container">
        {expiresColumnResolver(w.node)}
      </div>
    ),
  }));
};

export const winningsStateLabelResolver = (state: Number): string => {
  switch (state) {
    case 0:
      return "Claim";
    case 1:
      return "Expired";
    case 2:
      return "Claimed";
    case 3:
      return "Collecting";
    case 4:
      return "Returned";
    case 5:
      return "Collected";
    case 6:
      return "Failed";
    case 7:
      return "Claim";
  }
};

export const winningsStateDisplayResolver = (state: Number): string => {
  switch (state) {
    case 0:
      return "Unclaimed";
    case 1:
      return "Expired";
    case 2:
      return "Claimed";
    case 3:
      return "Collecting";
    case 4:
      return "Returned";
    case 5:
      return "Collected";
    case 6:
      return "Failed";
    case 7:
      return "Claiming";
  }
};

export const winningsStateColorResolver = (state: Number): string => {
  switch (state) {
    case 0:
      return "yellow";
    case 1:
      return "red";
    case 2:
      return "green";
    case 3:
      return "yellow";
    case 4:
      return "blue";
    case 5:
      return "green";
    case 6:
      return "red";
    case 7:
      return "yellow";
  }
};

export const currencyIconResolver = (currency: string) => {
  switch (currency) {
    case "EUR":
      return EuroIcon;
    case "GBP":
      return BritishPoundIcon;
    default:
      return DollarIcon;
  }
};

export const adminProfilesTableDataResovler = (
  profile: ContextProfile
): any => {
  let amount: Money;
  if (!profile) return {};
  if (profile.funds.deposit) {
    profile.funds.deposit.forEach((c) => {
      amount.display = c.display;
      amount.currency = c.currency;
    });
  }
  return {
    ID: profile.user.id,
    Name: profile.user.displayName,
    "Unclaimed Funds": <AmountView amount={amount} />,
  };
};

export const withdrawsTableDataResovler = (withdraw: Withdraw): any => {
  return {
    id: withdraw.id,
    userId: withdraw.userId,
    state: withdrawStateLabelResolver(withdraw.state),
    email: withdraw.email,
    prizes: (
      <div className="flex-column flex">
        {withdraw.prizes.map((x) => {
          return (
            <React.Fragment>
              <span className="mb-1">{x.prizeId}</span>
              <AmountView className="mb-1" amount={x.amount} />
            </React.Fragment>
          );
        })}
      </div>
    ),
  };
};

const withdrawStateLabelResolver = (state: number): string => {
  switch (state) {
    case 0:
      return "Created";
    case 1:
      return "Processing";
    case 2:
      return "Completed";
    case 3:
      return "Failed";
  }
};
