import React from "react";
import { Link } from "react-router-dom";

const Community = () => {
  return (
    <div className="faq-tabs">
      <div className="mt-2">
        <div className="help-page__content__section__faq__questions">
          <div className="help-page__content__section__faq__questions-item">
            <h1>How can I set up my own competition?</h1>
            <p>
              Head over to our <Link to="/create/competition">create page</Link>{" "}
              and follow the steps.
            </p>
          </div>
          <div className="help-page__content__section__faq__questions-item">
            <h1>Is this an alternative to start.gg?</h1>
            <p>No, your tournament runs like usual through start.gg.</p>
          </div>
          <div className="help-page__content__section__faq__questions-item">
            <h1>
              Do all of the participants of my competition need to sign in?
            </h1>
            <p>
              Only competition entrants who have earned a share of the prize
              pool are required to sign in.
            </p>
          </div>
          <div className="help-page__content__section__faq__questions-item">
            <h1>Why isn't my competition updating?</h1>
            <p>
              Event data is fetched every 10 minutes, so you may not see your
              changes instantaneously.
            </p>
          </div>
          <div className="help-page__content__section__faq__questions-item">
            <h1>What do I do when my tournament is over?</h1>
            <p>
              After your tournament has been finalized on start.gg, navigate to
              your Context Admin page by selecting the "Admin" button on your
              Context competition page. You will be able to finalize payments
              there.
            </p>
          </div>
          <div className="help-page__content__section__faq__questions-item">
            <h1>Still looking for an answer?</h1>
            <p>
              Get in contact with us <Link to="/contact">here.</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Community;
