import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  useGetCommunityCompetitionsQuery,
  useGetCommunityQuery,
} from "api/graphql";
import { useMyProfile } from "lib/profile";

import { competitionTableDataResolver } from "services/helpers/resolvers";

import Button from "components/Button";
import CustomTable from "components/Table";
import CompetitionCard from "components/CompetitionCard";

import Layout from "domains/community/components/Layout";
import Details from "domains/community/components/Details";
import StaffList from "domains/community/components/StaffList";
import LoadingData from "components/LoadingData";

const tableHeaders = [
  { name: "⠀⠀⠀⠀⠀", props: { align: "center" } },
  { name: "Name", props: { align: "left" } },
  { name: "Game", props: { align: "left" } },
  { name: "Start Date", props: { align: "left" } },
  { name: "Status", props: { align: "left" } },
  { name: "Prize Pool", props: { align: "left" } },
  { name: "Crowdfunding", props: { align: "center" } },
];

const ViewCommunity = () => {
  const user = useMyProfile();
  const { id } = useParams();

  const [cursor, setCursor] = useState(null);
  const [tableData, setTableData] = useState([]);

  const { data, isLoading, isError, refetch } = useGetCommunityQuery({
    id: id,
  });
  const { data: competitions, isLoading: competitionsFetching } =
    useGetCommunityCompetitionsQuery({
      id: id,
      after: cursor,
      pageSize: 20,
    });

  useEffect(() => {
    const compsData = competitions?.community?.competitions.edges?.map(
      ({ node }) => competitionTableDataResolver(node)
    );

    if (compsData != null) {
      var newData = [...tableData, ...compsData];
      setTableData(newData);
    }
  }, [competitions]);

  if (isLoading) {
    return <LoadingData />;
  }

  if (isError) {
    return (
      <div className="mt-6 text-center text-2xl text-white">
        There was an error loading community with ID: {id}
      </div>
    );
  }

  const featured = data?.community?.competitionsFeatured;
  const pageInfo = competitions?.community?.competitions?.pageInfo;

  const handleNextPage = () => {
    setCursor(pageInfo.endCursor);
    refetch();
  };

  return (
    <>
      <Layout
        community={data?.community}
        communityAdmin={data?.communityAdmin}
        user={user}
      >
        <div className="community-overview">
          <div className="community-overview__left">
            <Details community={data?.community} />
            <StaffList staff={data?.community.staff} />
          </div>
          <div className="community-overview__right">
            {tableData && tableData.length == 0 && (
              <div className="community-overview-default">
                <h1>Welcome to {data?.community.name}</h1>
                <div className="community-overview-default__divider"></div>
                <p>
                  This is the Context Community for everything relating to{" "}
                  {data?.community.name}.
                  <br />
                  <br />
                  You can support this community by subscribing. A community
                  subscription is the best way to support your favorite
                  communities with a recurring payment. This contribution will
                  help this community run more frequent Context competitions and
                  continue providing entertainment.
                  <br />
                  <br />
                  If a subscription isn't on your radar, you are also able to
                  support this community by making a one off donation.
                </p>
              </div>
            )}
            {featured && featured.length > 0 && (
              <div className="community-upcoming">
                <div className="community-upcoming__header">
                  <h1>Upcoming Events</h1>
                </div>
                <ScrollContainer
                  className="community-upcoming__container pb-2 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-ctx-orange"
                  hideScrollbars={false}
                >
                  {featured.map((node, k: number) => (
                    <CompetitionCard key={k} data={node} />
                  ))}
                </ScrollContainer>
              </div>
            )}
            {tableData && tableData.length > 0 && (
              <div className="community-all-events">
                <div className="community-all-events__header">
                  <h1>All Events</h1>
                </div>
                <div className="mb-2 overflow-x-auto rounded-md bg-context-light py-2 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-ctx-orange">
                  {/* <div className="community-all-events__table"> */}
                  <CustomTable
                    tableHeaders={tableHeaders}
                    tableData={tableData}
                  />
                </div>
                <Button
                  small
                  className="mx-auto my-2"
                  label={
                    competitionsFetching
                      ? "Loading..."
                      : pageInfo?.hasNextPage
                      ? "Load more"
                      : "You've reached the end"
                  }
                  disabled={!pageInfo?.hasNextPage}
                  onClick={() => handleNextPage()}
                />
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ViewCommunity;
