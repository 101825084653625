import { useMyProfile } from "lib/profile";

const PrivateRoute: React.FC = ({ children }) => {
  const auth = useMyProfile();

  if (!auth) {
    window.location.href = "/login";
  }

  return <>{children}</>;
};

export default PrivateRoute;
