import { useState } from "react";

import Winners from "./Steps/Winners";
import Wizard from "components/Wizard";

import Trophy from "@iconify/icons-uil/trophy";
import { useNavigate } from "react-router-dom";
import { useDisburseCompetitionMutation } from "api/graphql";

export type FinalizeWizardProps = {
  type: string;
  data: any;
};

const steps = [
  {
    stepName: "Winners",
    stepDescription: "Select your winners.",
    stepIcon: Trophy,
    stepId: 1,
    stepComponent: Winners,
  },
];

const FinalizeWizard = (props: FinalizeWizardProps) => {
  const nav = useNavigate();

  const { mutateAsync: executeDisburseCompetition } = useDisburseCompetitionMutation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const executeDisbursement = (states: any) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      if (props.data.status != "Completed") {
        setLoading(false);
        reject({ message: "Cannot finalize now, incorrect state" });
        return;
      } else if (!states.winners.valid) {
        setLoading(false);
        reject({
          message:
            "Invalid selection. At least one member from each team must be selected.",
        });
        return;
      } else {
        setError(null);
      }
      if (error) return setLoading(false);
      const disbursementObject = {
        id: props.data.id,
        config: {
          teamBreakdowns: states.winners.entrantsToPayout,
        },
      };

      executeDisburseCompetition(disbursementObject, {
        onSettled: (data, error) => {
          if (data) {
            resolve(true);
            setTimeout(() => {
              setLoading(false);
              nav(0);
            }, 1500);
          }

          if (error) {
            setLoading(false);
            reject(error);
          }
        },
      });
    });
  };

  const finalClick = (states: any) => {
    executeDisbursement(states)
      .then((res) => {
        if (res) return true;
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <div className="finalize-form__wrapper">
      <div className="finalize-form__wrapper__header">
        <h1>Finalize {props.type}</h1>
      </div>
      <div className="finalize-form__wrapper__wizard">
        <Wizard
          steps={steps}
          finalClick={finalClick}
          context={{ data: props.data }}
          forceLoading={loading}
          forceError={error}
          success={success}
          noHeaders
          noNegativeMargin
          noStepShadow
        />
      </div>
    </div>
  );
};
export default FinalizeWizard;
