import React from "react";
import TeamMemberLink from "./TeamMemberLink";

type TeamMemberProps = {
  name: string;
  position: string;
  avatarURL: string;
  links?: TeamMemberLink[];
  email?: string;
};

export type TeamMemberLink = {
  type: "twitter" | "linkedin" | "email";
  url: string;
};

const TeamMember = (props: TeamMemberProps) => {
  return (
    <div className="team-member__wrapper">
      <div className="team-member__avatar">
        <img src={props.avatarURL} alt="" />
      </div>
      <div className="team-member__content">
        <div className="team-member__content__title">
          <h1>{props.name}</h1>
          <h3>{props.position}</h3>
        </div>
        {props.links && props.links.length > 0 && (
          <div className="mt-0.5 flex gap-x-1">
            {props.links.map((link, i) => (
              <TeamMemberLink key={i} {...link} />
            ))}
            {props.email && <TeamMemberLink type="email" url={props.email} />}
          </div>
        )}
      </div>
    </div>
  );
};
export default TeamMember;
