import React from "react";

const Loading = () => {
  return (
    <div className="flex h-full min-h-screen flex-col items-center justify-center">
      <div className="flex flex-1 flex-col items-center justify-center space-x-2 space-y-4">
        <div className="flex items-center justify-center space-x-2">
          <h1 className="text-6xl font-bold text-white">
            <img src="/context-logo.png" />
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Loading;
