import { Community, ProfileCommunity } from "types/Community";
import { regionResolver } from "services/helpers/resolvers";
import { useNavigate } from "react-router-dom";

type CommunityCardProps = {
  data: Community | ProfileCommunity;
  small?: boolean;
};

const CommunityCard = (props: CommunityCardProps) => {
  const nav = useNavigate();

  const navigateToCommunity = (id) => {
    nav(`/${id}`);
  };

  return (
    <div
      className={`community-card ${props.small ? "small" : ""}`}
      onClick={() => navigateToCommunity(props.data.alias || props.data.id)}
    >
      <div className="community-card__wrapper">
        <div
          className="community-card__background"
          style={{
            backgroundImage: `
            linear-gradient(180deg, rgba(27, 27, 27, 0.9) 0%, rgba(27, 27, 27, 0.9) 100%),
            url(${props.data.banner ? props.data.banner : "/rocket-league.jpg"})
            `,
          }}
        ></div>
        <div className="community-card__content">
          <div className="community-card__content__top">
            <div className="community-card__content__image">
              <img
                src={props.data.logo ? props.data.logo : "/no-image.jpg"}
                alt={`${props.data.name} Logo`}
              />
            </div>
            <div className="community-card__content__text">
              <h1>{props.data.name}</h1>
              <h3>
                {props.data.regions.map((r, i) => {
                  if (i === props.data.regions.length - 1)
                    return regionResolver(r);
                  else return regionResolver(r) + ", ";
                })}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommunityCard;
