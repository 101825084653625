import { useGetGames } from "api/api";
import SearchGame from "components/FormInputs/SearchGame";
import { Field, useFormikContext } from "formik";
import { useState } from "react";

const SelectGame = () => {
  const { values } = useFormikContext<any>();

  const [gameName, setGameName] = useState("");
  const { data, isFetching } = useGetGames(
    { limit: 25, offset: 0, search: gameName },
    { query: { enabled: gameName.length > 0 } }
  );

  return (
    <div className="space-y-5">
      <div>
        <h1 className="text-2xl font-semibold text-neutral-100">Select Game</h1>
        <p className="text-neutral-400">
          Select the game you would like to use for your competition
        </p>
      </div>
      <div className="space-y-4 rounded-md shadow-sm">
        <div className="relative md:grid md:grid-cols-5">
          <div className="w-full sm:col-span-3">
            <Field
              name="game"
              label="Search Game"
              component={SearchGame}
              placeholder="Rocket League"
              options={data?.items}
              searchChanged={setGameName}
              isFetching={isFetching}
            />
          </div>
        </div>
        {values?.game && (
          <div
            draggable={false}
            className="z-0 flex max-h-[102px] select-none items-start gap-x-3 overflow-y-hidden rounded-md border border-context-black border-opacity-50 bg-context-gray bg-opacity-60 text-neutral-400"
          >
            <img
              src={values.game.logo}
              alt={`${values.game.name} Logo`}
              className="h-[100px] flex-initial rounded-l-md"
              draggable={false}
            />
            <div className="flex flex-1 flex-col overflow-clip">
              <h1 className="flex-initial text-lg font-semibold">
                {values.game.name}
              </h1>
              <p className="flex-1 text-clip break-words">
                {values.game.description}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectGame;
