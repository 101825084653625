import { useMyProfile } from "lib/profile";

const Debug = () => {
  const user = useMyProfile();

  return (
    <div className="mx-auto px-4 text-white sm:px-6 lg:px-8">
      <div className="my-2 mx-auto md:w-3/4 lg:w-2/3">
        <h1 className="text-2xl font-bold text-ctx-orange">
          Profile Debug | {user?.username}
        </h1>
        <p className="mb-1 text-neutral-300">
          This is some basic information related to your account. You are most
          likely here because you need to send us your id. <br />
          So, just copy the id below and send us a message :)
        </p>
        <pre
          style={{
            backgroundColor: "#141414",
            padding: "1rem",
            borderRadius: "0.5rem",
            marginBottom: "1rem",
            overflowWrap: "break-word",
            wordBreak: "break-all",
            whiteSpace: "pre-wrap",
          }}
        >
          {JSON.stringify(user, null, 2)}
        </pre>
        <p className="text-neutral-300">
          Note: "profileID" is not your context id so make sure you got the
          correct one.
        </p>
      </div>
    </div>
  );
};

export default Debug;
