import { useGetTeam } from "api/api";
import React from "react";
import { useParams } from "react-router-dom";

const ViewTeam = () => {
  const { id } = useParams();

  const { data } = useGetTeam<any>(id);

  console.log(data);

  return (
    <div className="text-white">
      ViewTeam {data?.name}
      <br />
      Invite code:{" "}
      <a
        className="text-orange-500"
        target="_blank"
        href={`/invite/${data?.code}`}
      >
        {data?.code}
      </a>
      <div>
        {data?.players?.map((players) => (
          <div key={players.id}>{players.role_id}</div>
        ))}
      </div>
    </div>
  );
};

export default ViewTeam;
