import { StandardFonts, rgb } from "pdf-lib";

export const inputCoordinatesMap = {
  name: {
    x: 65,
    y: 248,
  },
  citizenshipCountry: {
    x: 394,
    y: 248,
  },
  address: {
    x: 65,
    y: 272,
  },
  city: {
    x: 65,
    y: 296,
  },
  country: {
    x: 447,
    y: 296,
  },
  mailingAddress: {
    x: 65,
    y: 321,
  },
  mailingCity: {
    x: 65,
    y: 344,
  },
  mailingCountry: {
    x: 447,
    y: 344,
  },
  taxpayerIdentificationNumber: {
    x: 65,
    y: 368,
  },
  foreignTaxNumber: {
    x: 394,
    y: 368,
  },
  referenceNumbers: {
    x: 65,
    y: 392,
  },
  dateOfBirth: {
    x: 290,
    y: 392,
  },
  residentCountry: {
    x: 242,
    y: 418,
  },
  articleParagraph: {
    x: 65,
    y: 453,
  },
  specialRate: {
    x: 354,
    y: 453,
  },
  incomeType: {
    x: 65,
    y: 466,
  },
  additionalConditions: {
    x: 65,
    y: 489,
  },
  signatureDataUrl: {
    x: 114,
    y: 717,
  },
  date: {
    x: 437,
    y: 717,
  },
  printName: {
    x: 114,
    y: 741,
  },
  actingCapacity: {
    x: 372,
    y: 741,
  },
};

export const updateW8Fields = async (values, pdfDoc) => {
  const firstPage = pdfDoc.getPages()[0];
  const { height } = firstPage.getSize();
  const font = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const fontSize = 10;
  const fontColor = rgb(0, 0, 0);

  Object.keys(values).map(async (field) => {
    const coords = inputCoordinatesMap[field];
    if (!coords) return;

    const v = values[field];
    if (!v) {
      return;
    }

    switch (field) {
      case "additionalConditions":
        firstPage.drawText(v, {
          x: coords.x,
          y: height - coords.y,
          size: fontSize,
          font: font,
          maxWidth: 530,
          lineHeight: 12,
          color: fontColor,
        });
        return;
      case "signatureDataUrl":
        const signature = await pdfDoc.embedPng(v);
        const signatureDims = signature.scale(0.25);

        firstPage.drawImage(signature, {
          x: coords.x,
          y: height - coords.y,
          width: signatureDims.width,
          height: signatureDims.height,
        });
        break;
      case "specialRate":
        firstPage.drawText(String(v), {
          x: coords.x,
          y: height - coords.y,
          size: fontSize,
          font: font,
          color: fontColor,
        });
        return;
      case "dateOfBirth":
        firstPage.drawText(
          `${v.month}-${v.day}-${v.year}`,
          {
            x: coords.x,
            y: height - coords.y,
            size: fontSize,
            font: font,
            color: fontColor,
          }
        );
        break;
      case "date":
        firstPage.drawText(
          new Date(v).toLocaleDateString("en-us"),
          {
            x: coords.x,
            y: height - coords.y,
            size: fontSize,
            font: font,
            color: fontColor,
          }
        );
        break;
      default:
        firstPage.drawText(v.value || v, {
          x: coords.x,
          y: height - coords.y,
          size: fontSize,
          font: font,
          color: fontColor,
        });
        return;
    }
  });
};

const fieldRequiredError = "This is a required field";
const oneOfFieldRequiredError = "At least one input is required";

export const validateFields = async (values) => {
  let errors = [];

  return new Promise((resolve, reject) => {
    Object.keys(values).map((field) => {
      switch (field) {
        case "name":
          if (!values[field]) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case "citizenship_country":
          if (!values[field]) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case "address":
          if (!values[field]) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case "address_city":
          if (!values[field]) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case "address_country":
          if (!values[field]) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case "mailing_city":
          if (!values[field] && values.mailing_address) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case "mailing_country":
          if (!values[field] && values.mailing_address) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case "taxpayer_id_number":
          if (!values.taxpayer_id_number && !values.foreign_tax_id_number) {
            errors.push({
              id: field,
              message: oneOfFieldRequiredError,
            });
            break;
          }
          break;
        case "foreign_tax_id_number":
          if (!values.foreign_tax_id_number && !values.taxpayer_id_number) {
            errors.push({
              id: field,
              message: oneOfFieldRequiredError,
            });
            break;
          }
          break;
        case "date_of_birth":
          if (!values[field]) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case "signature_data_url":
          if (!values[field]) {
            errors.push({
              id: field,
              message: "A signature is required",
            });
            break;
          }
          break;
        default:
          break;
      }
    });
    if (errors.length > 0) reject(errors);

    resolve(true);
  });
};

export const validDate = (dateString): Promise<string> => {
  return new Promise((resolve, reject) => {
    // format date from 01012020 to 01-01-2020
    const formattedDate = formatDate(dateString);

    const regex = new RegExp(
      "^(0[1-9]|1[012])[- \\/.](0[1-9]|[12][0-9]|3[01])[- \\/.](19|20)\\d\\d$"
    );
    const valid = regex.test(formattedDate);

    if (!valid) reject(false);

    return resolve(formattedDate);
  });
};

const formatDate = (date: string): string => {
  return (
    // returns 01-01-2020
    date.slice(0, 2) + "-" + date.slice(2, 4) + "-" + date.slice(4, 8)
  );
};
