import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import { Icon } from "@iconify/react";

// icons
import ArrowRight from "@iconify/icons-uil/arrow-right";
import TwitterIcon from "@iconify/icons-uil/twitter";

const Custom404 = () => {
  const nav = useNavigate();

  return (
    <div>
      <div className="parallax space" />
      <div className="not-found">
        <div className="not-found__left">
          <div className="not-found__left__header">
            <h1>Oops! The page you're looking for doesn't exist.</h1>
            <h3>
              There may be a spelling error in the address entered or this page
              could have moved elsewhere.
            </h3>
          </div>
          <div className="not-found__left__buttons">
            <Button label="Return to home" glow onClick={() => nav("/")} />
          </div>
          <div className="not-found__left__contact">
            <h1 className="not-found__left__contact__title">
              Is there an issue you think we should know about?
            </h1>
            <div className="not-found__left__contact__container">
              <div className="not-found__left__contact__item">
                <div className="not-found__left__contact__item__header">
                  <img
                    className="not-found__left__contact__item__header__icon"
                    src="/discord.svg"
                    alt="discord"
                  />
                  <h1>Get in touch</h1>
                </div>
                <div className="not-found__left__contact__item__content">
                  <p>
                    Join our discord server to chat with other Context users,
                    get support for any issues you may face, and provide
                    feedback about the platform and share ideas.
                  </p>
                  <a
                    rel="noreferrer"
                    className="not-found__left__contact__item__content__link"
                    target="_blank"
                    href="https://discord.gg/c6BR2UT"
                  >
                    Our Discord <Icon icon={ArrowRight} />
                  </a>
                </div>
              </div>
              <div className="not-found__left__contact__item">
                <div className="not-found__left__contact__item__header">
                  <Icon
                    className="not-found__left__contact__item__header__icon"
                    icon={TwitterIcon}
                  />
                  <h1>Tweet us</h1>
                </div>
                <div className="not-found__left__contact__item__content">
                  <p>
                    If Discord isn't your forte, bring your issue to our
                    attention via Twitter, feel free to Tweet at us or direct
                    message.
                  </p>
                  <a
                    rel="noreferrer"
                    className="not-found__left__contact__item__content__link"
                    target="_blank"
                    href="https://twitter.com/ctx_gg"
                  >
                    Our Twitter <Icon icon={ArrowRight} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="not-found__right">
          <img
            className="not-found__right__crane"
            src="/crane.svg"
            alt="context-crane"
          />
        </div>
      </div>
    </div>
  );
};
export default Custom404;
