import { useGetCommunities } from "api/api";
import FormDropdown from "components/FormInputs/FormDropdown";
import { useProfileDetailsQuery } from "api/graphql";
import { Field } from "formik";

const SelectCommunity = () => {
  // This is when we convert to using the new communities
  // const { data } = useGetCommunities({ offset: 0, limit: 100 });

  // This is using the existing context.gg communities
  const { data, isLoading } = useProfileDetailsQuery();

  return (
    <div className="space-y-5">
      <div>
        <h1 className="text-2xl font-semibold text-neutral-100">
          Select Community
        </h1>
        <p className="text-neutral-400">
          What community is this competition for?
        </p>
      </div>
      <div className="space-y-4 rounded-md shadow-sm">
        <div className="md:grid md:grid-cols-4">
          <div className="col-span-2">
            {/* TODO: IF MORE THAN 4 COMMUNITIES, DISPLAY THEM AS BLOCKS RATHER THAN IN A DROPDOWN */}
            <Field
              name="community"
              label="Select Community"
              component={FormDropdown}
              placeholder="First Name"
              options={data?.profile.communities.edges}
              // options={data?.items} new communities
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCommunity;
