/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * api
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import { customInstance } from "../lib/instance";
export type GetTeamsParams = {
  community_id?: string;
  competition_id?: string;
  limit?: number;
  offset?: number;
};

export type GetCommunitiesParams = {
  search?: string;
  pinned?: boolean;
  limit?: number;
  offset?: number;
};

export type GetCompetitionsParams = {
  search?: string;
  pinned?: boolean;
  community_id?: string;
  limit?: number;
  offset?: number;
};

export type GetGamesParams = {
  search?: string;
  limit?: number;
  offset?: number;
};

/**
 * Unexpected server error
 */
export type UnexpectedErrorResponse = void;

/**
 * The type of invite
 */
export type InviteType = typeof InviteType[keyof typeof InviteType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteType = {
  team: "team",
} as const;

export interface Invite {
  /** Id for the player */
  id: string;
  /** The code for the invite */
  code: string;
  /** The type of invite */
  type: InviteType;
  /** The external id for the invite */
  external_id?: string;
}

/**
 * The role id for the team
 */
export type TeamPlayersItemRoleId =
  typeof TeamPlayersItemRoleId[keyof typeof TeamPlayersItemRoleId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TeamPlayersItemRoleId = {
  captain: "captain",
  starter: "starter",
  substitute: "substitute",
  manager: "manager",
  coach: "coach",
} as const;

export type TeamPlayersItem = {
  /** Id for the player */
  id: string;
  /** The role id for the team */
  role_id: TeamPlayersItemRoleId;
};

export interface Team {
  /** Id for the game */
  id: string;
  /** Name of the game */
  name: string;
  /** Logo for the game */
  logo: string;
  /** The players in the team */
  players: TeamPlayersItem[];
}

export interface Teams {
  /** The limit of the teams */
  limit: number;
  /** The page to show */
  page: number;
  /** The total number of items */
  total_items: number;
  /** The total number of pages */
  total_pages: number;
  /** The teams */
  items: Team[];
}

export interface Community {
  /** Id for the game */
  id: string;
  /** Name of the game */
  name: string;
  /** Logo for the game */
  logo: string;
}

export interface Communities {
  /** The limit of the competitions */
  limit: number;
  /** The page to show */
  page: number;
  /** The total number of items */
  total_items: number;
  /** The total number of pages */
  total_pages: number;
  /** The communities */
  items: Community[];
}

export interface Competition {
  /** The game id for the competition */
  game_id: string;
  /** The supported platforms for the competition */
  game_platforms: string[];
  /** The game mode for the competition */
  game_mode_id: string;
  /** Name of the competition */
  name: string;
  /** Description for the competition */
  description: string;
  /** When the competition starts */
  start_date: string;
  /** Copy an existing bracket */
  copy_bracket_from_id?: string;
}

export interface Competitions {
  /** The limit of the competitions */
  limit: number;
  /** The page to show */
  page: number;
  /** The total number of items */
  total_items: number;
  /** The total number of pages */
  total_pages: number;
  /** The competitions */
  items: Competition[];
}

export interface Game {
  /** Id for the game */
  id: string;
  /** Name of the game */
  name: string;
  /** Logo for the game */
  logo: string;
}

export interface Games {
  /** The limit of the competitions */
  limit: number;
  /** The page to show */
  page: number;
  /** The total number of items */
  total_items: number;
  /** The total number of pages */
  total_pages: number;
  /** The games */
  items: Game[];
}

export type CreateTeamAllOf = {
  /** The community this team is in */
  community_id?: string;
  /** the competition this team is in */
  competition_id?: string;
  /** Name of the game */
  name: string;
  /** Logo for the game */
  logo: string;
};

export type UnpinCompetition = Command;

export type CreateCompetitionAllOf = {
  /** The competition community */
  community_id: string;
  /** The game id for the competition */
  game_id: string;
  /** The supported platforms for the competition */
  game_platforms: string[];
  /** The game mode for the competition */
  game_mode_id: string;
  /** Name of the competition */
  name: string;
  /** Description for the competition */
  description: string;
  /** When the competition starts */
  start_date: string;
  /** Copy an existing bracket */
  copy_from_id?: string;
};

export type RemoveStaffMemberAllOf = {
  /** Staff member Id */
  staff_member_id: string;
};

export type UpdateStaffMemberAllOf = {
  /** Staff Id */
  staff_member_id: string;
  /** Role Id */
  role_id: string;
};

export type AddStaffMemberAllOf = {
  /** Staff Id */
  staff_member_id: string;
  /** Role Id */
  role_id: string;
};

export type CreateCommunityAllOf = {
  /** Name of the competition */
  name: string;
  /** Logo for the game */
  logo: string;
};

export interface Command {
  /** Aggregate Id */
  aggregate_id: string;
}

export type AcceptInvite = Command;

export type CreateTeam = Command & CreateTeamAllOf;

export type PinCompetition = Command;

export type CreateCompetition = Command & CreateCompetitionAllOf;

export type RemoveStaffMember = Command & RemoveStaffMemberAllOf;

export type UpdateStaffMember = Command & UpdateStaffMemberAllOf;

export type AddStaffMember = Command & AddStaffMemberAllOf;

export type CreateCommunity = Command & CreateCommunityAllOf;

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get a list of games
 */
export const getGames = (params?: GetGamesParams, signal?: AbortSignal) => {
  return customInstance<Games>({
    url: `/api/quantum/games`,
    method: "get",
    signal,
    params,
  });
};

export const getGetGamesQueryKey = (params?: GetGamesParams) => [
  `/api/quantum/games`,
  ...(params ? [params] : []),
];

export type GetGamesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGames>>
>;
export type GetGamesQueryError = UnexpectedErrorResponse;

export const useGetGames = <
  TData = Awaited<ReturnType<typeof getGames>>,
  TError = UnexpectedErrorResponse
>(
  params?: GetGamesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getGames>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGamesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGames>>> = ({
    signal,
  }) => getGames(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getGames>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Get a list of competitions
 */
export const getCompetitions = (
  params?: GetCompetitionsParams,
  signal?: AbortSignal
) => {
  return customInstance<Competitions>({
    url: `/api/quantum/competitions`,
    method: "get",
    signal,
    params,
  });
};

export const getGetCompetitionsQueryKey = (params?: GetCompetitionsParams) => [
  `/api/quantum/competitions`,
  ...(params ? [params] : []),
];

export type GetCompetitionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompetitions>>
>;
export type GetCompetitionsQueryError = UnexpectedErrorResponse;

export const useGetCompetitions = <
  TData = Awaited<ReturnType<typeof getCompetitions>>,
  TError = UnexpectedErrorResponse
>(
  params?: GetCompetitionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitions>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompetitionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompetitions>>> = ({
    signal,
  }) => getCompetitions(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getCompetitions>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Get a competition
 */
export const getCompetition = (id: string, signal?: AbortSignal) => {
  return customInstance<Competition>({
    url: `/api/quantum/competitions/${id}`,
    method: "get",
    signal,
  });
};

export const getGetCompetitionQueryKey = (id: string) => [
  `/api/quantum/competitions/${id}`,
];

export type GetCompetitionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompetition>>
>;
export type GetCompetitionQueryError = UnexpectedErrorResponse;

export const useGetCompetition = <
  TData = Awaited<ReturnType<typeof getCompetition>>,
  TError = UnexpectedErrorResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetition>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompetitionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompetition>>> = ({
    signal,
  }) => getCompetition(id, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getCompetition>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Get a list of communities
 */
export const getCommunities = (
  params?: GetCommunitiesParams,
  signal?: AbortSignal
) => {
  return customInstance<Communities>({
    url: `/api/quantum/communities`,
    method: "get",
    signal,
    params,
  });
};

export const getGetCommunitiesQueryKey = (params?: GetCommunitiesParams) => [
  `/api/quantum/communities`,
  ...(params ? [params] : []),
];

export type GetCommunitiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCommunities>>
>;
export type GetCommunitiesQueryError = UnexpectedErrorResponse;

export const useGetCommunities = <
  TData = Awaited<ReturnType<typeof getCommunities>>,
  TError = UnexpectedErrorResponse
>(
  params?: GetCommunitiesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCommunities>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCommunitiesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunities>>> = ({
    signal,
  }) => getCommunities(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getCommunities>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Get a list of teams
 */
export const getTeams = (params?: GetTeamsParams, signal?: AbortSignal) => {
  return customInstance<Teams>({
    url: `/api/quantum/teams`,
    method: "get",
    signal,
    params,
  });
};

export const getGetTeamsQueryKey = (params?: GetTeamsParams) => [
  `/api/quantum/teams`,
  ...(params ? [params] : []),
];

export type GetTeamsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTeams>>
>;
export type GetTeamsQueryError = UnexpectedErrorResponse;

export const useGetTeams = <
  TData = Awaited<ReturnType<typeof getTeams>>,
  TError = UnexpectedErrorResponse
>(
  params?: GetTeamsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTeams>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTeamsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeams>>> = ({
    signal,
  }) => getTeams(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTeams>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Get a team
 */
export const getTeam = (id: string, signal?: AbortSignal) => {
  return customInstance<Team>({
    url: `/api/quantum/teams/${id}`,
    method: "get",
    signal,
  });
};

export const getGetTeamQueryKey = (id: string) => [`/api/quantum/teams/${id}`];

export type GetTeamQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTeam>>
>;
export type GetTeamQueryError = UnexpectedErrorResponse;

export const useGetTeam = <
  TData = Awaited<ReturnType<typeof getTeam>>,
  TError = UnexpectedErrorResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTeam>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTeamQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeam>>> = ({
    signal,
  }) => getTeam(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTeam>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Get an invite by code
 */
export const getInvite = (code: string, signal?: AbortSignal) => {
  return customInstance<Invite>({
    url: `/api/quantum/invite/${code}`,
    method: "get",
    signal,
  });
};

export const getGetInviteQueryKey = (code: string) => [
  `/api/quantum/invite/${code}`,
];

export type GetInviteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInvite>>
>;
export type GetInviteQueryError = UnexpectedErrorResponse;

export const useGetInvite = <
  TData = Awaited<ReturnType<typeof getInvite>>,
  TError = UnexpectedErrorResponse
>(
  code: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInvite>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInviteQueryKey(code);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvite>>> = ({
    signal,
  }) => getInvite(code, signal);

  const query = useQuery<Awaited<ReturnType<typeof getInvite>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!code, ...queryOptions }
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Create a new community
 */
export const postCreateCommunity = (createCommunity: CreateCommunity) => {
  return customInstance<void>({
    url: `/api/quantum/createcommunity`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: createCommunity,
  });
};

export type PostCreateCommunityMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCreateCommunity>>
>;
export type PostCreateCommunityMutationBody = CreateCommunity;
export type PostCreateCommunityMutationError = UnexpectedErrorResponse;

export const usePostCreateCommunity = <
  TError = UnexpectedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCreateCommunity>>,
    TError,
    { data: CreateCommunity },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCreateCommunity>>,
    { data: CreateCommunity }
  > = (props) => {
    const { data } = props ?? {};

    return postCreateCommunity(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCreateCommunity>>,
    TError,
    { data: CreateCommunity },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Add a staff member to a community
 */
export const postAddStaffMember = (addStaffMember: AddStaffMember) => {
  return customInstance<void>({
    url: `/api/quantum/addstaffmember`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: addStaffMember,
  });
};

export type PostAddStaffMemberMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAddStaffMember>>
>;
export type PostAddStaffMemberMutationBody = AddStaffMember;
export type PostAddStaffMemberMutationError = UnexpectedErrorResponse;

export const usePostAddStaffMember = <
  TError = UnexpectedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAddStaffMember>>,
    TError,
    { data: AddStaffMember },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAddStaffMember>>,
    { data: AddStaffMember }
  > = (props) => {
    const { data } = props ?? {};

    return postAddStaffMember(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof postAddStaffMember>>,
    TError,
    { data: AddStaffMember },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Update a staff member in a community
 */
export const postUpdateStaffMember = (updateStaffMember: UpdateStaffMember) => {
  return customInstance<void>({
    url: `/api/quantum/updatestaffmember`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: updateStaffMember,
  });
};

export type PostUpdateStaffMemberMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUpdateStaffMember>>
>;
export type PostUpdateStaffMemberMutationBody = UpdateStaffMember;
export type PostUpdateStaffMemberMutationError = UnexpectedErrorResponse;

export const usePostUpdateStaffMember = <
  TError = UnexpectedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUpdateStaffMember>>,
    TError,
    { data: UpdateStaffMember },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUpdateStaffMember>>,
    { data: UpdateStaffMember }
  > = (props) => {
    const { data } = props ?? {};

    return postUpdateStaffMember(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof postUpdateStaffMember>>,
    TError,
    { data: UpdateStaffMember },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Remove a staff member from a community
 */
export const postRemoveStaffMember = (removeStaffMember: RemoveStaffMember) => {
  return customInstance<void>({
    url: `/api/quantum/removestaffmember`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: removeStaffMember,
  });
};

export type PostRemoveStaffMemberMutationResult = NonNullable<
  Awaited<ReturnType<typeof postRemoveStaffMember>>
>;
export type PostRemoveStaffMemberMutationBody = RemoveStaffMember;
export type PostRemoveStaffMemberMutationError = UnexpectedErrorResponse;

export const usePostRemoveStaffMember = <
  TError = UnexpectedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postRemoveStaffMember>>,
    TError,
    { data: RemoveStaffMember },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postRemoveStaffMember>>,
    { data: RemoveStaffMember }
  > = (props) => {
    const { data } = props ?? {};

    return postRemoveStaffMember(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof postRemoveStaffMember>>,
    TError,
    { data: RemoveStaffMember },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Create a new competition
 */
export const postCreateCompetition = (createCompetition: CreateCompetition) => {
  return customInstance<void>({
    url: `/api/quantum/createcompetition`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: createCompetition,
  });
};

export type PostCreateCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCreateCompetition>>
>;
export type PostCreateCompetitionMutationBody = CreateCompetition;
export type PostCreateCompetitionMutationError = UnexpectedErrorResponse;

export const usePostCreateCompetition = <
  TError = UnexpectedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCreateCompetition>>,
    TError,
    { data: CreateCompetition },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCreateCompetition>>,
    { data: CreateCompetition }
  > = (props) => {
    const { data } = props ?? {};

    return postCreateCompetition(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCreateCompetition>>,
    TError,
    { data: CreateCompetition },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Pin a competition
 */
export const postPinCompetition = (pinCompetition: PinCompetition) => {
  return customInstance<void>({
    url: `/api/quantum/pincompetition`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: pinCompetition,
  });
};

export type PostPinCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPinCompetition>>
>;
export type PostPinCompetitionMutationBody = PinCompetition;
export type PostPinCompetitionMutationError = UnexpectedErrorResponse;

export const usePostPinCompetition = <
  TError = UnexpectedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPinCompetition>>,
    TError,
    { data: PinCompetition },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPinCompetition>>,
    { data: PinCompetition }
  > = (props) => {
    const { data } = props ?? {};

    return postPinCompetition(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof postPinCompetition>>,
    TError,
    { data: PinCompetition },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Unpin a competition
 */
export const postUnpinCompetition = (unpinCompetition: UnpinCompetition) => {
  return customInstance<void>({
    url: `/api/quantum/unpincompetition`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: unpinCompetition,
  });
};

export type PostUnpinCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUnpinCompetition>>
>;
export type PostUnpinCompetitionMutationBody = UnpinCompetition;
export type PostUnpinCompetitionMutationError = UnexpectedErrorResponse;

export const usePostUnpinCompetition = <
  TError = UnexpectedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUnpinCompetition>>,
    TError,
    { data: UnpinCompetition },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUnpinCompetition>>,
    { data: UnpinCompetition }
  > = (props) => {
    const { data } = props ?? {};

    return postUnpinCompetition(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof postUnpinCompetition>>,
    TError,
    { data: UnpinCompetition },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Create a new team
 */
export const postCreateTeam = (createTeam: CreateTeam) => {
  return customInstance<void>({
    url: `/api/quantum/createteam`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: createTeam,
  });
};

export type PostCreateTeamMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCreateTeam>>
>;
export type PostCreateTeamMutationBody = CreateTeam;
export type PostCreateTeamMutationError = UnexpectedErrorResponse;

export const usePostCreateTeam = <
  TError = UnexpectedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCreateTeam>>,
    TError,
    { data: CreateTeam },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCreateTeam>>,
    { data: CreateTeam }
  > = (props) => {
    const { data } = props ?? {};

    return postCreateTeam(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof postCreateTeam>>,
    TError,
    { data: CreateTeam },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Accept an invite
 */
export const postAcceptInvite = (acceptInvite: AcceptInvite) => {
  return customInstance<void>({
    url: `/api/quantum/acceptinvite`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: acceptInvite,
  });
};

export type PostAcceptInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAcceptInvite>>
>;
export type PostAcceptInviteMutationBody = AcceptInvite;
export type PostAcceptInviteMutationError = UnexpectedErrorResponse;

export const usePostAcceptInvite = <
  TError = UnexpectedErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAcceptInvite>>,
    TError,
    { data: AcceptInvite },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAcceptInvite>>,
    { data: AcceptInvite }
  > = (props) => {
    const { data } = props ?? {};

    return postAcceptInvite(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof postAcceptInvite>>,
    TError,
    { data: AcceptInvite },
    TContext
  >(mutationFn, mutationOptions);
};
