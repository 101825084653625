import React from "react";
import { InlineIcon } from "@iconify/react";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import ErrorComponent from "components/Error";

// icons
import SaveIcon from "@iconify/icons-uil/save";
import TrashIcon from "@iconify/icons-uil/trash-alt";
import { useLocation } from "react-router-dom";

type SettingsFormFooterProps = {
  id: string;
  isUnsaved: boolean;
  saveLoading: boolean;
  saveSuccess: any;
  saveError: any;
  handleSave: Function;
  resetState: Function;
};

const SettingsFormFooter = (props: SettingsFormFooterProps) => {
  const location = useLocation();

  return (
    <div>
      {props.isUnsaved && (
        <div className="community-admin__settings__form__buttons">
          <Button
            label={
              <span>
                <InlineIcon className="inline-block" icon={SaveIcon} /> Save
              </span>
            }
            color="green"
            onClick={props.handleSave}
          />
          <Button
            label={
              <span>
                <InlineIcon className="inline-block" icon={TrashIcon} /> Discard
              </span>
            }
            color="red"
            onClick={props.resetState}
          />
          {props.saveLoading && (
            <div className="community-admin__settings__form__buttons__loading">
              <CircularProgress sm />
            </div>
          )}
        </div>
      )}
      {props.saveSuccess && (
        <div className="community-admin__settings__form__success pt-2">
          <span className="green bold">Settings successfully saved!</span>
        </div>
      )}
      {props.saveError && (
        <div className="community-admin__settings__form__success pt-2">
          <ErrorComponent
            id={props.id}
            visible={props.saveError ? true : false}
            message={props.saveError && props.saveError.message}
            path={location.pathname}
            type="GeneralSettingsSaved"
            closeable={false}
            style={{ margin: "24px 0" }}
            inverse
          />
        </div>
      )}
    </div>
  );
};
export default SettingsFormFooter;
