import classNames from "classnames";
import React from "react";

import Select from "react-select";

type SelectProps = {
  id: string;
  isSearchable?: boolean;
  isMultiple?: boolean;
  disabled?: boolean;
  label?: any;
  className?: string;
  onChange?: any;
  options: any;
  name?: any;
  styles?: any;
  error?: any;
  errorStyles?: any;
  value: any;
};

const CustomSelect = (props: SelectProps) => {
  return (
    <div className={classNames("select__container", props.className)}>
      {props.label && (
        <h3
          className={`select__label ${props.error ? "error" : ""} ${
            props.disabled ? "disabled" : ""
          }`}
        >
          {props.label}
        </h3>
      )}
      <Select
        styles={
          !props.error
            ? props.styles
            : props.errorStyles
            ? props.errorStyles
            : props.styles
        }
        value={props.value}
        options={props.options}
        isSearchable={props.isSearchable}
        name={props.name}
        onChange={props.onChange}
        id={props.id}
        isMulti={props.isMultiple}
        isDisabled={props.disabled}
      />
      <h3 className={`select__label__below ${props.error ? "error" : ""}`}>
        {props.error && props.error.message}
      </h3>
    </div>
  );
};
export default CustomSelect;
