import React, { useState, useEffect, useReducer } from "react";

type ParallaxProps = {
  url?: string;
  type?: string;
};

const Parallax = (props: ParallaxProps) => {
  return (
    <div
      className="h-72 bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `
          url("${props.url}")`,
      }}
    ></div>
  );
};

export default Parallax;
