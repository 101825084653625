import React, { useEffect, useState } from "react";
import {
  TusUploady,
  useRequestPreSend,
  useItemAbortListener,
  useItemFinalizeListener,
  useItemStartListener,
  useAbortAll,
} from "@rpldy/tus-uploady";

import { asUploadButton } from "@rpldy/upload-button";
import FileUploadProgress from "../FileUploadProgress";
import { XIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";

interface FileUploadTusProps {
  onChange: any;
}

const url = "/api/assets/files";

const UploadFile = ({ onChange, ...buttonProps }: any) => {
  // const refreshCsrf = useRefreshCsrf();

  useRequestPreSend(async ({ items }) => {
    if (items.length === 0) {
      return {};
    }

    // const token = await refreshCsrf();

    // we should only have 1 file for a TUS upload
    return {
      options: {
        destination: {
          params: {
            "file-name": items[0].file.name,
            "file-type": items[0].file.type,
          },
          // headers: {
          //   "X-CSRF-Token": token,
          // },
        },
      },
    };
  });

  useItemAbortListener((item) => {
    if (onChange) {
      onChange("");
    }
  });

  useItemFinalizeListener((item) => {
    if (!onChange) {
      return;
    }

    const id = item?.uploadResponse?.location || "";
    const index = id.lastIndexOf("/");

    if (index >= 0) {
      onChange(id.substr(index + 1));
      return;
    }

    onChange(id);
  });

  return (
    <button
      {...buttonProps}
      type="button"
      className="rounded-md border-2 border-transparent bg-inflow-purple px-8 py-2 text-sm font-semibold text-white shadow-lg outline-inflow-purple transition hover:border-inflow-purple hover:bg-white hover:text-inflow-purple"
    >
      Upload File
    </button>
  );
};

const CustomUploadButton = asUploadButton((props: any) => {
  return <UploadFile {...props} />;
});

const FilePreview = () => {
  const [fileName, setFileName] = useState("");
  const { setFieldValue } = useFormikContext();
  const abortAll = useAbortAll();

  useItemStartListener((item: any) => {
    setFileName(item.file.name);
  });

  const onClickCancel = () => {
    setFileName("");
    abortAll();
    setFieldValue("attachment_id", "");
  };

  if (!fileName) {
    return <></>;
  }

  return (
    <div className="mt-2 flex items-center break-all text-sm font-semibold sm:whitespace-nowrap">
      <div>
        <XIcon
          onClick={onClickCancel}
          className="inline-block h-7 w-7 text-red-700 hover:cursor-pointer"
        />
      </div>
      <div>{fileName}</div>
    </div>
  );
};

const FileUploadTus: React.FC<FileUploadTusProps> = ({ onChange }) => {
  return (
    <TusUploady
      destination={{ url: url, headers: { "X-CSRF-Token": "" } }}
      chunkSize={2142880}
      sendDataOnCreate
      multiple={false}
    >
      <div className="flex flex-col">
        <FileUploadProgress />
        <CustomUploadButton extraProps={{ onChange }} />
        <FilePreview />
      </div>
    </TusUploady>
  );
};

export default FileUploadTus;
