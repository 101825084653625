import React, { useState, useRef, useEffect } from "react";
import { PrizeClaimComponentProps } from "types/Wizard";
import { validSSN } from "utils";
import { validateFields } from "domains/forms/lib/FW9";
import { W9Form, W9FormSubmitInput } from "types/Forms";

// components
import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Input from "components/Input";
import Radio from "components/Radio";
import RadioGroup from "components/RadioGroup";
import SignatureCanvas from "components/SignatureCanvas";
import SSNInput from "components/SocialSecurityInput";
import Toast from "components/Toast";
import { useTaxFormSubmitMutation } from "api/graphql";

const IrsW9Form = (props) => {
  const {
    context,
    next,
    wizardError,
    sendState,
    wizardStates,
  }: PrizeClaimComponentProps = props;

  const sigCanvas = useRef(null);

  const [values, setValues] = useState({
    name: "",
    business_name: "",
    // TODO: COMBINE tax_classification_code & THIS INTO ONE
    federal_tax_classification: {
      id: "",
      value: "",
    },
    tax_classification_code: "",
    exempt_payee_code: "",
    exempt_fatca_reporting_code: "",
    other: "",
    address: "",
    requester_name_address: "",
    city_state_and_zip_code: "",
    list_account_numbers: "",
    social_security_number: "",
    employer_identification_number: "",
    signature_data_url: "",
    date: new Date(),
  });

  const [inputErrors, setInputErrors] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [confirmError, setConfirmError] = useState(null);
  const [hasBeenPopulated, setHasBeenPopulated] = useState(false);

  const { mutateAsync: submitTaxForm } = useTaxFormSubmitMutation({
    onSettled: (data) => {
      if (data) {
        sendState("irstaxValid", data.taxFormSubmit.submitted);
        setConfirmSuccess(data.taxFormSubmit.submitted);
        setConfirmLoading(false);
      }
    },
  });

  useEffect(() => {
    if (context.irstax && context.irstax.w9) {
      populateForm(context.irstax.w9);
    }
  }, [context]);

  useEffect(() => {
    if (props.error) {
      setInputErrors([...inputErrors, props.error]);
      return;
    }
  }, [props.error]);

  const populateForm = (data: W9Form) => {
    setValues({
      ...values,
      name: data.name,
      business_name: data.businessName,
      federal_tax_classification: taxClassifications.find(
        (x) => x.value === data.federalTaxClassification
      ),
      tax_classification_code: data.federalTaxClassificationLLC,
      other: data.federalTaxClassificationOther,
      exempt_payee_code: data.exemptPayeeCode,
      exempt_fatca_reporting_code: data.exemptFactaCode,
      address: data.address,
      requester_name_address: data.requestersNameAddress,
      city_state_and_zip_code: data.cityStateZip,
      list_account_numbers: data.accountNumbers,
    });
    setHasBeenPopulated(true);
  };

  const resetInputErrors = (fieldName?: string) => {
    if (fieldName) {
      const index = inputErrors.findIndex((x) => x.id === fieldName);
      if (index === -1) return;
      inputErrors.splice(index, 1);
      setInputErrors(inputErrors);
      return;
    }
    setInputErrors([]);
  };

  const handleRadioChange = (e) => {
    resetInputErrors();
    setValues({
      ...values,
      federal_tax_classification: {
        id: e.target.id,
        value: e.target.value,
      },
      // reset these two just incase
      tax_classification_code: "",
      other: "",
    });
  };

  const handleInputChange = (e) => {
    resetInputErrors(e.target.name);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const resetCanvas = () => {
    sigCanvas.current.clear();
    setValues({
      ...values,
      signature_data_url: "",
    });
  };

  // handle click away for ssn
  const ssnHandleBlur = async (e, value) => {
    // validate ssn
    validSSN(value)
      .then((res) => {
        resetInputErrors();
        setValues({
          ...values,
          social_security_number: value,
        });
        return;
      })
      .catch(() =>
        setInputErrors([
          ...inputErrors,
          {
            id: "social_security_number",
            message: "Invalid SSN, check your input and try again",
          },
        ])
      );
  };

  const handleSignatureEnd = (e) => {
    setValues({
      ...values,
      signature_data_url: sigCanvas.current.getTrimmedCanvas().toDataURL(),
    });
  };

  const handleSubmit = async () => {
    wizardError(null);
    try {
      await validateFields(values);
    } catch (errors) {
      setInputErrors(errors);
      return;
    }

    const w9Input: W9FormSubmitInput = {
      name: values.name,
      businessName: values.business_name,
      federalTaxClassification: values.federal_tax_classification.value,
      federalTaxClassificationLLC: values.tax_classification_code,
      federalTaxClassificationOther: values.other,
      exemptPayeeCode: values.exempt_payee_code,
      exemptFactaCode: values.exempt_fatca_reporting_code,
      address: values.address,
      cityStateZip: values.city_state_and_zip_code,
      requestersNameAddress: values.requester_name_address,
      accountNumbers: values.list_account_numbers,
      ssn: values.social_security_number,
      ein: values.employer_identification_number,
      signatureDataUrl: values.signature_data_url,
    };
    const input = {
      prizeId: context.prize.id,
      country: wizardStates.countrySelect.country,
      w9: w9Input,
      w8ben: null,
    };

    setConfirmLoading(true);
    submitTaxForm({ input });
  };

  next(() => {
    if (!confirmSuccess) {
      wizardError({ message: "Form must be submitted before continuing" });
      return false;
    } else {
      return true;
    }
  });

  return (
    <div className="form__tax__w-9 tax-form__wrapper">
      {hasBeenPopulated && (
        <div className="prize-payout__toast mb-3">
          <Toast
            type="info"
            message="This form has been pre-filled with previous data, please double check all of the inputs before submitting!"
            closeable
          />
        </div>
      )}
      <div className="form__tax__header">
        <h1>Request for Taxpayer Identification Number and Certification</h1>
        <h3>
          Go to{" "}
          <a target="_blank" href="https://www.irs.gov/FormW9">
            www.irs.gov/FormW9
          </a>{" "}
          for instructions and the latest information
        </h3>
      </div>

      <div className="form__tax__w-9__section-1">
        <Input
          label="1 - Name (as shown on your income tax return)"
          name="name"
          value={values.name}
          onChange={(e) => handleInputChange(e)}
          error={
            inputErrors.some((x) => x.id === "name") &&
            inputErrors.find((x) => x.id === "name")
          }
        />
      </div>
      {/* end section 1 */}

      <div className="form__tax__w-9__section-2">
        <Input
          label="2 - Business name/disregarded entity name, if different from above"
          name="business_name"
          value={values.business_name}
          onChange={(e) => handleInputChange(e)}
          error={
            inputErrors.some((x) => x.id === "business_name") &&
            inputErrors.find((x) => x.id === "business_name")
          }
        />
      </div>
      {/* end section 2 */}

      <div className="form__tax__w-9__section-3">
        <div className="form__tax__w-9__section-3__left">
          <RadioGroup
            label="3 - Check appropriate box for federal tax classification of the person whose name is entered on line 1. Check only one of the
        following seven boxes."
            error={
              inputErrors.some((x) => x.id === "federal_tax_classification") &&
              inputErrors.find((x) => x.id === "federal_tax_classification")
            }
          >
            <Radio
              id="item_1"
              checked={values.federal_tax_classification.id === "item_1"}
              name="federal_tax_classification"
              label="Individual/sole proprietor or single-member LLC"
              value="Individual/sole proprietor or single-member LLC"
              onChange={(e) => handleRadioChange(e)}
            />
            <Radio
              id="item_2"
              checked={values.federal_tax_classification.id === "item_2"}
              name="federal_tax_classification"
              label="C Corporation"
              value="C Corporation"
              onChange={(e) => handleRadioChange(e)}
            />
            <Radio
              id="item_3"
              checked={values.federal_tax_classification.id === "item_3"}
              name="federal_tax_classification"
              label="S Corporation"
              value="S Corporation"
              onChange={(e) => handleRadioChange(e)}
            />
            <Radio
              id="item_4"
              checked={values.federal_tax_classification.id === "item_4"}
              name="federal_tax_classification"
              label="Partnership"
              value="Partnership"
              onChange={(e) => handleRadioChange(e)}
            />
            <Radio
              id="item_5"
              checked={values.federal_tax_classification.id === "item_5"}
              name="federal_tax_classification"
              label="Trust/estate"
              value="Trust/estate"
              onChange={(e) => handleRadioChange(e)}
            />
            <Radio
              id="item_6"
              checked={values.federal_tax_classification.id === "item_6"}
              name="federal_tax_classification"
              label="Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)"
              value="LLC"
              onChange={(e) => handleRadioChange(e)}
            />
            {values.federal_tax_classification.id === "item_6" && (
              <Input
                label="Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)"
                name="tax_classification_code"
                value={values.tax_classification_code}
                onChange={(e) => handleInputChange(e)}
                className="form__tax__w-9__tax-classification-code"
                error={
                  inputErrors.some((x) => x.id === "tax_classification_code") &&
                  inputErrors.find((x) => x.id === "tax_classification_code")
                }
              />
            )}
            <div className="form__note">
              Note: Check the appropriate box in the line above for the tax
              classification of the single-member owner. Do not check LLC if the
              LLC is classified as a single-member LLC that is disregarded from
              the owner unless the owner of the LLC is another LLC that is not
              disregarded from the owner for U.S. federal tax purposes.
              Otherwise, a single-member LLC that is disregarded from the owner
              should check the appropriate box for the tax classification of its
              owner.
            </div>
            <Radio
              id="item_7"
              checked={values.federal_tax_classification.id === "item_7"}
              name="federal_tax_classification"
              label="Other (see instructions)"
              value="Other"
              onChange={(e) => handleRadioChange(e)}
            />
            {values.federal_tax_classification.id === "item_7" && (
              <Input
                label="Other (see instructions)"
                name="other"
                value={values.other}
                onChange={(e) => handleInputChange(e)}
                className="form__tax__w-9__tax-classification-code"
                error={
                  inputErrors.some((x) => x.id === "other") &&
                  inputErrors.find((x) => x.id === "other")
                }
              />
            )}
          </RadioGroup>
        </div>
        <div className="form__tax__w-9__section-3__right">
          <h1 className="form__heading__label">
            4 - Exemptions (codes apply only to certain entities, not
            individuals; see instructions on page 3):
          </h1>
          <Input
            label="Exempt payee code (if any)"
            name="exempt_payee_code"
            value={values.exempt_payee_code}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "exempt_payee_code") &&
              inputErrors.find((x) => x.id === "exempt_payee_code")
            }
          />
          <Input
            label="Exemption from FATCA reporting code (if any)"
            name="exempt_fatca_reporting_code"
            value={values.exempt_fatca_reporting_code}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "exempt_fatca_reporting_code") &&
              inputErrors.find((x) => x.id === "exempt_fatca_reporting_code")
            }
          />
          <p className="form__note">
            (Applies to accounts maintained outside the U.S.)
          </p>
        </div>
      </div>
      {/* end section 3 */}
      <div className="form__tax__w-9__section-4">
        <Input
          label="Requester's name and address (optional)"
          name="requester_name_address"
          value={values.requester_name_address}
          onChange={(e) => handleInputChange(e)}
          error={
            inputErrors.some((x) => x.id === "requester_name_address") &&
            inputErrors.find((x) => x.id === "requester_name_address")
          }
        />
        <Input
          label="5 - Address (number, street, and apt. or suite no.) See instructions."
          name="address"
          value={values.address}
          onChange={(e) => handleInputChange(e)}
          error={
            inputErrors.some((x) => x.id === "address") &&
            inputErrors.find((x) => x.id === "address")
          }
        />
        <Input
          label="6 - City, state, and ZIP code"
          name="city_state_and_zip_code"
          value={values.city_state_and_zip_code}
          onChange={(e) => handleInputChange(e)}
          error={
            inputErrors.some((x) => x.id === "city_state_and_zip_code") &&
            inputErrors.find((x) => x.id === "city_state_and_zip_code")
          }
        />
        <Input
          label="7 - List account number(s) here (optional)"
          name="list_account_numbers"
          value={values.list_account_numbers}
          onChange={(e) => handleInputChange(e)}
          error={
            inputErrors.some((x) => x.id === "list_account_numbers") &&
            inputErrors.find((x) => x.id === "list_account_numbers")
          }
        />
      </div>
      <div className="form__tax__w-9__section-5">
        <div className="form__heading">
          <h1>
            <span>Part I</span>Taxpayer Identification Number (TIN)
          </h1>
        </div>
        <div className="form__content">
          Enter your TIN in the appropriate box. The TIN provided must match the
          name given on line 1 to avoid backup withholding. For individuals,
          this is generally your social security number (SSN). However, for a
          resident alien, sole proprietor, or disregarded entity, see the
          instructions for Part I, later. For other entities, it is your
          employer identification number (EIN). If you do not have a number, see
          How to get a TIN, later. Note: If the account is in more than one
          name, see the instructions for line 1. Also see What Name and Number
          To Give the Requester for guidelines on whose number to enter.
        </div>
        <div className="form__tax__w-9__section-5__inputs">
          <SSNInput
            handleBlur={ssnHandleBlur}
            error={
              inputErrors.some((x) => x.id === "social_security_number") &&
              inputErrors.find((x) => x.id === "social_security_number")
            }
          />
          <span id="inputs__or">OR</span>
          <Input
            label="Employer identification number"
            name="employer_identification_number"
            value={values.employer_identification_number}
            onChange={(e) => handleInputChange(e)}
            maxLength={9}
            autoComplete="off"
            placeholder="123456789"
            error={
              inputErrors.some(
                (x) => x.id === "employer_identification_number"
              ) &&
              inputErrors.find((x) => x.id === "employer_identification_number")
            }
          />
        </div>
      </div>
      <div className="form__tax__w-9__section-6">
        <div className="form__heading">
          <h1>
            <span>Part II</span>Certification
          </h1>
        </div>
        <div className="form__content">
          Under penalties of perjury, I certify that:
          <ol>
            <li>
              1. The number shown on this form is my correct taxpayer
              identification number (or I am waiting for a number to be issued
              to me); and
            </li>
            <li>
              2. I am not subject to backup withholding because: (a) I am exempt
              from backup withholding, or (b) I have not been notified by the
              Internal Revenue Service (IRS) that I am subject to backup
              withholding as a result of a failure to report all interest or
              dividends, or (c) the IRS has notified me that I am no longer
              subject to backup withholding; and
            </li>
            <li>
              3. I am a U.S. citizen or other U.S. person (defined below); and
            </li>
            <li>
              4. The FATCA code(s) entered on this form (if any) indicating that
              I am exempt from FATCA reporting is correct. Certification
              instructions. You must cross out item 2 above if you have been
              notified by the IRS that you are currently subject to backup
              withholding because you have failed to report all interest and
              dividends on your tax return. For real estate transactions, item 2
              does not apply. For mortgage interest paid, acquisition or
              abandonment of secured property, cancellation of debt,
              contributions to an individual retirement arrangement (IRA), and
              generally, payments other than interest and dividends, you are not
              required to sign the certification, but you must provide your
              correct TIN. See the instructions for Part II, later.
            </li>
          </ol>
        </div>
        <div className="form__tax__w-9__section-6__signature">
          <div className="form__heading">
            <h1>Sign here</h1>
          </div>
          <div className="form__tax__w-9__section-6__signature__canvas__container">
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 450,
                height: 55,
                className: "form__tax__w-9__section-6__signature__canvas",
              }}
              ref={sigCanvas}
              onEnd={handleSignatureEnd}
              velocityFilterWeight={1.5}
              minDistance={3}
            />
            <Button label="Reset" outline small onClick={() => resetCanvas()} />
          </div>
          {inputErrors.some((x) => x.id === "signature") && (
            <span className="form__tax__w-9__section-6__signature__error">
              {inputErrors.find((x) => x.id === "signature").message}
            </span>
          )}
        </div>
      </div>
      <div className="mt-4 flex flex-align-center">
        <Button
          className=""
          label="Confirm your details"
          color="green"
          onClick={handleSubmit}
        />
        {confirmLoading && <CircularProgress className="ml-2" sm inverse />}
        {confirmSuccess && !confirmError && (
          <span className="green bold ml-2">Form successfully submitted.</span>
        )}
      </div>
    </div>
  );
};

export default IrsW9Form;

const taxClassifications = [
  {
    id: "item_1",
    value: "Individual/sole proprietor or single-member LLC",
  },
  {
    id: "item_2",
    value: "C Corporation",
  },
  {
    id: "item_3",
    value: "S Corporation",
  },
  {
    id: "item_4",
    value: "Partnership",
  },
  {
    id: "item_5",
    value: "Trust/estate",
  },
  {
    id: "item_6",
    value: "LLC",
  },
  {
    id: "item_7",
    value: "Other",
  },
];
