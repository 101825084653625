import React from 'react';
import CompetitionSuggestionItem from './CompetitionSuggestionItem';

type CompetitionSuggestionsProps = {
  competitions: any[]
}

const CompetitionSuggestions = (props: CompetitionSuggestionsProps) => {

  const { competitions } = props;

  return (
    <div className="comp-suggestions">
      {
        competitions && competitions.map((c, k) => <CompetitionSuggestionItem key={k} competition={c} />
        )
      }
    </div>
  );
};
export default CompetitionSuggestions;