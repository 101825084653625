import FinalizeWizard from 'components/FinalizeForm/FinalizeWizard';
import Modal from 'components/Modal';

export type FinalizeModalProps = {
  visible: boolean
  type: string
  data: any
  toggleModal: Function
}

export enum FinalizeTypes {
  COMPETITION = "Competition",
  SHOWMATCH = "Showmatch",
}

const FinalizeModal = (props: FinalizeModalProps) => {
  return (
    <Modal visible={props.visible} toggleModal={props.toggleModal}>
      <FinalizeWizard
        type={props.type}
        data={props.data}
      />
    </Modal>
  );
};
export default FinalizeModal;