import TusUploady from "@rpldy/tus-uploady";
import { UploadDropButton } from "..";

const url = "/api/assets/files";

const UploadWithCrop = ({ onChange, cropValues }: any) => {
  return (
    <TusUploady
      // destination={{ url: url, headers: { "X-CSRF-Token": "2" } }} needed for inflow
      destination={{ url: url, headers: { "X-CSRF-Token": "2" } }}
      chunkSize={2142880}
      sendDataOnCreate
      multiple={false}
    >
      <UploadDropButton extraProps={{ onChange, cropValues, crop: true }} />
    </TusUploady>
  );
};

export default UploadWithCrop;
