import React from 'react';

type ButtonProps = {
  label: any
  className?: string
  outline?: boolean
  inverse?: boolean
  small?: boolean
  glow?: boolean
  disabled?: boolean
  fullWidth?: boolean
  onClick?: any
  color?: string
}

const Button = (props: ButtonProps) => {
  const {
    label,
    className,
    outline,
    inverse,
    small,
    glow,
    disabled,
    fullWidth,
    onClick,
    color
  } = props;

  const wrapped = () => {
    if (disabled) {
      return
    }

    onClick()
  }

  return (
    <span
      onClick={wrapped}
      className={
        `
      btn
      ${className ? className : ''}
      ${!outline ? 'btn__before' : ''}
      ${small ? 'btn__small' : ''}
      ${outline ? 'btn__outline' : ''}
      ${inverse ? 'inverse' : ''}
      ${disabled ? 'btn__disabled' : ''}
      ${fullWidth ? 'btn__full-width' : ''}
      ${glow ? 'btn__glow' : ''}
      ${color ? 'bg-' + color : ''}
      `
      }>
      <span className="btn__label">{label}</span>
    </span>
  );
};
export default Button;