import NewCompetitionWizard from "components/NewCompetitionWizard";
import React from "react";
import { Link } from "react-router-dom";

const CreateCompetition = () => {
  const onSubmit = () => {
    alert("Submitted");
  };

  return (
    // -mt-16 pt-16 just so we can render the noise background without having it on body (putting on the div below instead)
    <div
      style={{ backgroundImage: "url('/ctx_bg.png')" }}
      className="-mt-16 flex min-h-screen items-center pt-16 text-white"
    >
      <div className="mx-auto w-full max-w-7xl content-center justify-between p-4">
        <NewCompetitionWizard data={{}} onSubmit={onSubmit} isLoading={false} />
        {/* testing out text stroke for some design aesthetics
        <h1 className="text-stroke scale-y-150 text-center text-10xl font-black tracking-normal text-transparent">
          CONTEXT.GG
        </h1> */}
      </div>
    </div>
  );
};

export default CreateCompetition;
