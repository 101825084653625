import CommunityCard from "components/CommunityCard";
import { Link } from "react-router-dom";
import React from "react";
import { ContextProfile } from "types/Profile";

type MyCommunitiesProps = {
  profile: any;
};

const MyCommunities = ({ profile }: MyCommunitiesProps) => {
  return (
    <div className="profile-v2__content__container profile-v2__communities">
      <div className="profile-v2__content__header">
        <h1>Your Communities</h1>
        <h3>A list of all Context Communities you are a part of</h3>
      </div>
      <div className="profile-v2__communities__wrapper">
        {profile.communities && profile.communities.edges.length > 0 ? (
          profile.communities.edges.map((c, k) => {
            return <CommunityCard key={k} data={c.node} small />;
          })
        ) : (
          <div className="profile-v2__communities__none">
            <h1>You aren't a part of any Context Communities</h1>
            <h3>
              Feel free to{" "}
              <Link to="/create/community">
                create one now
              </Link>
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};
export default MyCommunities;
