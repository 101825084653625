import {
  CakeIcon,
  CogIcon,
  CollectionIcon,
  HomeIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";

type CommunityViewProps = {
  community: any;
  communityAdmin: any;
  user: any;
  children: any;
};

const NewLayout = ({
  community,
  communityAdmin,
  user,
  children,
}: CommunityViewProps) => {
  const location = useLocation();
  const communityId = community.alias != "" ? community.alias : community.id;

  const links = [
    {
      name: community.name,
      description: `${community.name} Administration`,
      href: `/${communityId}/admin`,
      Icon: HomeIcon,
    },
    {
      name: "Orders",
      description: "A list orders relating to various transactions",
      href: `/${communityId}/admin/orders`,
      Icon: ShoppingCartIcon,
    },
    {
      name: "Winners",
      description:
        "A list of all players who have won prizes in Context competitions",
      href: `/${communityId}/admin/winners`,
      Icon: CakeIcon,
    },
    {
      name: "Settings",
      description: "Control various aspects of your Context Community",
      href: `/${communityId}/admin/settings`,
      Icon: CogIcon,
    },
  ];

  // get current page object from links
  const currentPage = links.find((link) => link.href === location.pathname);

  return (
    <div className="flex flex-col  text-white">
      <div className="z-10 mx-auto flex w-full flex-initial justify-center space-x-6 bg-context-gray text-neutral-400 sm:space-x-10">
        {links.map((link) => (
          <Link
            key={link.name}
            to={link.href}
            className={classNames(
              location.pathname == link.href
                ? "border-ctx-orange text-white"
                : "border-transparent",
              " border-b-2 py-2 font-semibold transition hover:text-neutral-300"
            )}
          >
            <link.Icon className="-mt-1 mr-0.5 inline-block h-6 w-6" />{" "}
            {link.name}
          </Link>
        ))}
      </div>
      <img
        src={community.banner || "/placeholder-banner-dark.png"}
        className="h-52 object-cover opacity-75"
      />
      <div className="container z-10 mx-auto -mt-6 w-full px-4 transition-all sm:px-6 lg:px-8">
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-4">
            <img
              src={community.logo || "/no-image.jpg"}
              className=" h-[100px] rounded"
            />
            <div>
              <h1 className="text-4xl font-semibold">{community.name}</h1>
              {/* <h2>{currentPage.description}</h2> */}
            </div>
          </div>
          <Link
            to={`/${communityId}`}
            className="mx-4 rounded bg-gradient-to-br from-ctx-dark-orange via-ctx-orange to-ctx-dark-orange bg-size-150 bg-pos-0 px-4 py-2 font-semibold text-white transition-all duration-500 hover:bg-pos-100"
          >
            Exit Admin
          </Link>
        </div>
        <div className="space-y-7 divide-y divide-neutral-700">
          <div className="mt-10 space-y-1">
            <h2 className="text-4xl font-semibold">{currentPage.name}</h2>
            <p className="text-lg text-neutral-300">
              {currentPage.description}
            </p>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default NewLayout;
