import Parallax from "components/Parallax";
import { Layout, ExternalProfiles } from "domains/profile/components";
import { useProfileDetailsQuery } from "api/graphql";
import LoadingData from "components/LoadingData";

const ExternalProfilesView = () => {
  const { data, isLoading } = useProfileDetailsQuery();

  if (isLoading) {
    return <LoadingData />;
  }

  return (
    <>
      <Layout user={data.profile.user}>
        <ExternalProfiles profile={data.profile} />
      </Layout>
    </>
  );
};

export default ExternalProfilesView;
