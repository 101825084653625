import { StandardFonts, rgb } from 'pdf-lib';

// height of our ssn input
const ssnY = 353;
// height of our ein input
const einY = 400;

export const inputCoordinatesMap = {
  name: {
    x: 65,
    y: 105,
  },
  businessName: {
    x: 65,
    y: 128,
  },
  federalTaxClassification: {
    'Individual/sole proprietor or single-member LLC': {
      x: 66,
      y: 166,
    },
    'C Corporation': {
      x: 180.5,
      y: 166,
    },
    'S Corporation': {
      x: 252.5,
      y: 166,
    },
    Partnership: {
      x: 324.5,
      y: 166,
    },
    'Trust/estate': {
      x: 396.5,
      y: 166,
    },
    LLC: {
      x: 66,
      y: 191,
    },
    Other: {
      x: 66,
      y: 238,
    },
  },
  federalTaxClassificationLLC: {
    x: 420,
    y: 190,
  },
  federalTaxClassificationOther: {
    x: 166,
    y: 237,
  },
  exemptPayeeCode: {
    x: 546,
    y: 178,
  },
  exemptFactaCode: {
    x: 505,
    y: 214,
  },
  address: {
    x: 65,
    y: 260,
  },
  requestersNameAddress: {
    x: 397,
    y: 262,
  },
  cityStateZip: {
    x: 65,
    y: 284,
  },
  accountNumbers: {
    x: 65,
    y: 308,
  },
  ssn: {
    digit_1: {
      x: 421,
      y: ssnY,
    },
    digit_2: {
      x: 436,
      y: ssnY,
    },
    digit_3: {
      x: 451,
      y: ssnY,
    },
    digit_4: {
      x: 479,
      y: ssnY,
    },
    digit_5: {
      x: 494,
      y: ssnY,
    },
    digit_6: {
      x: 521,
      y: ssnY,
    },
    digit_7: {
      x: 536,
      y: ssnY,
    },
    digit_8: {
      x: 551,
      y: ssnY,
    },
    digit_9: {
      x: 566,
      y: ssnY,
    },
  },
  ein: {
    digit_1: {
      x: 421,
      y: einY,
    },
    digit_2: {
      x: 436,
      y: einY,
    },
    digit_3: {
      x: 464,
      y: einY,
    },
    digit_4: {
      x: 479,
      y: einY,
    },
    digit_5: {
      x: 493,
      y: einY,
    },
    digit_6: {
      x: 508,
      y: einY,
    },
    digit_7: {
      x: 522,
      y: einY,
    },
    digit_8: {
      x: 537,
      y: einY,
    },
    digit_9: {
      x: 552,
      y: einY,
    },
  },
  signatureDataUrl: {
    x: 135,
    y: 560,
  },
  date: {
    x: 421,
    y: 560,
  },
};

export const updateW9Fields = async (values, pdfDoc) => {
  const firstPage = pdfDoc.getPages()[0];
  const { height } = firstPage.getSize();
  const font = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const fontSize = 10;
  const fontColor = rgb(0, 0, 0);

  Object.keys(values).map(async field => {
    const coords = inputCoordinatesMap[field];
    if (!coords) return;

    const v = values[field];
    if (!v) {
      return;
    }

    switch (field) {
      case 'federalTaxClassification':
        const inner = coords[v];
        firstPage.drawText('X', {
          x: inner.x,
          y: height - inner.y,
          size: fontSize,
          font,
          color: fontColor,
        });

        break;
      case 'requestersNameAddress':
        firstPage.drawText(v, {
          x: coords.x,
          y: height - coords.y,
          size: fontSize,
          font,
          maxWidth: 188,
          lineHeight: 12,
          color: fontColor,
        });
        break;
      case 'ssn':
        const ssn = v.split('');
        ssn.forEach((d, k) => {
          const digitCoords = coords[`digit_${k + 1}`];
          firstPage.drawText(d, {
            x: digitCoords.x,
            y: height - digitCoords.y,
            size: fontSize + 2,
            font,
            color: fontColor,
          });
        });
        break;
      case 'ein':
        const ein = v.split('');
        ein.forEach((d, k) => {
          const digitCoords = coords[`digit_${k + 1}`];
          firstPage.drawText(d, {
            x: digitCoords.x,
            y: height - digitCoords.y,
            size: fontSize + 2,
            font,
            color: fontColor,
          });
        });
        break;
      case 'signatureDataUrl':
        const signature = await pdfDoc.embedPng(v);
        const signatureDims = signature.scale(0.25);

        firstPage.drawImage(signature, {
          x: coords.x,
          y: height - coords.y,
          width: signatureDims.width,
          height: signatureDims.height,
        });
        break;
      case 'date':
        const date = new Date(v);
        firstPage.drawText(date.toLocaleDateString(), {
          x: coords.x,
          y: height - coords.y,
          size: fontSize,
          font,
          color: fontColor,
        });
        break;
      default:
        firstPage.drawText(v.value || v, {
          x: coords.x,
          y: height - coords.y,
          size: fontSize,
          font,
          color: fontColor,
        });
        break;
    }
  });
};

const fieldRequiredError = 'This is a required field';
const oneOfFieldRequiredError = 'At least one input is required';

export const validateFields = async values => {
  const errors = [];

  return new Promise((resolve, reject) => {
    Object.keys(values).map((field) => {
      switch (field) {
        case 'name':
          if (!values[field]) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case 'business_name':
          // this is an optional field, return if it's not there
          if (!values[field]) break;
          const reg = new RegExp('^.*[a-z]|[0-9]|-|&$');
          const valid = reg.test(values[field]);
          if (!valid) {
            errors.push({
              id: field,
              message:
                'Business name entered is not valid, must only include A-Z, 0-9, - or &',
            });
            break;
          }
          break;
        case 'federal_tax_classification':
          if (!values[field].value) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case 'tax_classification_code':
          if (
            values.federal_tax_classification.id === 'item_6' &&
            !values[field]
          ) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case 'other':
          if (
            values.federal_tax_classification.id === 'item_7' &&
            !values[field]
          ) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case 'address':
          if (!values[field]) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case 'city_state_and_zip_code':
          if (!values[field]) {
            errors.push({
              id: field,
              message: fieldRequiredError,
            });
            break;
          }
          break;
        case 'social_security_number':
          if (!values[field] && !values.employer_identification_number) {
            errors.push({
              id: field,
              message: oneOfFieldRequiredError,
            });
            break;
          }
          break;
        case 'employer_identification_number':
          if (!values[field] && !values.social_security_number) {
            errors.push({
              id: field,
              message: oneOfFieldRequiredError,
            });
            break;
          }
          break;
        case 'signature_data_url':
          if (!values[field]) {
            errors.push({
              id: field,
              message: 'A signature is required',
            });
            break;
          }
          break;
        default:
          break;
      }
    });
    if (errors.length > 0) reject(errors);

    resolve(true);
  });
};
