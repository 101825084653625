import React from "react";

// type for prop
import { TeamMemberLink as TeamMemberLinkProps } from "./TeamMember";

const TeamMemberLink = (props: TeamMemberLinkProps) => {
  return (
    <a
      target="_blank"
      key={props.url}
      href={props.type === "email" ? `mailto:${props.url}` : props.url}
      className={`team-member__link ${props.type}`}
    >
      <img src={logoUrlResolver(props.type)} alt={props.type + " Logo"} />
    </a>
  );
};

export default TeamMemberLink;

const logoUrlResolver = (type: string): string => {
  switch (type) {
    case "twitter":
      return "/twitter-logo-square.png";
    case "linkedin":
      return "/linkedin-logo.png";
    case "email":
      return "/email.png";
  }
};
