import WantFries from "components/Order/WantFries";
import CircularProgress from "components/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetCompetitionListQuery,
  useGetCompletedOrderQuery,
} from "api/graphql";

const OrderCompleted = () => {
  const { id } = useParams();
  const nav = useNavigate();

  const compsFilter = {
    states: ["Created", "Active"],
  };

  const initialVariables = { filter: compsFilter, pageSize: 6 };

  const {
    data: orderData,
    isLoading: orderLoading,
    error: orderError,
  } = useGetCompletedOrderQuery({
    id: id,
  });

  const { data: competitionData, isLoading: competitionLoading } =
    useGetCompetitionListQuery(initialVariables);

  const handleClick = () => {
    nav("/");
  };

  return (
    <div>
      <div className="parallax" />
      <div className="order-complete">
        {orderLoading || competitionLoading ? (
          <CircularProgress lg />
        ) : (
          <WantFries
            error={orderError}
            order={orderData && orderData.order}
            competitions={competitionData && competitionData.competitions.edges}
            handleClick={handleClick}
          />
        )}
      </div>
    </div>
  );
};
export default OrderCompleted;
