import React, { useState } from "react";
import { DonationWizardStates } from "types/Wizard";

// components
import DonateAmount from "components/DonationWizardSteps/Amount";
import DonatePaymentMethod from "components/DonationWizardSteps/Checkout";
import Wizard from "components/Wizard";

// icons
import moneyBill from "@iconify/icons-uil/money-bill";
import shoppingBag from "@iconify/icons-uil/shopping-bag";
import Modal from "components/Modal";
import { useNavigate } from "react-router-dom";
import { useNewCompetitionDonationMutation } from "api/graphql";
import { useMyProfile } from "lib/profile";

type DonationModalProps = {
  user: any;
  competitionData: any;
  visible: boolean;
  toggleModal: Function;
};

const DonationModal = (props: DonationModalProps) => {
  const nav = useNavigate();
  const user = useMyProfile();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { mutateAsync: newDonation } = useNewCompetitionDonationMutation({
    onSettled: (data, error) => {
      if (data.competitionDonationNew)
        nav(`/order/confirmation/${data.competitionDonationNew.id}`);

      if (error) {
        setLoading(false);
        setError(error);
      }
    },
  });

  const steps = [
    {
      stepName: "Amount",
      stepDescription: "Enter your desired contribution amount",
      stepIcon: moneyBill,
      stepId: 1,
      stepComponent: DonateAmount,
    },
    {
      stepName: "Checkout",
      stepDescription: "Select the method used to complete your contribution",
      stepIcon: shoppingBag,
      stepId: 2,
      stepComponent: DonatePaymentMethod,
    },
  ];

  const finalClick = async (states: DonationWizardStates) => {
    if (!states.checkout)
      return setError({
        message:
          "At least one payment method must be selected for this competition",
      });

    // no payments selected for a competition with a prize
    if (states.contribution.amount > 0 && states.checkout.options.length === 0)
      return setError({
        message:
          "At least one payment method must be selected for this competition",
      });

    // check if the checkout payment options are valid
    if (!states.checkout.isValid)
      return setError({ message: "Selected payment options are invalid." });

    setLoading(true);

    // build our payment configs
    const paymentConfigs = states.checkout.options;

    // build the new competition input
    const input = {
      competitionId: props.competitionData.id,
      amount: states.contribution.amount,
      currency: states.contribution.currency,
      paymentConfig: paymentConfigs,
    };

    newDonation({ input });
  };

  return (
    <Modal visible={props.visible} toggleModal={props.toggleModal}>
      <div className="donate-modal">
        <Wizard
          steps={steps}
          finalClick={finalClick}
          context={{ user: props.user, data: props.competitionData }}
          noNegativeMargin={true}
          forceLoading={loading}
          forceError={error}
          noHeaders={true}
        />
      </div>
    </Modal>
  );
};

export default DonationModal;
