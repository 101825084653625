export const createCookie = (name: string, value: string, expires: Date) => {
  const exp = expires.toUTCString();
  document.cookie = `${name}=${value || ''};expires=${exp}; path=/`;
};

export const expires = new Date(Date.now() + 2 * 60000);

export const getCookie = (name, cookies) => {
  const nameEQ = `${name}=`;
  const ca = cookies.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
