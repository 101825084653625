import React, { useEffect } from "react";

import PaymentOptions from "components/PaymentOptions";
import { CompetitionWizardStepProps } from "types/Wizard";
import { InlineIcon } from "@iconify/react";
import { currencyIconResolver } from "services/helpers/resolvers";

const CheckoutStep = (props: CompetitionWizardStepProps) => {
  const {
    classes,
    context,
    next,
    wizardStates,
    loading,
    sendState,
    wizardError,
  } = props;

  const handleChange = (selectedOptions, isValid: boolean) => {
    wizardError(null);
    const state = { options: selectedOptions, isValid };
    sendState("checkout", state);
  };

  console.log(context);

  return (
    <div className="checkout-step">
      {wizardStates.prize.dollars !== 0 ? (
        <React.Fragment>
          <div className="wizard__step-header">
            <h1>Checkout</h1>
            <h3>
              Select your preferred payment methods in order to complete this
              transaction.
            </h3>
          </div>
          <div className="checkout-step__total">
            <h1>Competition prize pool</h1>
            <span className=" flex items-center gap-x-0.5">
              <InlineIcon
                icon={currencyIconResolver(wizardStates.prize.currency)}
              />
              {wizardStates.prize.dollars}{" "}
              <span className="text-small">{wizardStates.prize.currency}</span>
            </span>
          </div>
          <PaymentOptions
            userId={context.user.id}
            communityId={
              wizardStates.begin.community ? wizardStates.begin.ownerId : null
            }
            amount={wizardStates.prize.dollars}
            currency={wizardStates.prize.currency}
            for={"COMPETITION"}
            onChange={handleChange}
          />
        </React.Fragment>
      ) : (
        <div className="checkout-step__zero-prize">
          <h1>Hang on! Before you go..</h1>
          <h3>
            Are you sure you want to create a competition with no prize pool?
            Even $5 prize pools attract more players!
          </h3>
          <span>
            If you've changed your mind, click the prize pool tab to edit your
            input.
          </span>
        </div>
      )}
    </div>
  );
};
export default CheckoutStep;
