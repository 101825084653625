import React from "react";
import { statusClassResolver } from "services/helpers/resolvers";

type CompetitionSuggestionItemProps = {
  competition: any;
};

const CompetitionSuggestionItem = (props: CompetitionSuggestionItemProps) => {
  const { competition } = props;

  console.log(competition);

  return (
    <a
      className="comp-suggestions__item"
      target="_blank"
      href={`/${
        competition.node.community.alias ?? competition.node.community.id
      }/competitions/${competition.node.id}`}
    >
      <div className="comp-suggestions__item__container">
        <div className="comp-suggestions__item__container__logo">
          <img
            src={`
          ${
            competition.node.thumbnail
              ? competition.node.thumbnail
              : "/no-image.jpg"
          }
          `}
            alt=""
          />
        </div>
        <div className="comp-suggestions__item__container__info">
          <h1>{competition.node.name}</h1>
          {/* <h3>{competition.node.owner.displayName}</h3> */}
          <div className="comp-suggestions__item__container__info">
            <div className="comp-suggestions__item__container__info__item">
              <span className="comp-suggestions__item__container__info__item__name">
                Status
              </span>
              <span
                className={`comp-suggestions__item__container__info__item__value ${statusClassResolver(
                  competition.node.status
                )}`}
              >
                {competition.node.status}
              </span>
            </div>
            <div className="comp-suggestions__item__container__info__item">
              <span className="comp-suggestions__item__container__info__item__name">
                Prize pool
              </span>
              <span className="comp-suggestions__item__container__info__item__value">
                {competition.node.amount.currency}
                {competition.node.amount.display}
              </span>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};
export default CompetitionSuggestionItem;
