import React, { createContext, useContext } from "react";
import { useProfileQuery } from "api/graphql";
import Loading from "components/Loading";

interface ProfileContextInterface {
  id: string;
  connection: string;
  provider: string;
  profileID: string;
  username: string;
  displayName: string;
  avatarURL: string;
}

const ProfileContext = createContext<ProfileContextInterface | null>(null);

const ProfileProvider: React.FC = ({ children }) => {
  const { data, isFetching, isLoading } = useProfileQuery();

  if (isFetching || isLoading) {
    return <Loading />;
  }

  return (
    <ProfileContext.Provider value={data?.profile?.user}>
      {children}
    </ProfileContext.Provider>
  );
};

const useMyProfile = (): ProfileContextInterface => {
  const context = useContext<ProfileContextInterface | null>(ProfileContext);
  if (context === null) {
    throw new Error("useMyProfile must be used within a ProfileProvider");
  }
  return context;
};

export { useMyProfile, ProfileProvider };
