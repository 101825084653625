import React, { useState } from "react";
import { InlineIcon } from "@iconify/react";
import { WizardComponentProps } from "types/Wizard";

import Button from "components/Button";
import CustomSelect from "components/Select";

import SelectStyles from "styles/Select/SelectNewStyle";

import PlusIcon from "@iconify/icons-uil/plus";
import { useNavigate } from "react-router-dom";

const BeginStep = (props) => {
  const nav = useNavigate();

  const {
    context,
    next,
    sendState,
    loading,
    wizardError,
  }: WizardComponentProps = props;

  const [selectedCommunity, setSelectedCommunity] = useState({
    value: "",
    label: "None",
  });

  next(async () => {
    loading(true);
    if (!selectedCommunity.value) {
      return wizardError({
        message: "A community is required to create a competition.",
      });
    }
    try {
      sendState("begin", {
        ownerId: selectedCommunity.value,
        community: true,
      });
      return true;
    } catch (err) {
      wizardError(err);
      loading(false);
      return false;
    }
  });

  const handleChange = (selected, info) => {
    wizardError(null);
    setSelectedCommunity(selected);
  };

  return (
    <div className="begin-step">
      <div className="wizard__step-header">
        <h1>Who's this competition for?</h1>
        <h3>
          Choose to create this competition on behalf of one of your communities
        </h3>
      </div>
      <div className="begin-step__select">
        {context.communities && (
          <CustomSelect
            styles={SelectStyles}
            value={selectedCommunity}
            onChange={handleChange}
            options={context.communities.edges.map(({ node }) => ({
              value: node.id,
              label: node.name,
            }))}
            isSearchable={false}
            name="communityId"
            id="communityId"
            label="Select Community"
          />
        )}
      </div>
      <Button
        label={
          <div className="flex items-center gap-x-1">
            <InlineIcon icon={PlusIcon} /> New Community
          </div>
        }
        onClick={() => nav("/create/community")}
        small
      />
    </div>
  );
};
export default BeginStep;
