import React from "react";
import { InlineIcon } from "@iconify/react";
import classNames from "classnames";

type ToggleButtonProps = {
  selected: boolean;
  onClick: any;
  attachFront: any;
  prop: string;
};

const ToggleButton = (props: ToggleButtonProps) => {
  return (
    <button
      id="toggle-button"
      className={classNames(
        "toggle-button hover:bg-neutral-800",
        props.selected && "active"
      )}
      value={props.prop}
      onClick={props.onClick}
    >
      <span className="flex items-center">
        <InlineIcon icon={props.attachFront} />
        {props.prop}
      </span>
    </button>
  );
};
export default ToggleButton;
