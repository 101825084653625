import React, { useState, useEffect, useRef } from 'react';

const Modal = (props) => {

  const node = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = e => {
    if (node.current.contains(e.target) || props.loading) {
      return;
    };
    props.toggleModal(() => false);
  };

  const styles = {
    width: props.width,
    height: props.height
  }

  return (
    <div>
      <div ref={node} style={styles} className={`modal-container ${props.visible ? 'visible' : 'hidden'} ${props.fixed ? 'fixed' : ''}`}>
        {props.children}
      </div>
      <div className={`modal-overlay ${props.fixed ? 'fixed' : ''}`}></div>
    </div>
  );
};
export default Modal;