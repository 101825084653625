import React, { useState } from "react";

import Radio from "components/Radio";
import RadioGroup from "components/RadioGroup";

const ProviderStep = ({
  context,
  next,
  sendState,
  loading,
  wizardStates,
  wizardError,
}) => {
  const [type, setType] = useState("smashgg");

  const handleRadioChange = (e) => {
    setType(e.target.name);
  };

  next(async () => {
    loading(true);
    try {
      sendState("provider", { name: type });
      return true;
    } catch (err) {
      wizardError(err);
      loading(false);
      return false;
    }
  });

  return (
    <div className="provider-step">
      <div className="wizard__step-header">
        <h1>Choose your provider</h1>
        <h3>We currently support the following tournament providers.</h3>
      </div>
      <RadioGroup label="Competition Provider">
        <Radio
          name="smashgg"
          checked={type === "smashgg"}
          onChange={(e) => handleRadioChange(e)}
          id="smashgg"
          label="start.gg"
        />
        {/* <Radio
          name='battlefy'
          checked={type === 'battlefy'}
          onChange={(e) => handleRadioChange(e)}
          id='battlefy'
          label='Battlefy'
        /> */}
        <Radio
          name="challengermode"
          checked={type === "challengermode"}
          onChange={(e) => handleRadioChange(e)}
          id="challengermode"
          label="Challenger Mode"
        />
      </RadioGroup>
    </div>
  );
};
export default ProviderStep;
