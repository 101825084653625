import PropTypes from "prop-types";
import React, { Component } from "react";
import SignaturePad, { Options } from "signature_pad";
import TrimCanvas from "./TrimCanvas";

export interface ReactSignatureCanvasProps extends Options {
  canvasProps?: React.CanvasHTMLAttributes<HTMLCanvasElement> | undefined;
  clearOnResize?: boolean | undefined;
  onBegin?: Function;
  onEnd?: Function;
}

export default class SignatureCanvas extends Component<ReactSignatureCanvasProps> {
  static propTypes = {
    // signature_pad's props
    velocityFilterWeight: PropTypes.number,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    minDistance: PropTypes.number,
    dotSize: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    penColor: PropTypes.string,
    throttle: PropTypes.number,
    onEnd: PropTypes.func,
    onBegin: PropTypes.func,
    // props specific to the React wrapper
    canvasProps: PropTypes.object,
    clearOnResize: PropTypes.bool,
  };

  static defaultProps = {
    clearOnResize: true,
  };

  _sigPad: any = null;
  _canvas: any = null;

  _excludeOurProps = () => {
    const { canvasProps, clearOnResize, ...sigPadProps } = this.props;
    return sigPadProps;
  };

  componentDidMount() {
    this._sigPad = new SignaturePad(this._canvas, this._excludeOurProps());

    this._sigPad.addEventListener("beginStroke", () => {
      if (this.props.onBegin) {
        this.props.onBegin();
      }
    });

    this._sigPad.addEventListener("endStroke", () => {
      if (this.props.onEnd) {
        this.props.onEnd();
      }
    });

    this._resizeCanvas();
    this.on();
  }

  componentWillUnmount() {
    this.off();
  }

  // propagate prop updates to SignaturePad
  componentDidUpdate() {
    Object.assign(this._sigPad, this._excludeOurProps());
  }

  // return the canvas ref for operations like toDataURL
  getCanvas = () => {
    return this._canvas;
  };

  // return a trimmed copy of the canvas
  getTrimmedCanvas = () => {
    // copy the canvas
    const copy: any = document.createElement("canvas");
    copy.width = this._canvas.width;
    copy.height = this._canvas.height;
    copy.getContext("2d").drawImage(this._canvas, 0, 0);
    // then trim it
    return TrimCanvas(copy);
  };

  // return the internal SignaturePad reference
  getSignaturePad = () => {
    return this._sigPad;
  };

  _checkClearOnResize = () => {
    if (!this.props.clearOnResize) {
      return;
    }
    this._resizeCanvas();
  };

  _resizeCanvas = () => {
    const canvasProps = this.props.canvasProps || {};
    const { width, height } = canvasProps;
    // don't resize if the canvas has fixed width and height
    if (width && height) {
      return;
    }

    const canvas = this._canvas;
    /* When zoomed out to less than 100%, for some very strange reason,
      some browsers report devicePixelRatio as less than 1
      and only part of the canvas is cleared then. */
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    if (!width) {
      canvas.width = canvas.offsetWidth * ratio;
    }
    if (!height) {
      canvas.height = canvas.offsetHeight * ratio;
    }
    canvas.getContext("2d").scale(ratio, ratio);
    this.clear();
  };

  render() {
    const { canvasProps } = this.props;

    return (
      <canvas
        ref={(ref) => {
          this._canvas = ref;
        }}
        {...canvasProps}
      />
    );
  }

  // all wrapper functions below render
  //
  on = () => {
    window.addEventListener("resize", this._checkClearOnResize);
    return this._sigPad.on();
  };

  off = () => {
    window.removeEventListener("resize", this._checkClearOnResize);
    return this._sigPad.off();
  };

  clear = () => {
    return this._sigPad.clear();
  };

  isEmpty = () => {
    return this._sigPad.isEmpty();
  };

  fromDataURL = (dataURL: any, options: any) => {
    return this._sigPad.fromDataURL(dataURL, options);
  };

  toDataURL = (type: any, encoderOptions: any) => {
    return this._sigPad.toDataURL(type, encoderOptions);
  };

  fromData = (pointGroups: any) => {
    return this._sigPad.fromData(pointGroups);
  };

  toData = () => {
    return this._sigPad.toData();
  };
}
