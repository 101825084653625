import { Field, useFormikContext } from "formik";
import React from "react";
import classNames from "classnames";
import _ from "lodash";

interface FormInputTypes {
  validation?: (value: any) => void;
  placeholder?: string;
  name: string;
  label: string;
  type?: string;
  as?: string;
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  extraClassName?: string;
  validate?: boolean;
}

const FormInput = ({
  validation,
  placeholder,
  name,
  label,
  type = "text",
  as = "input",
  Icon,
  disabled,
  extraClassName,
  validate = true,
}: FormInputTypes) => {
  const { touched, errors } = useFormikContext();

  // this extracts value from a nested object using string as a path e.g. "team.region.country.city" returns "Perth"
  // https://lodash.com/docs#get
  const e = _.get(errors, name, false);
  const t = _.get(touched, name, false);

  const isErrored = validate && e && t;

  return (
    <>
      <label
        htmlFor={name}
        className="mb-1 block text-sm font-semibold text-neutral-300"
      >
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        {Icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
            <Icon
              className={classNames(
                isErrored
                  ? `h-6 w-6 text-red-400`
                  : `z-20 h-6 w-6 text-neutral-600`
              )}
              aria-hidden="true"
            />
          </div>
        )}
        <Field
          id={name}
          name={name}
          type={type}
          as={as}
          placeholder={placeholder}
          validate={validation}
          disabled={disabled}
          className={classNames(
            isErrored
              ? "border-red-500 bg-red-800 bg-opacity-10 text-red-500 text-opacity-90 placeholder:text-red-500"
              : "bg-opacity-60 text-neutral-400",
            "relative block w-full rounded-md border border-context-black border-opacity-50 bg-context-gray bg-opacity-60 px-3 py-2  placeholder-neutral-500 caret-ctx-orange focus:z-10 focus:border-ctx-orange focus:border-opacity-80 focus:bg-context-dark focus:placeholder-neutral-500 focus:outline-none focus:ring-0",
            Icon ? "pl-10" : "",
            extraClassName && extraClassName
          )}
        />
      </div>
      {isErrored && <p className="text-xs font-bold text-red-500">* {e}</p>}
    </>
  );
};

export default FormInput;
