import { AtSymbolIcon } from "@heroicons/react/outline";
import Footer from "components/Footer";
import Parallax from "components/Parallax";

const Contact = () => {
  return (
    <div>
      <div className="contact">
        <Parallax url="/context-banner-new.png" />
        <div className="mx-auto -mt-40 px-6 md:px-40">
          <div className="space-y-8">
            <h1 className="text-5xl font-bold text-white">Contact us</h1>
            <h3 className="text-2xl text-neutral-300">
              Have or question or just want to say hey? We'd love to hear from
              you.
            </h3>
          </div>

          <div className=" help-page__content__section__contact mt-12 lg:mt-24">
            <div className="help-page__content__section__contact__header">
              <h3>
                Our preferred method of contact is via email, but you can
                contact us on any of these platforms.
              </h3>
            </div>
            <div className="flex flex-col flex-wrap gap-4 font-bold text-ctx-orange md:flex-row">
              <a
                className="flex w-52 shrink-0 items-center gap-x-3 rounded bg-context-light px-4 py-4 transition hover:bg-context-darker"
                href="mailto:us@context.gg"
              >
                <AtSymbolIcon className="h-6 w-6 rounded-full bg-white p-0.5 text-context-darker" />
                us@context.gg
              </a>
              <a
                className="flex w-52 shrink-0 items-center gap-x-3 rounded bg-context-light px-4 py-4 transition hover:bg-context-darker"
                href="https://discord.gg/c6BR2UT"
              >
                <img src="/discord.svg" alt="" width="30px" /> Discord
              </a>
              <a
                className="flex w-52 shrink-0 items-center gap-x-3 rounded bg-context-light px-4 py-4 transition hover:bg-context-darker"
                href="https://twitter.com/ctx_gg"
              >
                <img src="/twitter.svg" alt="" width="30px" /> Twitter
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Contact;
