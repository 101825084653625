import { Route, Routes } from "react-router-dom";
import CreateCommunity from "./CreateCommunity";
import CreateCompetition from "./CreateCompetition";
import CreateTeam from "./CreateTeam";
import NewCreateCompetition from "./NewCreateCompetition";
import Select from "./Select";

const CreateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Select />} />
      <Route path="/competition" element={<CreateCompetition />} />
      <Route path="/community" element={<CreateCommunity />} />
      <Route path="/newcompetition" element={<NewCreateCompetition />} />
      <Route path="/team" element={<CreateTeam />} />
    </Routes>
  );
};

export default CreateRoutes;
