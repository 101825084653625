import { useGetCommunityQuery } from "api/graphql";
import LoadingData from "components/LoadingData";
import NewLayout from "domains/community/components/NewLayout";
import { useMyProfile } from "lib/profile";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import CommunityAdmin from "./CommunityAdmin";
import CommunityAdminOrders from "./CommunityAdminOrders";
import CommunityAdminSettings from "./CommunityAdminSettings";
import CommunityAdminWinners from "./CommunityAdminWinners";

const Admin = () => {
  const { id } = useParams();
  const user = useMyProfile();
  const nav = useNavigate();
  const { data, isLoading } = useGetCommunityQuery({ id });

  useEffect(() => {
    // Check if user is an admin
    if (data && data.communityAdmin.enabled === false) {
      nav("/");
    }
  }, [data]);

  if (isLoading) {
    return <LoadingData />;
  }

  return (
    <NewLayout
      community={data.community}
      communityAdmin={data.communityAdmin}
      user={user}
    >
      <Routes>
        <Route path="/" element={<CommunityAdmin />} />
        <Route path="/orders" element={<CommunityAdminOrders />} />
        <Route path="/winners" element={<CommunityAdminWinners />} />
        <Route path="/settings" element={<CommunityAdminSettings />} />
      </Routes>
    </NewLayout>
  );
};

export default Admin;
