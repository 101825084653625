import { useLocation, useParams } from "react-router-dom";

import { useGetCommunitySettingsQuery } from "api/graphql";
import { useMyProfile } from "lib/profile";

import AdminMenu from "domains/community/components/AdminMenu";
import Layout from "domains/community/components/Layout";
import CommunityGeneralSettings from "domains/community/components/Settings/CommunityGeneralSettings";
import CommunityAdvancedSettings from "domains/community/components/Settings/CommunityAdvancedSettings";

import ErrorComponent from "components/Error";
import LoadingLogo from "components/LoadingLogo";

const CommunityAdminWinners = () => {
  const location = useLocation();
  const user = useMyProfile();
  const { id } = useParams();

  const { isLoading, error, data } = useGetCommunitySettingsQuery({ id });
  if (isLoading) {
    return <LoadingLogo />;
  }

  if (error) {
    return (
      <ErrorComponent
        id="community_admin_settings"
        visible={error ? true : false}
        message={error && error.message}
        path={location.pathname}
        type="CommunitySettings"
        closeable={false}
        style={{ margin: "24px 0" }}
        inverse
      />
    );
  }

  return (
    <div className="my-6">
      <div className="space-y-6">
        <div className="text-lg sm:grid sm:grid-cols-3 sm:items-start sm:gap-x-4 sm:gap-y-6">
          <div className="-mt-2.5">
            <h1 className="text-xl font-semibold text-orange-500">General</h1>
            <p className="text-neutral-300">
              General settings related to your community
            </p>
          </div>
          <CommunityGeneralSettings community={data.community} />
        </div>

        <div className="border-t border-neutral-700 pt-5 text-lg sm:grid sm:grid-cols-3 sm:items-start sm:gap-x-4 sm:gap-y-6">
          <div className="">
            <h1 className="text-xl font-semibold text-orange-500">Advanced</h1>
            <p className="text-neutral-300">Advanced community settings</p>
          </div>
          <CommunityAdvancedSettings communityAdmin={data.communityAdmin} />
        </div>
      </div>
    </div>
  );
};

export default CommunityAdminWinners;
