import { useState, useRef, useEffect } from "react";
import {
  TaxFormSubmitInput,
  W8BenForm,
  W8BenFormSubmitInput,
} from "types/Forms";
import { PrizeClaimComponentProps } from "types/Wizard";
import { validateFields } from "domains/forms/lib/FW8BEN";

// components
import SignatureCanvas from "components/SignatureCanvas";
import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import Input from "components/Input";
import BirthDateInput from "components/DateOfBirthInput";
import Toast from "components/Toast";
import { useTaxFormSubmitMutation } from "api/graphql";

const formatDate = () => {
  const date = new Date()
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .toString();
  return date.replace(/\//g, "-");
};

const W8Form = (props) => {
  const {
    context,
    next,
    wizardError,
    sendState,
    wizardStates,
  }: PrizeClaimComponentProps = props;

  const sigCanvas = useRef(null);

  const [values, setValues] = useState({
    name: "",
    citizenship_country: "",
    address: "",
    address_city: "",
    address_country: "",
    mailing_address: "",
    mailing_city: "",
    mailing_country: "",
    taxpayer_id_number: "",
    foreign_tax_id_number: "",
    reference_numbers: "",
    day: "",
    month: "",
    year: "",
    signature_data_url: "",
    resident_country: "",
    article_paragraph: "",
    special_rate: "",
    income_type: "",
    additional_conditions: "",
    date: formatDate(),
    print_name: "",
    acting_capacity: "",
  });
  const [inputErrors, setInputErrors] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [confirmError, setConfirmError] = useState(null);
  const [hasBeenPopulated, setHasBeenPopulated] = useState(false);

  const { mutateAsync: submitTaxForm } = useTaxFormSubmitMutation({
    onSettled: (data) => {
      if (data) {
        sendState("irstaxValid", data.taxFormSubmit.submitted);
        setConfirmSuccess(data.taxFormSubmit.submitted);
        setConfirmLoading(false);
      }
    },
  });

  useEffect(() => {
    if (context.irstax && context.irstax.w8ben) {
      populateForm(context.irstax.w8ben);
    }
  }, [context]);

  useEffect(() => {
    if (props.error) {
      setInputErrors([...inputErrors, props.error]);
      return;
    }
  }, [props.error]);

  const populateForm = (data: W8BenForm) => {
    setValues({
      name: data.name,
      citizenship_country: data.citizenshipCountry,
      address: data.address,
      address_city: data.city,
      address_country: data.country,
      mailing_address: data.mailingAddress,
      mailing_city: data.mailingCity,
      mailing_country: data.mailingCountry,
      taxpayer_id_number: data.taxpayerIdentificationNumber,
      foreign_tax_id_number: data.foreignTaxNumber,
      reference_numbers: data.referenceNumbers,
      day: String(data.dateOfBirth.day),
      month: String(data.dateOfBirth.month),
      year: String(data.dateOfBirth.year),
      signature_data_url: "",
      resident_country: data.residentCountry,
      article_paragraph: data.articleParagraph,
      special_rate: String(data.specialRate),
      income_type: data.incomeType,
      additional_conditions: data.additionalConditions,
      date: formatDate(),
      print_name: data.printName,
      acting_capacity: data.actingCapacity,
    });
    setHasBeenPopulated(true);
  };

  const resetInputErrors = (fieldName?: string) => {
    if (fieldName) {
      const index = inputErrors.findIndex((x) => x.id === fieldName);
      if (index === -1) return;
      inputErrors.splice(index, 1);
      setInputErrors(inputErrors);
      return;
    }
    setInputErrors([]);
  };

  const handleInputChange = (e) => {
    resetInputErrors("signature_data_url");
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const resetCanvas = () => {
    sigCanvas.current.clear();
    setValues({
      ...values,
      signature_data_url: "",
    });
  };

  const handleSignatureEnd = (e) => {
    resetInputErrors("signature_data_url");
    setValues({
      ...values,
      signature_data_url: sigCanvas.current.getTrimmedCanvas().toDataURL(),
    });
  };

  const handleSubmit = async () => {
    wizardError(null);
    try {
      await validateFields(values);
    } catch (errors) {
      setInputErrors(errors);
      return;
    }

    const w8benInput: W8BenFormSubmitInput = {
      name: values.name,
      citizenshipCountry: values.citizenship_country,
      address: values.address,
      city: values.address_city,
      country: values.address_country,
      mailingAddress: values.mailing_address,
      mailingCity: values.mailing_city,
      mailingCountry: values.mailing_country,
      taxpayerIdentificationNumber: values.taxpayer_id_number,
      foreignTaxNumber: values.foreign_tax_id_number,
      referenceNumbers: values.reference_numbers,
      dateOfBirth: {
        day: Number(values.day),
        month: Number(values.month),
        year: Number(values.year),
      },
      signatureDataUrl: values.signature_data_url,
      residentCountry: values.resident_country,
      articleParagraph: values.article_paragraph,
      specialRate: Number(values.special_rate),
      incomeType: values.income_type,
      additionalConditions: values.additional_conditions,
      printName: values.print_name,
      actingCapacity: values.acting_capacity,
    };
    const input: any = {
      prizeId: context.prize.id,
      country: wizardStates.countrySelect.country,
      w9: null,
      w8ben: w8benInput,
    };

    setConfirmLoading(true);
    submitTaxForm({ input });
  };

  next(() => {
    if (!confirmSuccess) {
      wizardError({ message: "Form must be submitted before continuing" });
      return false;
    } else {
      return true;
    }
  });

  return (
    <div className="fw8-ben tax-form__wrapper">
      {hasBeenPopulated && (
        <div className="prize-payout__toast mb-3">
          <Toast
            type="info"
            message="This form has been pre-filled with previous data, please double check all of the inputs before submitting!"
            closeable
          />
        </div>
      )}
      <div className="form__tax__header">
        <h1>
          Certificate of Foreign Status of Beneficial Owner for United States
          Tax Withholding and Reporting (Individuals)
        </h1>
        <h3>
          For use by individuals. Entities must use Form W-8BEN-E. Go to{" "}
          <a target="_blank" href="https://www.irs.gov/FormW8BEN">
            www.irs.gov/FormW8BEN
          </a>{" "}
          for instructions and the latest information. Give this form to the
          withholding agent or payer. Do not send to the IRS.
        </h3>
        <br />
        <h3>
          For instructions go to;{" "}
          <a href="https://www.irs.gov/pub/irs-pdf/iw8ben.pdf" target="_blank">
            https://www.irs.gov/pub/irs-pdf/iw8ben.pdf
          </a>
        </h3>
      </div>
      <div className="fw8-ben__disclaimer">
        <h1>Do not use this form if:</h1>
        <ul>
          <li>
            You are NOT an individual. <span>Use W-8BEN-E instead</span>
          </li>
          <li>
            You are a U.S. citizen or other U.S. person, including a resident
            alient individual. <span>Use W-9 instead</span>
          </li>
          <li>
            You are a beneficial owner claiming that income is effectively
            connected with the conduct of trade or business within the U.S.
            (other than personal services). <span>Use W-8ECI instead</span>
          </li>
          <li>
            You are a beneficial owner who is receiving compensation for
            personal services performed in the United States.{" "}
            <span>Use 8233 or W-4 instead</span>
          </li>
          <li>
            You are a person acting as an intermediary.{" "}
            <span>Use W-8IMY instead</span>
          </li>
        </ul>
      </div>
      <div className="fw8-ben__section-1">
        <div className="form__heading">
          <h1>
            <span>Part I</span>Identification of Beneficial Owner (see
            instructions){" "}
          </h1>
        </div>
        <div className="fw8-ben__section-1__line-1">
          <Input
            className="fw8-ben__section-1__line-1__name"
            label="1 - Name of individual who is the beneficial owner"
            name="name"
            value={values.name}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "name") &&
              inputErrors.find((x) => x.id === "name")
            }
          />
          <Input
            className="fw8-ben__section-1__line-1__country"
            label="2 - Country of citizenship"
            name="citizenship_country"
            value={values.citizenship_country}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "citizenship_country") &&
              inputErrors.find((x) => x.id === "citizenship_country")
            }
          />
        </div>
        <div className="fw8-ben__section-1__address">
          <Input
            label="3 - Permanent residence address (street, apt. or suite no., or rural route). Do not use a P.O. box or in-care-of address."
            name="address"
            value={values.address}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "address") &&
              inputErrors.find((x) => x.id === "address")
            }
          />
          <div className="fw8-ben__section-1__address__location">
            <Input
              className="fw8-ben__section-1__address__location__city"
              label="City/town/state/province. Include postal code where appropriate."
              name="address_city"
              value={values.address_city}
              onChange={(e) => handleInputChange(e)}
              error={
                inputErrors.some((x) => x.id === "address_city") &&
                inputErrors.find((x) => x.id === "address_city")
              }
            />
            <Input
              className="fw8-ben__section-1__address__location__country"
              label="Country"
              name="address_country"
              value={values.address_country}
              onChange={(e) => handleInputChange(e)}
              error={
                inputErrors.some((x) => x.id === "address_country") &&
                inputErrors.find((x) => x.id === "address_country")
              }
            />
          </div>
        </div>
        <div className="fw8-ben__section-1__address">
          <Input
            label="4 - Mailing address (if different from above)"
            name="mailing_address"
            value={values.mailing_address}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "mailing_address") &&
              inputErrors.find((x) => x.id === "mailing_address")
            }
          />
          <div className="fw8-ben__section-1__address__location">
            <Input
              className="fw8-ben__section-1__address__location__city"
              label="Mailing Address City/town/state/province. Include postal code where appropriate."
              name="mailing_city"
              value={values.mailing_city}
              onChange={(e) => handleInputChange(e)}
              error={
                inputErrors.some((x) => x.id === "mailing_city") &&
                inputErrors.find((x) => x.id === "mailing_city")
              }
            />
            <Input
              className="fw8-ben__section-1__address__location__country"
              label="Mailing Address Country"
              name="mailing_country"
              value={values.mailing_country}
              onChange={(e) => handleInputChange(e)}
              error={
                inputErrors.some((x) => x.id === "mailing_country") &&
                inputErrors.find((x) => x.id === "mailing_country")
              }
            />
          </div>
        </div>
        <div className="fw8-ben__section-1__line-6">
          <Input
            className="fw8-ben__section-1__line-6__usTIN"
            label="5 - U.S. taxpayer identification number (SSN or ITIN), if required"
            name="taxpayer_id_number"
            value={values.taxpayer_id_number}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "taxpayer_id_number") &&
              inputErrors.find((x) => x.id === "taxpayer_id_number")
            }
          />
          <Input
            className="fw8-ben__section-1__line-6__fTIN"
            label="6 - Foreign tax identifying number"
            name="foreign_tax_id_number"
            value={values.foreign_tax_id_number}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "foreign_tax_id_number") &&
              inputErrors.find((x) => x.id === "foreign_tax_id_number")
            }
          />
        </div>
        <div className="fw8-ben__section-1__line-7">
          <Input
            className="fw8-ben__section-1__line-7__refnums"
            label="7 - Reference number(s)"
            name="reference_numbers"
            value={values.reference_numbers}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "reference_numbers") &&
              inputErrors.find((x) => x.id === "reference_numbers")
            }
          />
        </div>
        <BirthDateInput
          handleChange={handleInputChange}
          values={values}
          errors={inputErrors}
          locale={context.locale}
        />
      </div>
      {/* end section 1 */}
      <div className="fw8-ben__section-2">
        <div className="form__heading">
          <h1>
            <span>Part II</span>Claim of Tax Treaty Benefits (for chapter 3
            purposes only) (see instructions)
          </h1>
        </div>
        <div className="fw8-ben__section-2__9">
          <div>
            I certify that the beneficial owner is a resident of
            <Input
              name="resident_country"
              value={values.resident_country}
              onChange={(e) => handleInputChange(e)}
              error={
                inputErrors.some((x) => x.id === "resident_country") &&
                inputErrors.find((x) => x.id === "resident_country")
              }
            />
            within the meaning of the income tax treaty between the United
            States and that country.
          </div>
        </div>
        <div className="fw8-ben__section-2__10">
          <h1>
            Special rates and conditions (if applicable—see instructions):
          </h1>
          The beneficial owner is claiming the provisions of Article and
          paragraph
          <Input
            className="fw8-ben__section-2__10__paragraph"
            name="article_paragraph"
            value={values.article_paragraph}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "article_paragraph") &&
              inputErrors.find((x) => x.id === "article_paragraph")
            }
          />
          of the treaty identified on line 9 above to claim a
          <Input
            className="fw8-ben__section-2__10__special-rate"
            name="special_rate"
            type="number"
            value={values.special_rate}
            onChange={(e) => handleInputChange(e)}
            endAdornment="%"
            error={
              inputErrors.some((x) => x.id === "special_rate") &&
              inputErrors.find((x) => x.id === "special_rate")
            }
          />
          rate of withholding on (specify type of income):
          <Input
            className="fw8-ben__section-2__10__income-type"
            name="income_type"
            value={values.income_type}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "income_type") &&
              inputErrors.find((x) => x.id === "income_type")
            }
          />
          Explain the additional conditions in the Article and paragraph the
          beneficial owner meets to be eligible for the rate of withholding:
          <Input
            className="fw8-ben__section-2__10__additional-conditions"
            name="additional_conditions"
            value={values.additional_conditions}
            onChange={(e) => handleInputChange(e)}
            error={
              inputErrors.some((x) => x.id === "additional_conditions") &&
              inputErrors.find((x) => x.id === "additional_conditions")
            }
          />
        </div>
      </div>
      {/* end section 2 */}
      <div className="fw8-ben__section-3">
        <div className="form__heading">
          <h1>
            <span>Part III</span>Certification
          </h1>
        </div>
        <div className="form__content">
          <span className="fw8-ben__section-3__declaration">
            Under penalties of perjury, I declare that I have examined the
            information on this form and to the best of my knowledge and belief
            it is true, correct, and complete. I further certify under penalties
            of perjury that:
          </span>
          <ul>
            <li>
              I am the individual that is the beneficial owner (or am authorized
              to sign for the individual that is the beneficial owner) of all
              the income to which this form relates or am using this form to
              document myself for chapter 4 purposes,{" "}
            </li>
            <li>
              The person named on line 1 of this form is not a U.S. person{" "}
            </li>
            <li>
              The income to which this form relates is:
              <ol type="a">
                <li>
                  {" "}
                  not effectively connected with the conduct of a trade or
                  business in the United States,
                </li>
                <li>
                  {" "}
                  effectively connected but is not subject to tax under an
                  applicable income tax treaty, or
                </li>
                <li>
                  {" "}
                  the partner's share of a partnership's effectively connected
                  income,
                </li>
              </ol>
            </li>
            <li>
              The person named on line 1 of this form is a resident of the
              treaty country listed on line 9 of the form (if any) within the
              meaning of the income tax treaty between the United States and
              that country, and
            </li>
            <li>
              For broker transactions or barter exchanges, the beneficial owner
              is an exempt foreign person as defined in the instructions.
            </li>
          </ul>
          <p>
            Furthermore, I authorize this form to be provided to any withholding
            agent that has control, receipt, or custody of the income of which I
            am the beneficial owner or any withholding agent that can disburse
            or make payments of the income of which I am the beneficial owner. I
            agree that I will submit a new form within 30 days if any
            certification made on this form becomes incorrect.
          </p>
        </div>
        <div className="fw8-ben__section-3__signature">
          <div className="form__heading">
            <h1>Sign here</h1>
          </div>
          <div className="fw8-ben__section-3__signature__canvas__container">
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 450,
                height: 55,
                className: "fw8-ben__section-3__signature__canvas",
              }}
              ref={sigCanvas}
              onEnd={handleSignatureEnd}
              velocityFilterWeight={1.5}
              minDistance={3}
            />
            <Button label="Reset" outline small onClick={() => resetCanvas()} />
            <Input
              className="fw8-ben__section-3__signature__print-name"
              label="Print name of signer"
              name="print_name"
              value={values.print_name}
              onChange={(e) => handleInputChange(e)}
              error={
                inputErrors.some((x) => x.id === "print_name") &&
                inputErrors.find((x) => x.id === "print_name")
              }
            />
            <Input
              className="fw8-ben__section-3__signature__acting-capacity"
              label="Capacity in which acting (if form is not signed by beneficial owner)"
              name="acting_capacity"
              value={values.acting_capacity}
              onChange={(e) => handleInputChange(e)}
              error={
                inputErrors.some((x) => x.id === "acting_capacity") &&
                inputErrors.find((x) => x.id === "acting_capacity")
              }
            />
          </div>
          {inputErrors.some((x) => x.id === "signature_data_url") && (
            <span className="fw8-ben__section-3__signature__error">
              {inputErrors.find((x) => x.id === "signature_data_url").message}
            </span>
          )}
        </div>
      </div>
      <div className="mt-4 flex flex-align-center">
        {/* {inputErrors.length > 0 && <div className="fw8-ben__error">Error submitting, please check your inputs</div>} */}
        <Button
          label="Confirm your details"
          color="green"
          onClick={handleSubmit}
        />
        {confirmLoading && <CircularProgress className="ml-2" sm inverse />}
        {confirmSuccess && !confirmError && (
          <span className="green bold ml-2">Form successfully submitted.</span>
        )}
      </div>
    </div>
  );
};

export default W8Form;
